import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Deposit = {
  chargeDepositHold: (paymentIntent, amount, businessId, orderId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      paymentIntent,
      amount,
      businessId,
      orderId,
    };

    return axiosInstance.post(
      "/api/deposit/chargeDepositHold",
      bodyParameters,
      config
    );
  },
};

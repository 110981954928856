import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Calendar = {
  integrate: (businessId, calendarLinks) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const headers = { Authorization: `Bearer ${token}` };

    const params = {
      businessId,
      calendarLinks,
    };

    return axiosInstance.put("/api/ical/integrate", {
      params,
      headers,
    });
  },
  create: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const headers = { Authorization: `Bearer ${token}` };

    const params = {
      businessId,
    };

    return axiosInstance.get("/api/ical/create", {
      params,
      headers,
    });
  }
};

import React, { Fragment, useState, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../../State/AppContext";
import { twClassNames } from "../../../utils/twClassNames";
import RefundModal from "./RefundModal";
import CancelReservationModal from "./CancelReservationModal";
import ConfirmSecurityDepositModal from "./ConfirmSecurityDepositModal";
import ChangeDatesModal from "./ChangeDatesModal";

function isOrderFullyRefunded(order) {
  const isDeposit =
    order.metadata[0].depositInfo && order.metadata[0].depositInfo.enabled;

  let amount = order.amount;
  if (isDeposit) {
    amount =
      order.metadata[0].paymentInfo.grandTotal -
      order.metadata[0].depositInfo.remaining;
  }

  return order.amountRefunded >= amount;
}

export const OrderMenu = ({ order, businessData }) => {
  const { state } = useContext(AppContext);
  const { business } = state.user;
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModel] = useState(false);
  const [showSecurityDepositChargeModal, setShowSecurityDepositChargeModal] =
    useState(false);
  const [showChangeDatesModal, setShowChangeDatesModal] = useState(false);

  const isCancelled = order.orderStatus === "cancelled";
  const isFullyRefunded = isOrderFullyRefunded(order);
  const securityDeposit = order.metadata[0].securityDepositInfo;
  const isSecurityDepositHoldAvailable =
    securityDeposit &&
    securityDeposit.status === "success" &&
    moment(securityDeposit.chargeDate).isSameOrBefore(
      moment(securityDeposit.chargeDate).add(7, "days")
    );
  const isSecurityDepositOptionDisabled =
    securityDeposit && !!securityDeposit.convertedToCharge;

  const onRefundSelect = () => {
    if (order) {
      setShowRefundModal(true);
    }
  };

  const onCancelSelect = () => {
    if (order) {
      setShowCancelOrderModel(true);
    }
  };

  const onChargeSecurityDepositHold = () => {
    if (securityDeposit) {
      setShowSecurityDepositChargeModal(true);
    }
  };

  const onChangeDatesSelect = () => {
    if (order) {
      setShowChangeDatesModal(true);
    }
  };
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent text-sm font-semibold">
            <EllipsisVerticalIcon className="text-gray-400 w-6 h-6 hover:text-gray-600" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`https://reservations.booker.tech/?bkr_id=${business}&order_id=${order.orderNumber}`}
                    className={twClassNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    target="_blank"
                  >
                    Reservation Details
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={twClassNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left disabled:opacity-50"
                    )}
                    type="button"
                    onClick={onChangeDatesSelect}
                  >
                    Edit Dates
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={twClassNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left disabled:opacity-50"
                    )}
                    type="button"
                    onClick={onCancelSelect}
                    disabled={isCancelled}
                  >
                    {isCancelled ? "Order Cancelled" : "Cancel (no refund)"}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={twClassNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left disabled:opacity-50"
                    )}
                    type="button"
                    onClick={onRefundSelect}
                    disabled={isFullyRefunded}
                  >
                    {isFullyRefunded
                      ? "Refunded"
                      : isCancelled
                      ? "Refund"
                      : "Refund (option to cancel)"}
                  </button>
                )}
              </Menu.Item>
              {isSecurityDepositHoldAvailable && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={twClassNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left disabled:opacity-50"
                      )}
                      type="button"
                      onClick={onChargeSecurityDepositHold}
                      disabled={isSecurityDepositOptionDisabled}
                    >
                      {isSecurityDepositOptionDisabled
                        ? "Security deposit charged to customer"
                        : "Charge Security Deposit"}
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {showRefundModal && (
        <RefundModal setShowModal={setShowRefundModal} order={order} />
      )}
      {showCancelOrderModal && <CancelReservationModal order={order} />}

      <ChangeDatesModal
        order={order}
        businessTimeZone={businessData?.businessInfo?.timeZone}
        isOpen={showChangeDatesModal}
        setIsOpen={setShowChangeDatesModal}
      />
      <ConfirmSecurityDepositModal
        securityDeposit={securityDeposit}
        isOpen={showSecurityDepositChargeModal}
        setIsOpen={setShowSecurityDepositChargeModal}
        businessId={order.business}
        orderId={order._id}
      />
    </>
  );
};

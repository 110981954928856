import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../State/AppContext";
import { GuestAPI } from "../../../apis/index";
import { twClassNames } from "../../../utils/twClassNames";

export const guestOptions = [
  { label: "Adult", value: "adult" },
  { label: "Youth", value: "youth" },
  { label: "General", value: "general" },
  { label: "Pet", value: "pet" },
  { label: "Free", value: "free" },
  { label: "Other", value: "other" },
];

const AddGuest = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { business } = state.user;

  const initialGuestState = {
    label: "",
    guestType: "adult",
    minimum: 0,
    maximum: 0,
  };
  const initialErrorState = {
    label: "",
    guestType: "",
    minimum: 0,
    maximum: 0,
  };

  const [guest, setGuest] = useState(initialGuestState);
  const [error, setError] = useState(initialErrorState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuest((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (guest.label.length < 1) {
      setError((prev) => ({ ...prev, label: "This is a required field" }));
    } else if (guest.guestType.length < 1) {
      setError((prev) => ({ ...prev, guestType: "This is a required field" }));
    } else {
      await GuestAPI.create(business, [
        {
          ...guest,
          minimum: guest.minimum > 0 ? Number(guest.minimum) : undefined,
          maximum: guest.maximum > 0 ? Number(guest.maximum) : undefined,
        },
      ])
        .then((res) => {
          if (res.status === 200) {
            toast.success("Guest has been successfully added!");
            navigate("/guests");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setGuest(initialGuestState);
          setError(initialErrorState);
        });
    }
  };

  return (
    <div>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1 flex justify-between flex-col md:flex-row mt-6">
          <div>
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Add guest
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 mb-10">
              <p className="mt-2 flex items-center text-sm text-gray-500">
                The guests you add below will be options your customer can
                select.
              </p>
            </div>
          </div>
          <Link
            to="/guests"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 h-min w-min"
          >
            Back
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 pb-5">
          <label
            htmlFor="label"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Label of guest
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="label"
              value={guest.label}
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <p
              className={twClassNames(
                error.label.length > 0 ? "text-red-500" : "text-gray-500",
                "text-xs"
              )}
            >
              {error.label.length > 0
                ? error.label
                : `E.g. "Ages 18+" or "Ages 12-17" or "Dogs only"...etc.`}
            </p>
          </div>
          <label
            htmlFor="guestType"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Guest Type
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              name="guestType"
              value={guest.guestType}
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            >
              {guestOptions.map((op) => (
                <option value={op.value} key={op.value}>
                  {op.label}
                </option>
              ))}
            </select>
            <p
              className={twClassNames(
                error.guestType.length > 0 ? "text-red-500" : "text-gray-500",
                "text-xs"
              )}
            >
              {error.guestType.length > 0 ? error.guestType : ""}
            </p>
          </div>
          <label
            htmlFor="minimum"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Minimum # of guests (optional)
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="number"
              name="minimum"
              min={0}
              step={1}
              value={guest.minimum}
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
          <label
            htmlFor="minimum"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Maximum # of guests (optional)
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="number"
              name="maximum"
              min={0}
              step={1}
              value={guest.maximum}
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>
        <div className="flex justify-between pt-8 pr-8 mt-10">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddGuest;

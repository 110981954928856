import React from "react";
import { toast } from "react-toastify";
import { BusinessAPI } from "../../../apis";

export const SecurityDeposit = ({
  securityDepositInfo,
  setSecurityDepositInfo,
  business,
}) => {
  const { rateType, rate } = securityDepositInfo;

  const isPercentage = rateType === "percentage";

  const handleRadioInputChange = (e) => {
    const { name, id } = e.target;
    setSecurityDepositInfo((prev) => ({
      ...prev,
      [name]: id,
      rate: id === "percentage" ? 10 / 100 : 10 * 100,
    }));
  };
  const handleRateChange = (e) => {
    const { name, value } = e.target;
    setSecurityDepositInfo((prev) => ({
      ...prev,
      [name]: prev.rateType === "percentage" ? value / 100 : value * 100,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedSecurityDepositInfo = {
      ...securityDepositInfo,
      enabled: true,
    };

    setSecurityDepositInfo(updatedSecurityDepositInfo);

    await BusinessAPI.updateBusiness(
      business,
      "securityDeposit",
      updatedSecurityDepositInfo
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business security deposit settings has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDisable = async (e) => {
    e.preventDefault();

    const disabledDepositInfo = {
      rate: 0,
      rateType: undefined,
      enabled: false,
    };

    setSecurityDepositInfo(disabledDepositInfo);

    await BusinessAPI.updateBusiness(
      business,
      "securityDeposit",
      disabledDepositInfo
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            "Business security deposit settings have been disabled!"
          );
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <div className="flex justify-between items-center">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Security Deposit Settings
            {!securityDepositInfo.enabled && (
              <span className="text-xs text-red-300 pl-4">
                currently disabled
              </span>
            )}
          </h3>
          {securityDepositInfo.enabled && (
            <button
              onClick={handleDisable}
              className="text-sm py-2 px-3 rounded bg-transparent text-red-600 hover:bg-red-50 transition-colors"
            >
              Disable
            </button>
          )}
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
            Rate Type
          </p>
          <div className="flex flex-col">
            <label
              className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
              htmlFor="fee"
            >
              <input
                type="radio"
                name="rateType"
                id="fee"
                className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                checked={rateType === "fee" ? "checked" : undefined}
                onChange={handleRadioInputChange}
              />
              Fixed rate
            </label>
            <label
              className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
              htmlFor="percentage"
            >
              <input
                type="radio"
                name="rateType"
                id="percentage"
                className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                checked={isPercentage ? "checked" : undefined}
                onChange={handleRadioInputChange}
              />
              Percentage
            </label>
          </div>
        </div>
        <div>
          <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
            Rate Amount ({isPercentage ? "%" : "$"})
          </p>
          <input
            type="number"
            max={isPercentage ? 100 : undefined}
            step={1}
            min={1}
            name="rate"
            value={isPercentage ? rate * 100 : rate / 100}
            onChange={handleRateChange}
            className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
          />
        </div>
        {(securityDepositInfo.enabled || rate > 0) && (
          <div>
            <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Summary
            </p>
            <p className="block text-sm text-gray-900 sm:mt-px sm:pt-2 mr-2">
              A security deposit of&nbsp;
              <span className="inline, text-sm text-violet-600">
                {isPercentage
                  ? `${rate * 100}% of the customer's total`
                  : `$${(rate / 100).toFixed(2)}`}
              </span>
              &nbsp;will be charged 24 hours before the reservation ends.
            </p>
          </div>
        )}
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Availability = {
  create: (availability) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      availability,
    };

    return axiosInstance.post(
      "/api/availability/createAvailability",
      bodyParameters,
      config
    );
  },

  getOrder: (orderId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/getOrder", {
      params: { orderId: orderId },
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  availabilities: (businessId, productId, date) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/availability/availabilitiesByProduct", {
      params: { businessId, productId, date },
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

import React, { useRef } from "react";
import InputBlock from "./InputBlock";
import { Editor } from "@tinymce/tinymce-react";

export const Options = ({ options, setOptions }) => {
  const editorRef = useRef(null);

  const handleChange = (index, e, field) => {
    let newOptions = [...options];
    const value = typeof e !== "string" ? e.target.value : e;
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleOptionChange = (groupIndex, optionIndex, e) => {
    const name = e.target.name.slice(0, -1);
    const value = e.target.value;

    let newOptions = [...options];
    const prevOptionObj = newOptions[groupIndex].options[optionIndex];

    newOptions[groupIndex].options[optionIndex] = {
      ...prevOptionObj,
      [name]: value,
    };
    setOptions(newOptions);
  };

  const addOptionGroup = () => {
    setOptions((prev) => [
      ...prev,
      {
        description: "",
        groupName: "",
        options: [{ name: "", additionalPrice: "" }],
      },
    ]);
  };

  const addOptionInGroup = (groupIndex) => {
    setOptions((prev) =>
      prev.map((op, i) => {
        if (i === groupIndex) {
          return {
            ...op,
            options: [...op.options, { name: "", additionalPrice: "" }],
          };
        }
        return op;
      })
    );
  };

  const removeOptionInGroup = (groupIndex, optionIndex) => {
    let newOptions = [...options];
    newOptions[groupIndex].options.splice(optionIndex, 1);
    setOptions(newOptions);
  };

  const removeOptionGroup = (i) => {
    let newOptions = [...options];
    newOptions.splice(i, 1);
    setOptions(newOptions);
  };

  return (
    <div className="border-t border-gray-200 ">
      <div className="flex flex-col gap-1 pt-2 pb-5">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Product Options Section
        </p>
        <p className="text-gray-500 text-sm">
          In this section you can set the options that customers can select for
          your product.
        </p>
      </div>
      {options.map((element, groupIndex) => (
        <div key={groupIndex}>
          <div className="mb-4">
            <InputBlock
              value={element.groupName}
              onChange={(e) => handleChange(groupIndex, e, "groupName")}
              field={element.groupName}
              label="Option group name"
            />
            <div className="sm:grid sm:grid-cols-3 sm:items-start gap-4 pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Option group description
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Editor
                  apiKey={"3u557s7493kor583in0tern82p6wzpw7leqapaxo3wvhcc0v"}
                  onInit={(_, editor) => (editorRef.current = editor)}
                  value={element.description}
                  onEditorChange={(content, _) =>
                    handleChange(groupIndex, content, "description")
                  }
                  init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                      "image",
                      "code",
                      "table",
                      "link",
                      "media",
                      "codesample",
                      "lists",
                    ],
                    toolbar:
                      "undo redo | blocks fontsize | bold italic underline | link mergetags  | checklist numlist bullist indent outdent",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
                  }}
                />
              </div>
            </div>
          </div>
          {element.options.map((op, opIndex) => {
            return (
              <div
                className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:pt-5"
                key={opIndex}
              >
                <label
                  htmlFor="time"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Option {opIndex + 1}
                </label>
                <div className="mt-1 sm:col-span-1 sm:mt-0">
                  <input
                    type="text"
                    name={"name" + opIndex}
                    id={"name" + opIndex}
                    value={op.name}
                    placeholder="Name"
                    onChange={(e) => handleOptionChange(groupIndex, opIndex, e)}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="mt-1 sm:col-span-1 sm:mt-0">
                  <input
                    type="number"
                    name={"additionalPrice" + opIndex}
                    id={"additionalPrice" + opIndex}
                    value={op.additionalPrice}
                    placeholder="Price ¢"
                    onChange={(e) => handleOptionChange(groupIndex, opIndex, e)}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {(!op.additionalPrice || !op.additionalPrice.length > 0) && (
                    <p className="text-xs text-gray-500">
                      Enter 0 if this option should not effect the base price
                      when selected.
                    </p>
                  )}
                </div>
                {opIndex > 0 && (
                  <button
                    type="button"
                    className="w-max rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => removeOptionInGroup(groupIndex, opIndex)}
                  >
                    Remove
                  </button>
                )}
              </div>
            );
          })}

          <div className="flex flex-col gap-2 my-5">
            <button
              className="w-max rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              type="button"
              onClick={() => addOptionInGroup(groupIndex)}
            >
              Add another option
            </button>

            <button
              type="button"
              className="w-max rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={() => removeOptionGroup(groupIndex)}
            >
              Remove this option group
            </button>
          </div>
        </div>
      ))}
      <div className="pt-5 border-gray-200 border-t">
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          type="button"
          onClick={() => addOptionGroup()}
        >
          Add another option group
        </button>
      </div>
    </div>
  );
};

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Amenity = {
  createAmenity: (business, amenities) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      business: business,
      amenities: amenities,
    };

    return axiosInstance.post(
      "/api/amenity/createAmenity",
      bodyParameters,
      config
    );
  },
  getAmenities: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/amenity/getAmenities", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteAmenity: (businessId, amenityId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      businessId: businessId,
      amenityId: amenityId,
    };

    return axiosInstance.put(
      "/api/amenity/deleteAmenity",
      bodyParameters,
      config
    );
  },
};

import React from "react";
import InputBlock from "./InputBlock";
import { twClassNames } from "../../../../utils/twClassNames";

export const RentalData = ({ rentalData, setRentalData, isEditing }) => {
  const handleChange = (index, field, value) => {
    let newRentalData = [...rentalData];

    newRentalData[index][field] = value;
    setRentalData(newRentalData);
  };

  const addRentalDataObj = () => {
    const newRentalData = [...rentalData, { rentalType: "", qty: 1, price: 0 }];
    setRentalData(newRentalData);
  };

  const removeRentalDataObj = (i) => {
    let newRentalData = [...rentalData];
    newRentalData.splice(i, 1);
    setRentalData(newRentalData);
  };
  return (
    <div className="pt-1 sm:border-t sm:border-gray-200 ">
      <div className="flex flex-col gap-1 sm:pt-2">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Rental Data
        </p>
        {isEditing ? (
          <p className="text-amber-600 text-sm">
            Properties related to inventory within this section are not
            editable. Please contact a developer to make changes to any disabled
            properties.
          </p>
        ) : (
          <p className="text-gray-500 text-sm">
            In this section you'll set the inventory & pricing for this rental
            product.
          </p>
        )}
      </div>
      <div className="pt-2">
        {rentalData.map((element, index) => {
          return (
            <div
              key={index}
              className={twClassNames(index > 0 && "border-t mt-5")}
            >
              <InputBlock
                field={`rentalType${index}`}
                label="Rental Type/Label"
                onChange={(e) =>
                  handleChange(index, "rentalType", e.target.value)
                }
                value={element.rentalType}
                showBorder={false}
                helperText={
                  index === 0
                    ? "Enter the type of product here (i.e. If product title is 'Bike Rental', the rental type could be: 'Red Bike'). Leave blank if there is no different types."
                    : undefined
                }
                disabled={isEditing}
                minLength={5}
                required
              />
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor={`qty${index}`}
                  className={twClassNames(
                    "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2",
                    isEditing && "opacity-50"
                  )}
                >
                  Quantity
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="number"
                    step={1}
                    min={1}
                    name={`qty${index}`}
                    value={element.qty}
                    onChange={(e) =>
                      handleChange(index, "qty", Number(e.target.value))
                    }
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={isEditing}
                  />
                  {index === 0 && !isEditing ? (
                    <p className="text-xs text-gray-500">
                      This is the number of this type of rental product that
                      exists (i.e. If the business has 4 x Red Bikes, you'd
                      enter '4')
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor={`price${index}`}
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Price ¢
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="number"
                    step={1}
                    min={0}
                    name={`price${index}`}
                    value={element.price}
                    onChange={(e) =>
                      handleChange(index, "price", Number(e.target.value))
                    }
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
              {index > 0 && !isEditing && (
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-5"
                  onClick={() => removeRentalDataObj(index)}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}

        <div className="pt-5">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-600"
            type="button"
            onClick={() => addRentalDataObj()}
            disabled={isEditing}
          >
            Add another type
          </button>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export default function SelectBlock({
  value,
  field,
  label,
  onChange,
  options,
  id = "",
  helperText = "",
  isDisabled = false,
  index = false,
}) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
      <label
        htmlFor={field}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <select
          name={field}
          id={id}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
          value={value}
          onChange={onChange}
          disabled={!!isDisabled}
        >
          <option key="----" value="----">
            --- Please Select ---
          </option>
          {index === false
            ? options.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                );
              })
            : options.map((_, index) => {
                return (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                );
              })}
        </select>
        {helperText.length > 0 && (
          <p className="text-xs text-gray-500">{helperText}</p>
        )}
      </div>
    </div>
  );
}

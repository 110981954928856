import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessAPI, OrderAPI } from "../../../apis";
import { CSVLink } from "react-csv";

import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Export() {
  const { businessId } = useParams();
  const [orders, setOrders] = useState();
  const [business, setBusiness] = useState();
  const [activeFilter, setActiveFilter] = useState();

  useEffect(() => {
    OrderAPI.getAll(businessId)
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  useEffect(() => {
    BusinessAPI.getById(businessId)
      .then((res) => {
        setBusiness(res.data.business);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  const totalCount = _.sumBy(orders, "amount");

  const headers = [
    { label: "Order number", key: "orderNumber" },
    { label: "Customer name", key: "customerName" },
    { label: "Customer email", key: "customerEmail" },
    { label: "Order amount", key: "orderAmount" },
    { label: "Order status", key: "orderStatus" },
    { label: "Products", key: "products" },
    { label: "Created at", key: "createdAt" },
  ];

  const ordersData = _.map(orders, (order) => {
    return {
      orderNumber: order.orderNumber,
      customerName: order.customer.name,
      customerEmail: order.customer.email,
      orderAmount: `$${(order.amount / 100).toFixed(2)}`,
      orderStatus: order.status,
      products: _.map(order.products, "name"),
      createdAt: new Date(order.createdAt),
    };
  });

  const filterDate = (filterBy) => {
    OrderAPI.ordersByDate(businessId, filterBy)
      .then((res) => {
        setOrders(res.data.orders);
        setActiveFilter(filterBy);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    business && (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Export Data - {business.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Below is a report of orders/bookings
            </p>
          </div>
        </div>

        <div className="bg-white border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5 mt-6">
          <dl className="mx-auto grid max-w-4xl grid-cols-1 lg:grid-cols-2 lg:px-2 xl:px-0">
            <div className="flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-4 sm:px-6 lg:border-t-0 xl:px-8">
              <dt className="text-sm font-medium leading-6 text-gray-500">
                Revenue
              </dt>

              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                ${(totalCount / 100).toFixed(2)}
              </dd>
            </div>
            <div className="flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-4 sm:px-6 lg:border-t-0 xl:px-8 sm:border-l">
              <dt className="text-sm font-medium leading-6 text-gray-500">
                Total Orders
              </dt>
              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {_.size(orders)}
              </dd>
            </div>
          </dl>
        </div>

        <div className="ml-6 pb-4 mt-10 flex w-full gap-x-8 text-sm font-semibold leading-6 sm:w-auto sm:leading-7">
          <span
            className={
              activeFilter === undefined ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate()}>All-time</button>
          </span>
          <span
            className={
              activeFilter === "thirty" ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate("thirty")}>Last 30 days</button>
          </span>
          <span
            className={
              activeFilter === "seven" ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate("seven")}>Last 7 days</button>
          </span>
          <CSVLink
            data={ordersData}
            headers={headers}
            filename={`${business.name}.csv`}
            className="text-red-700"
          >
            Download CSV
          </CSVLink>
        </div>

        <div className="-mx-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-white">
          <table className="min-w-full divide-y divide-gray-300 ">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Order #
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Customer Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Product(s)
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Amount
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {ordersData.map((order, orderIdx) => (
                <tr key={order.orderNumber}>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-transparent",
                      "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                    )}
                  >
                    <div className="font-medium text-gray-900">
                      {order.orderNumber}
                    </div>

                    {orderIdx !== 0 ? (
                      <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {order.customerName}
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {order.customerEmail}
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    <table>
                      {order.products &&
                        order.products.map((product) => (
                          <tr>
                            <td>{product}</td>
                          </tr>
                        ))}
                    </table>
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {order.orderAmount}
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "px-3 py-3.5 text-sm text-gray-500"
                    )}
                  ></td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-transparent",
                      "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                    )}
                  >
                    {orderIdx !== 0 ? (
                      <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}

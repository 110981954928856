import React from "react";
import { twClassNames } from "../../../../utils/twClassNames";

export default function InputBlock({
  value,
  field,
  label,
  onChange,
  showBorder = true,
  helperText,
  disabled = false,
  required = false,
  minLength = undefined,
}) {
  return (
    <>
      <div
        className={twClassNames(
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5",
          showBorder && "sm:border-t sm:border-gray-200"
        )}
      >
        <label
          htmlFor={field}
          className={twClassNames(
            "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2",
            disabled && "opacity-50"
          )}
        >
          {label}
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
            type="text"
            name={field}
            value={value}
            onChange={onChange}
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={disabled}
            required={required}
            minLength={minLength}
          />
          {helperText && !disabled && (
            <p className="text-xs text-gray-500">{helperText}</p>
          )}
        </div>
      </div>
    </>
  );
}

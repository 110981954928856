import _ from "lodash";
import React from "react";

export default function Checkbox({
  title,
  description,
  field,
  values,
  setValues,
  useTitleField,
  isGuests = false,
}) {
  const handleChange = (selected, i) => {
    let selectedField = values[field][i];
    selectedField.selected = !selected;
    let objClone = [...values[field]];
    objClone[i] = selectedField;
    setValues({
      ...values,
      [field]: [...objClone],
    });
  };

  return (
    <>
      {!_.isEmpty(values[field]) ? (
        <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5  border-t">
          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-notifications">
              <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                <div>
                  <div
                    className="text-sm font-semibold leading-6 text-gray-900"
                    id="label-notifications"
                  >
                    {title}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="max-w-lg">
                    <p className="text-sm text-gray-500">{description}</p>
                    <div className="mt-4 space-y-4">
                      {useTitleField ? (
                        values[field].map(({ title, selected }, idx) => (
                          <div className="flex items-center" key={idx}>
                            <input
                              id={idx}
                              type="checkbox"
                              onChange={() => handleChange(selected, idx)}
                              checked={selected}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />

                            <label
                              htmlFor={`item-${title.id}`}
                              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                            >
                              {title}
                            </label>
                          </div>
                        ))
                      ) : isGuests ? (
                        <>
                          {values[field].map(
                            ({ label, selected, guestType }, idx) => (
                              <div className="flex items-center" key={idx}>
                                <input
                                  id={idx}
                                  type="checkbox"
                                  onChange={() => handleChange(selected, idx)}
                                  checked={selected}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />

                                <label
                                  htmlFor={`item-${label}`}
                                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {label} | {guestType}
                                </label>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {values[field].map(({ name, selected }, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={idx}
                                type="checkbox"
                                onChange={() => handleChange(selected, idx)}
                                checked={selected}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />

                              <label
                                htmlFor={`item-${name.id}`}
                                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                              >
                                {name}
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

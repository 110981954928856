import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import SelectBlock from "./SelectBlock";
import { twClassNames } from "../../../../utils/twClassNames";

export default function DynamicPrice({
  prices,
  setPrices,
  maxLimit,
  showGuestType,
  showTopBorder = true,
  isFeeOrAccommodationProduct = false,
}) {
  const doesMinMaxExist = !!prices.some(
    (tier) =>
      (tier.minGuests && tier.minGuests > 0) ||
      (tier.maxGuests && tier.maxGuests > 0)
  );
  const [count, setCount] = useState(1);
  const [isPriceBasedOnTotalGuestCt, setIsPriceBasedOnTotalGuestCt] =
    useState(doesMinMaxExist);

  const handleChange = (index, e, field) => {
    let newPrices = [...prices];

    newPrices[index][field] = e.target.value;

    if (field === "guestType" && e.target.value === "free") {
      newPrices[index].price = 0;
    }

    setPrices(newPrices);
  };

  const addFormFields = () => {
    setPrices([...prices, { label: "", price: 0, guestType: "" }]);
    setCount(count + 1);
  };

  const removeFormFields = (i) => {
    let newPrices = [...prices];
    newPrices.splice(i, 1);
    setPrices(newPrices);
    setCount(count - 1);
  };

  const handleSelectPriceOptions = (e) => {
    setIsPriceBasedOnTotalGuestCt(e.currentTarget.value === "true");
    if (e.currentTarget.value !== "true") {
      // reset price tiers to remove min & max guests
      const newPrices = prices.map((tier) => {
        if (
          (tier.minGuests && tier.minGuests >= 0) ||
          (tier.maxGuests && tier.maxGuests >= 0)
        ) {
          return {
            label: tier.label,
            price: tier.price,
            guestType: tier.guestType,
          };
        }
        return tier;
      });
      setPrices(newPrices);
    }
  };

  return (
    <>
      {isFeeOrAccommodationProduct && (
        <SelectBlock
          value={isPriceBasedOnTotalGuestCt}
          onChange={handleSelectPriceOptions}
          field="isPriceBasedOnTotalGuestCt"
          id={`${uuid()}-isPriceBasedOnTotalGuestCt`}
          label="Are the prices fixed for a range of total guests?"
          options={[
            {
              value: true,
              text: "Yes - prices are per range of guest counts (i.e. 1-3 guests is $5 total)",
            },
            {
              value: false,
              text: "No - prices are a single fee or per guest or per day/night",
            },
          ]}
          index={false}
          helperText="This changes the pricing options available below."
        />
      )}
      {prices.map((element, index) => (
        <div key={index}>
          <div
            className={twClassNames(
              showTopBorder && "sm:border-t sm:border-gray-200",
              "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5"
            )}
          >
            <label
              htmlFor="label"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Pricing label
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                name="label"
                id={`${uuid()}-label`}
                value={element.label}
                onChange={(e) => handleChange(index, e, "label")}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Price ¢
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                name="price"
                id={`${uuid()}-price`}
                value={element.price}
                disabled={element.guestType === "free"}
                onChange={(e) => handleChange(index, e, "price")}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
              />
            </div>
          </div>
          {showGuestType && (
            <SelectBlock
              value={element.guestType}
              onChange={(e) => handleChange(index, e, "guestType")}
              field="guestType"
              id={`${uuid()}-guestType`}
              label="Guest type"
              options={[
                { value: "adult", text: "Adult" },
                { value: "youth", text: "Youth" },
                { value: "general", text: "General" },
                { value: "pet", text: "Pet" },
                { value: "free", text: "Free" },
                { value: "other", text: "Other" },
              ]}
              index={false}
              helperText={
                element.guestType === "free"
                  ? "ⓘ Price must be $0 for this guestType"
                  : undefined
              }
            />
          )}
          {isPriceBasedOnTotalGuestCt && (
            <>
              <SelectBlock
                value={element.minGuests}
                label="Minimum Guests"
                field="minGuests"
                id={`${uuid()}-minGuests`}
                onChange={(e) => handleChange(index, e, "minGuests")}
                options={[...new Array(30)]}
                index
              />
              <SelectBlock
                value={element.maxGuests}
                label="Maximum Guests"
                field="maxGuests"
                id={`${uuid()}-maxGuests`}
                onChange={(e) => handleChange(index, e, "maxGuests")}
                options={[...new Array(30)]}
                index
              />
            </>
          )}
          {index ? (
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={() => removeFormFields(index)}
            >
              Remove last price tier
            </button>
          ) : null}
        </div>
      ))}
      {count !== maxLimit ? (
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          type="button"
          onClick={() => addFormFields()}
        >
          Add another price tier
        </button>
      ) : null}
    </>
  );
}

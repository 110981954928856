import React, { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { GuestAPI } from "../../../apis";
import { toast } from "react-toastify";
import { guestOptions } from "./AddGuest";

const EditGuestModal = ({ guest, isOpen, setIsOpen }) => {
  const [updatedGuest, setUpdatedGuest] = useState(guest);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUpdatedGuest((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    GuestAPI.update(updatedGuest)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            res.data.message ?? "Guest has been successfully updated!"
          );
          closeModal();
          window.location.reload(); // state hack
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Body */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Editing Guest:&nbsp;
                        <span className="text-purple-800">{guest.label}</span>
                      </Dialog.Title>
                      <form id="editGuestForm" onSubmit={handleSubmit}>
                        <div className="mt-5 w-full flex flex-col gap-3">
                          <div>
                            <label
                              htmlFor="guestType"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Label of guest
                            </label>
                            <div className="pt-1">
                              <input
                                type="text"
                                name="label"
                                value={updatedGuest.label}
                                onChange={handleChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="guestType"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Guest Type
                            </label>
                            <div className="pt-1">
                              <select
                                name="guestType"
                                value={updatedGuest.guestType}
                                onChange={handleChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                {guestOptions.map((op) => (
                                  <option value={op.value} key={op.value}>{op.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="value"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Minimum # of guests (optional)
                            </label>
                            <div className="pt-1">
                              <input
                                type="number"
                                name="minimum"
                                min={0}
                                step={1}
                                value={updatedGuest.minimum}
                                onChange={handleChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="value"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Maximum # of guests (optional)
                            </label>
                            <div className="pt-1">
                              <input
                                type="number"
                                name="maximum"
                                min={0}
                                step={1}
                                value={updatedGuest.maximum}
                                onChange={handleChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    form="editGuestForm"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setUpdatedGuest(guest);
                      closeModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditGuestModal;

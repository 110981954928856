import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Business } from "../../apis/Business";
import { Organization } from "../../apis/Organization";
import { Link } from "react-router-dom";

export default function AdminOrganizationDetail() {
  const { organizationId } = useParams();
  const [organization, setOrganization] = useState();
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    Organization.getById(organizationId)
      .then((res) => {
        setOrganization(res.data.organization);
      })
      .catch((err) => {
        console.log(err);
      });
    Business.getBusinesses(organizationId)
      .then((res) => {
        setBusinesses(res.data.businesses);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [organizationId]);

  return (
    organization && (
      <div className="px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              {organization.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the businesses for {organization.name}
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="text-lg font-semibold text-gray-900 border-t border-gray-300 pt-3 pb-10">
                Businesses
              </h1>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {businesses &&
                  businesses.map((business) => (
                    <li
                      key={business.name}
                      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                    >
                      <Link
                        to={`/admin/business/${business._id}`}
                        className="block hover:bg-gray-50"
                      >
                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-md font-medium text-gray-900">
                                {business.name}
                              </h3>
                            </div>
                            <div>
                              <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                                {business.isActive ? "Active" : "Inactive"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

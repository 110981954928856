import { BusinessAPI } from "../../../apis";
import { toast } from "react-toastify";

const Contact = ({ business, contactInfo, setContactInfo }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({
      ...contactInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await BusinessAPI.updateBusiness(business, "contact", contactInfo)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business address has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Business contact information
        </h3>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Phone number
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="phone"
              value={contactInfo?.phone}
              onChange={handleInputChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Email address
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="email"
              value={contactInfo?.email}
              onChange={handleInputChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};
export default Contact;

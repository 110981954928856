import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../State/AppContext';
import BusinessNav from './BusinessNav';
import OrganizationsNav from './OrganizationsNav';
import AdminNav from './AdminNav';
import { Business } from '../../apis/Business';
import { Organization } from '../../apis/Organization';

const DesktopNav = ({ logout }) => {
  const { state } = useContext(AppContext);
  const [name, setName] = useState('');
  const { role, organization, business } = state.user;
  const [businessType, setBusinessType] = useState('');

  useEffect(() => {
    if (role === 'Organization') {
      Organization.getById(organization)
        .then((res) => {
          setName(res.data.organization.name);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (role === 'Business') {
      Business.getById(business)
        .then((res) => {
          setName(res.data.business.name);
          setBusinessType(res.data.business.businessType);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setName('TTC Admin');
    }
  }, [business, organization, role]);

  return (
    <div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
      <div className='flex min-h-0 flex-1 flex-col bg-gray-800'>
        <div className='flex flex-1 flex-col overflow-y-auto pt-5 pb-4'>
          <div className='flex flex-shrink-0 items-center px-4 text-white font-bold text-lg'>
            {name}
          </div>
          <nav className='mt-5 flex-1 space-y-1 px-2'>
            {role === 'Admin' && <AdminNav />}
            {role === 'Organization' && <OrganizationsNav />}
            {role === 'Business' && <BusinessNav businessType={businessType} />}
          </nav>
        </div>
        <div className='flex flex-shrink-0 bg-gray-700 p-4'>
          <div className='flex items-center'>
            <div className='ml-3'>
              <p className='text-md font-medium text-gray-300 group-hover:text-gray-200'>
                <button onClick={() => logout()}>Logout</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DesktopNav;

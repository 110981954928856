import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { OrderAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";

const RefundModal = ({ setShowModal, order }) => {
  const { state } = useContext(AppContext);
  const [refundAmount, setRefundAmount] = useState("");
  const [orderStatus, setOrderStatus] = useState(
    order.orderStatus ?? "complete"
  );
  const [refundApplicationFee, setRefundApplicationFee] = useState(false);

  const isCurrentStatusCancelled = order.orderStatus === "cancelled";

  const validateAmounts = (refundAmount) => {
    // There was already a partial refund, use that amount for validation
    if (order.amountRemaining > 0) {
      if (refundAmount > order.amountRemaining) {
        toast.error(
          `Error: Refund amount cannot be greater than ${(
            order.amountRemaining / 100
          ).toFixed(2)}`
        );
        return false;
      }
    } else {
      if (refundAmount > order.amount) {
        toast.error(
          `Error: Refund amount cannot be greater than ${(
            order.amount / 100
          ).toFixed(2)}`
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateAmounts(refundAmount)) {
      let refundStatus;
      if (refundAmount * 100 < order.amount) {
        refundStatus = "partial";
      }

      if (refundAmount * 100 === order.amount) {
        refundStatus = "full";
      }

      if (order.amountRemaining > 0) {
        if (refundAmount * 100 === order.amountRemaining) {
          refundStatus = "full";
        }
      }

      await OrderAPI.createRefund(
        order,
        refundAmount,
        refundStatus,
        orderStatus,
        refundApplicationFee
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Reservation has been refunded!");
            setShowModal(false);
            window.location.reload(); // state hack
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setShowModal(false);
        });
    }
  };

  const handleClick = (e) => {
    if (e.currentTarget.checked) {
      setOrderStatus("cancelled");
    } else {
      setOrderStatus("complete");
    }
  };
  const handleRefundApplicationFee = (e) => {
    if (e.currentTarget.checked) {
      setRefundApplicationFee(true);
    } else {
      setRefundApplicationFee(false);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">Refund Payment</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative flex-auto">
              <form onSubmit={handleSubmit}>
                <div role="group" aria-labelledby="label-availability ">
                  <div className="max-w-lg ml-auto">
                    <div
                      className={`space-y-4 ${
                        !isCurrentStatusCancelled ? "border-b" : ""
                      }`}
                    >
                      <div className="px-6 pb-6">
                        <label
                          htmlFor="push-everything"
                          className="mt-5 block text-sm font-medium text-gray-800"
                        >
                          Refund amount
                          <br />
                          <span className="text-gray-400 text-xs">
                            You may also enter a partial amount.
                          </span>
                        </label>
                        <div className="flex items-center justify-end mt-4">
                          <input
                            type="number"
                            name="refundAmount"
                            id="refundAmount"
                            autoComplete="refund-amount"
                            value={refundAmount}
                            onChange={(e) => setRefundAmount(e.target.value)}
                            className="border-gray-300 block w-full max-w-lg rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        {order && order.amountRemaining > 0 ? (
                          <div className="text-xs text-gray-600">
                            Amount remaining that's refundable: $
                            {(order.amountRemaining / 100).toFixed(2)}
                          </div>
                        ) : null}

                        {(state.user.role === "Admin" ||
                          (state.user.originalUser &&
                            state.user.originalUser.role === "Admin")) && (
                          <div className="mt-3 flex justify-end items-center gap-2 text-orange-700">
                            <label htmlFor="refundApplicationFee">
                              Refund application fee
                            </label>
                            <input
                              type="checkbox"
                              name="refundApplicationFee"
                              checked={refundApplicationFee ?? false}
                              onChange={handleRefundApplicationFee}
                              className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {!isCurrentStatusCancelled && (
                      <div className="mt-4 px-6 pb-6">
                        <span className="block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2 mr-2">
                          Cancel this reservation
                          <br />
                          <span className="text-gray-400 text-xs">
                            Select this if you'd like to also cancel the
                            reservation.
                          </span>
                        </span>

                        <div className="mt-3 flex justify-end items-center">
                          <input
                            type="checkbox"
                            name="cancel"
                            checked={
                              orderStatus === "cancelled" ? "checked" : false
                            }
                            disabled={isCurrentStatusCancelled}
                            onChange={handleClick}
                            className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-end pt-4 border-t border-solid border-slate-200 rounded-b pb-4">
                  <button
                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default RefundModal;

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../State/AppContext";
import { AmenityAPI } from "../../../apis/index";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function Amenities() {
  const { state } = useContext(AppContext);
  const [amenities, setAmenities] = useState();

  const { role, business } = state.user;

  useEffect(() => {
    AmenityAPI.getAmenities(business)
      .then((res) => {
        setAmenities(res?.data?.amenities);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  const removeAmentity = (amenity) => {
    AmenityAPI.deleteAmenity(business, amenity)
      .then(() => {
        toast.success("Amenity successfully removed!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return role === "Business" ? (
    <div className="px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Amenities</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all amentities</p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/business/amentity/add"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
            type="button"
          >
            Add amenity
          </Link>
        </div>
      </div>

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
        {amenities &&
          amenities.map((amenity) => (
            <li
              key={amenity.name}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
            >
              <div className="flex flex-1 flex-col p-8">
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {amenity.name}
                </h3>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <button
                      onClick={() => removeAmentity(amenity._id)}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <span className="bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                        Delete
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  ) : null;
}

import React, { useContext, useEffect, useState } from "react";
import { BusinessAPI, OrderAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";
import _ from "lodash";
import { OrderMenu } from "./OrderMenu";
import { twClassNames } from "../../../utils/twClassNames";

export default function Orders() {
  const { state } = useContext(AppContext);
  const [orders, setOrders] = useState();
  const [businessData, setBusinessData] = useState(null);

  const { business } = state.user;

  useEffect(() => {
    OrderAPI.getAll(business)
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);
  useEffect(() => {
    if (business) {
      BusinessAPI.getById(business).then((res) =>
        setBusinessData(res.data.business)
      );
    }
  }, [business]);

  const total = _.sumBy(orders, (o) => {
    return o.amount || 0;
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Orders
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Below is a list of all of the orders created through the web site.
          </p>
        </div>
      </div>
      <div className="text-right mt-8 mb-2">
        <p className="text-base font-semibold leading-6 text-gray-900">
          The sum of all orders to date: ${(total / 100).toFixed(2)}
        </p>
      </div>
      <div className="-mx-4  ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Order #
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Customer Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Customer Email
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Amount
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.map((order, orderIdx) => {
                return (
                  <tr key={order._id}>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                      )}
                    >
                      <div className="font-medium text-gray-900">
                        {order.orderNumber}
                      </div>

                      {orderIdx !== 0 ? (
                        <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {order?.customer?.name} <br />
                      <span className="text-xs">{order?.customer?.id}</span>
                    </td>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {order?.customer?.email}
                    </td>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      $
                      {order.orderType === "manual_entry"
                        ? 0
                        : (order.amount / 100).toFixed(2)}
                      <br />
                      <span className="text-xs text-red-600">
                        {!_.isUndefined(order.refundStatus)
                          ? `(Refunded $${(order.amountRefunded / 100).toFixed(
                              2
                            )})`
                          : ""}
                      </span>
                    </td>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-gray-200",
                        "px-3 py-3.5 text-sm text-gray-500"
                      )}
                    ></td>
                    <td
                      className={twClassNames(
                        orderIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                      )}
                    >
                      <OrderMenu order={order} businessData={businessData} />

                      {orderIdx !== 0 ? (
                        <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" />
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

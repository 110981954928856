import InputBlock from "./Fields/InputBlock";
import ImageUpload from "./Fields/ImageUpload";
import Checkbox from "./Fields/Checkbox";
import DynamicPrice from "./Fields/DynamicPrice";
import { DisplaySettings } from "./Fields/DisplaySettings";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import SpecialRequest from "./Fields/SpecialRequest";
import SingleCheckBox from "./Fields/SingleCheckBox";
import SeasonalOverrides from "./Fields/SeasonalOverrides";
import { Options } from "./Fields/Options";
import DynamicInventory from "./Fields/DynamicInventory";
import EventAvailability from "./Fields/EventAvailability";
import DynamicDates from "./Fields/DynamicDates";
import SelectBlock from "./Fields/SelectBlock";
import { productTypes } from "./AddProduct";

export default function ExperienceEditForm({
  handleInputChange,
  handleSubmit,
  submitted,
  values,
  prices,
  setPrices,
  setValues,
  options,
  setOptions,
  imageUpload,
  showGuestType,
  seasonalOverrides,
  setSeasonalOverrides,
  inventory,
  setInventory,
  eventAvailability,
  setEventAvailability,
  globalAvailability,
  setGlobalAvailability,
  defaultOptions,
}) {
  const editorRef = useRef(null);

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Edit {values.title}
        </h3>

        <ImageUpload imageUpload={imageUpload} image={values.image} />

        <div className="space-y-2 sm:space-y-4">
          <InputBlock
            value={values.title}
            onChange={handleInputChange}
            field="title"
            label="Title"
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 border-t">
            <label
              htmlFor={values.priceBlurb}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
            </label>
            <div className="col-span-2">
              <Editor
                apiKey={"3u557s7493kor583in0tern82p6wzpw7leqapaxo3wvhcc0v"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={values.description}
                onEditorChange={(content, editor) =>
                  handleInputChange(content, "description")
                }
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "image",
                    "code",
                    "table",
                    "link",
                    "media",
                    "codesample",
                    "lists",
                  ],
                  toolbar:
                    "undo redo | blocks fontsize | bold italic underline | link mergetags  | checklist numlist bullist indent outdent",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
                }}
              />
            </div>
          </div>
          <SingleCheckBox
            label={
              <>
                Is this product <strong>only</strong> to be used as an add-on
                for other products?
              </>
            }
            values={values}
            setValues={setValues}
            name="isAddOnOnly"
            helperText="Select me for yes"
          />
          <SelectBlock
            value={values.minOccupancy}
            onChange={handleInputChange}
            field="minOccupancy"
            label="Minimum Occupancy"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.occupancy}
            onChange={handleInputChange}
            field="occupancy"
            label="Maximum Occupancy"
            options={defaultOptions}
            index={true}
          />
          <SpecialRequest
            description={values.specialRequest.description}
            isActive={values.specialRequest.isActive}
            setValues={setValues}
          />
          <DisplaySettings
            values={values}
            setValues={setValues}
            productType={productTypes.Event}
          />
          <DynamicInventory
            inventory={inventory}
            setInventory={setInventory}
            isEditing
          />
          <EventAvailability
            isMultiDayEvent={values.displaySettings.isMultiDayEvent}
            eventAvailability={eventAvailability}
            setEventAvailability={setEventAvailability}
          />
          <DynamicDates
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            title="Availability Exception Section"
            description="Set the range(s) of dates you'd like to set as closed dates for this product here. This will occurr regardless of the recurring dates set above."
            displayLongWeekendOption={false}
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 border-t">
            <label
              htmlFor={values.priceBlurb}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Price blurb
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                name="priceBlurb"
                value={values.priceBlurb}
                onChange={handleInputChange}
                className="block h-20 w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              ></textarea>
            </div>
          </div>
          <DynamicPrice
            prices={prices}
            setPrices={setPrices}
            maxLimit={3}
            showGuestType={showGuestType}
          />
          <SeasonalOverrides
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            title="Seasonal Overrides Section"
            description="The pricing you set here will override the product's base properties for the date range you select"
            hideMinNightsOverride
          />
          <Options options={options} setOptions={setOptions} />
          <Checkbox
            title="Product Add-ons"
            description="These will be the available add-ons for your product."
            field="addOns"
            useTitleField={true}
            values={values}
            setValues={setValues}
          />
          <Checkbox
            title="Product Amenities"
            description="These will be included in your product."
            field="amenities"
            values={values}
            setValues={setValues}
          />
          <Checkbox
            title="Product Guests"
            description="These will be the guest options displayed on this product."
            field="guests"
            values={values}
            setValues={setValues}
            isGuests
          />

          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 pt-1 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="isActive"
              className="block text-sm font-semibold text-red-800"
            >
              Deactivate this product?
            </label>
            <div>
              <input
                type="checkbox"
                name="isActive"
                checked={values.isActive === false ? "checked" : false}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    isActive: !e.target.checked,
                  }));
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8 pr-8">
        <div className="flex justify-end">
          <button
            disabled={submitted}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

import React from "react";

export default function BooleanSelectBlock({
  value,
  onChange,
  name,
  label,
  helperText,
  disabled = false,
}) {
  const booleanOptions = [
    { text: "Yes", value: true.toString() },
    { text: "No", value: false.toString() },
  ];
  const handleChange = (e) => {
    onChange(Boolean(e.target.value === "true"));
  };
  return (
    <>
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        {helperText && <p className="text-gray-500 text-xs">{helperText}</p>}
      </div>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <select
          name={name}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          value={value.toString()}
          onChange={handleChange}
          disabled={disabled}
        >
          <option key="----" value="----">
            --- Please Select ---
          </option>
          {booleanOptions.map((op) => {
            return (
              <option key={op.value} value={op.value}>
                {op.text}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const User = {
  login: async (username, password) => {
    return await axiosInstance.post("/api/auth/login", {
      username,
      password,
    });
  },
  proxy: async (businessId, role, originalUser) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      businessId: businessId,
      role: role,
      originalUser: originalUser,
    };

    return await axiosInstance.post("/api/auth/proxy", bodyParameters, config);
  },
  getAll: () => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/user/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  addUser: async (
    firstName,
    lastName,
    username,
    password,
    role,
    organization
  ) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      firstName: firstName,
      lastName: lastName,
      username: username,
      password: password,
      role: role,
      organization: organization,
    };

    return await axiosInstance.post(
      "/api/user/addUser",
      bodyParameters,
      config
    );
  },
  addUserToBusiness: async (
    firstName,
    lastName,
    username,
    password,
    business
  ) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      firstName: firstName,
      lastName: lastName,
      username: username,
      password: password,
      business: business,
    };

    return await axiosInstance.post(
      "/api/user/addUserToBusiness",
      bodyParameters,
      config
    );
  },
  changePassword: async (id, currentPassword, password) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      userId: id,
      currentPassword: currentPassword,
      password: password,
    };

    return await axiosInstance.post(
      "/api/user/changePassword",
      bodyParameters,
      config
    );
  },
};

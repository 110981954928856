import React, { useState, useEffect, useCallback, useContext } from "react";
import { BookingAPI } from "../../apis/index";
import moment from "moment";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { AppContext } from "../State/AppContext";
import AddCalendarEvent from "./AddCalendarEvent";
import _ from "lodash";
import { CalendarIntegration } from "./CalendarIntegration";

function currencyFormat(num) {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const UpcomingBookings = () => {
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  const getUpcomingBookings = useCallback(() => {
    BookingAPI.upcomingBookings(business)
      .then((res) => {
        if (res.status === 200) {
          const { upcomingBookings } = res.data;

          const groups = upcomingBookings.reduce((groups, booking) => {
            const date = new Date(booking.startDateTime).toLocaleDateString(
              [],
              {
                month: "long",
              }
            );
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(booking);
            return groups;
          }, {});

          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              bookings: groups[date],
            };
          });
          setUpcomingBookings(groupArrays);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  useEffect(() => {
    getUpcomingBookings();
  }, [getUpcomingBookings]);

  const routeChange = (business, orderNumber) => {
    const url = `https://reservations.booker.tech/?bkr_id=${business}&order_id=${orderNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div className="calendar">
      <div className="flex mx-auto divide-x-2 gap-10 h-screen">
        <div className="w-full">
          <div className="md:flex md:items-center md:justify-between border-b pb-4">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Upcoming Bookings
              </h2>
            </div>

            <div className="mt-4 flex md:ml-4 md:mt-0">
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => setShowModal(true)}
              >
                Add a Manual Booking
              </button>
            </div>
            {showModal ? (
              <AddCalendarEvent
                setShowModal={setShowModal}
                business={business}
              />
            ) : null}
          </div>

          <CalendarIntegration />

          <ol className="mt-4 w-3/4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
            {upcomingBookings.length > 0 &&
              upcomingBookings.map((booking, index) => {
                return (
                  <div key={index}>
                    <h2 className="font-semibold text-xl mb-1 mt-8">
                      {booking.date}
                    </h2>
                    {booking.bookings.map((bookingDetail) => {
                      return (
                        <li
                          key={bookingDetail._id}
                          className="relative flex space-x-6 py-6 xl:static"
                        >
                          <div className="flex-auto">
                            <h3 className="pr-10 font-semibold text-sm text-gray-600 xl:pr-0">
                              {bookingDetail.name === ""
                                ? _.startCase(
                                    _.toLower(bookingDetail.orderType)
                                  )
                                : bookingDetail.name}
                            </h3>
                            <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                              <div className="flex items-start space-x-3">
                                <dt className="mt-0.5">
                                  <span className="sr-only">Date</span>
                                  <CalendarIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </dt>
                                <dd>
                                  <time dateTime={bookingDetail.startDateTime}>
                                    <Link
                                      className="text-blue-700"
                                      onClick={() =>
                                        routeChange(
                                          bookingDetail.business,
                                          bookingDetail.orderNumber
                                        )
                                      }
                                    >
                                      {moment(
                                        bookingDetail.startDateTime
                                      ).format("MMMM DD")}{" "}
                                      -{" "}
                                      {moment(bookingDetail.endDateTime).format(
                                        "MMMM DD"
                                      )}
                                    </Link>
                                  </time>

                                  <div className="flex">
                                    <span className="text-xs text-gray-600">
                                      Reservation ID:{" "}
                                      {bookingDetail.orderNumber}
                                      <svg
                                        data-tooltip-id="copyToolTip"
                                        data-tooltip-content="Click to copy!"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-4 h-4 cursor-pointer inline ml-2"
                                        onClick={() =>
                                          navigator.clipboard.writeText(
                                            bookingDetail.orderNumber
                                          )
                                        }
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                                        />
                                      </svg>
                                      <Tooltip id="copyToolTip" />
                                    </span>
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center">
                            <div className="flex">
                              {bookingDetail.products.map((product) => (
                                <span
                                  className="text-sky-500 font-bold"
                                  key={product._id}
                                >
                                  {product?.title}
                                </span>
                              ))}
                            </div>
                            Total:{" "}
                            <span className="text-gray-600">
                              {_.isUndefined(bookingDetail.amount)
                                ? "N/A"
                                : currencyFormat(bookingDetail.amount / 100)}
                            </span>
                            <div className="flex">
                              {bookingDetail.products.map((product) => (
                                <span
                                  className="text-sky-500 font-bold"
                                  key={product._id}
                                >
                                  {product?.title}
                                </span>
                              ))}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </div>
                );
              })}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default UpcomingBookings;

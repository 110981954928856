import React, { useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import moment from "moment";
import { OrderAPI } from "../../../apis";

const ChangeDatesModal = ({ order, businessTimeZone, isOpen, setIsOpen }) => {
  const [dates, setDates] = useState({
    start: utcToZonedTime(order.startDateTime, businessTimeZone),
    end: utcToZonedTime(order.endDateTime, businessTimeZone),
  });
  const [error, setError] = useState("");

  const handleDateSelect = (date, field) => {
    setDates((prev) => ({ ...prev, [field]: date }));

    if (
      (field === "start" && moment(dates.end).isBefore(date)) ||
      (field === "end" && moment(date).isBefore(dates.start))
    ) {
      setError("Start date cannot be before the end date");
    } else {
      setError("");
    }
  };

  const handleSetNewDates = async () => {
    // Process the date to the business' timeZone
    const newStartDate = zonedTimeToUtc(
      dates.start,
      businessTimeZone
    ).toISOString();

    const newEndDate = zonedTimeToUtc(
      dates.end,
      businessTimeZone
    ).toISOString();

    // Update order with new dates in 3 spots
    const updatedOrder = {
      ...order,
      startDateTime: newStartDate,
      endDateTime: newEndDate,
      metadata: [
        {
          ...order.metadata[0],
          productInfo: order.metadata[0].productInfo.map((item) => {
            if (!item.isAddOnProduct) {
              return {
                ...item,
                cartItemStartDateTime: newStartDate,
                cartItemEndDateTime: newEndDate,
              };
            }
            return item;
          }),
        },
      ],
    };

    for (const key in updatedOrder.productGroups) {
      if (updatedOrder.productGroups.hasOwnProperty(key)) {
        const group = updatedOrder.productGroups[key];
        if (!group.isAddOn) {
          group.startDateTime = newStartDate;
          group.endDateTime = newEndDate;
        }
      }
    }

    await OrderAPI.update(updatedOrder)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setIsOpen(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message);
      });
  };

  return (
    order && (
      <>
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-gray-50 px-6 py-5">
                      <Dialog.Title
                        as="h3"
                        className="font-semibold text-lg leading-6 text-gray-900"
                      >
                        Change Reservation #{order.orderNumber} Dates
                      </Dialog.Title>
                    </div>
                    <div className="bg-white p-6 text-left flex flex-col gap-3">
                      <div className="grid grid-cols-3 gap-2">
                        <label
                          htmlFor="startDateTime"
                          className="font-medium text-sm self-center"
                        >
                          Start Date
                        </label>
                        <div className="col-span-2">
                          <ReactDatePicker
                            showTimeSelect
                            value={dates.start}
                            selected={new Date(dates.start)}
                            name="startDateTime"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            onChange={(date) => handleDateSelect(date, "start")}
                          />
                          <p className="text-xs text-gray-600">
                            Original:&nbsp;
                            {moment(
                              utcToZonedTime(
                                order.startDateTime,
                                businessTimeZone
                              )
                            ).format("MMM D, YYYY h:mm a")}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        <label
                          htmlFor="endDateTime"
                          className="font-medium text-sm self-center"
                        >
                          End Date
                        </label>
                        <div className="col-span-2">
                          <ReactDatePicker
                            showTimeSelect
                            value={dates.end}
                            selected={new Date(dates.end)}
                            name="endDateTime"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            onChange={(date) => handleDateSelect(date, "end")}
                          />
                          <p className="text-xs text-gray-600">
                            Original:&nbsp;
                            {moment(
                              utcToZonedTime(
                                order.endDateTime,
                                businessTimeZone
                              )
                            ).format("MMM D, YYYY h:mm a")}
                          </p>
                        </div>
                      </div>
                      {error.length > 0 && (
                        <div className="pt-3">
                          <p className="text-sm font-medium text-red-700">
                            Error: {error}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
                        onClick={handleSetNewDates}
                        disabled={error.length > 0}
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  );
};

export default ChangeDatesModal;

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../State/AppContext";
import { DiscountAPI, ProductGroupAPI } from "../../../apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Checkbox from "../Product/Fields/Checkbox";

export default function AddDiscount() {
  const [defaultProductGroups, setDefaultProductGroups] = useState([]);
  const defaultDiscountState = {
    name: "",
    amount: 0,
    criteria: 0,
    appliedOnAdditional: false,
    criteriaType: "guests",
    productGroups: defaultProductGroups,
  };
  const [discount, setDiscounts] = useState(defaultDiscountState);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const { business } = state.user;

  useEffect(() => {
    ProductGroupAPI.getAllActiveByBusiness(business).then((res) => {
      const formattedProductsArray = res.data.products
        .filter((p) => p.productType !== "fee")
        .map((p) => ({ _id: p._id, title: p.title, selected: false }));

      setDefaultProductGroups(formattedProductsArray);

      setDiscounts((prev) => ({
        ...prev,
        productGroups: formattedProductsArray,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedProductGroupIds = discount.productGroups
      .filter((p) => p.selected)
      .map((p) => p._id);
    const updatedDiscount = {...discount, productGroups: selectedProductGroupIds}

    await DiscountAPI.createDiscount(business, [updatedDiscount])
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business discount has been successfully created!");
          navigate("/business/discounts");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleChange = (e, isPercentage, isCheckBox, isNumber) => {
    const value = isCheckBox
      ? e.target.checked
      : isNumber
      ? Number(e.target.value)
      : e.target.value;

    let newDiscounts = {...discount};

    newDiscounts[e.target.name] = isPercentage ? value / 100 : value;

    setDiscounts(newDiscounts);
  };

  const handleRadioInputChange = (e) => {
    const { name, id } = e.target;
    let newDiscounts = {...discount};

    newDiscounts[name] = id;

    setDiscounts(newDiscounts);
  };

  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Add discount
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 mb-10">
            <p className="mt-2 flex items-center text-sm text-gray-500">
              The discount you add below will be applied to your business.
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 pb-5">
            <label
              htmlFor="time"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Name of discount
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                name="name"
                autoComplete="given-name"
                value={discount.name}
                onChange={(name) => handleChange(name)}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
              <p className="text-xs text-gray-400">E.g. "4+ Group Discount"</p>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 pb-5">
            <label
              htmlFor="Amount"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Amount (%) of discount
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="amount"
                min={1}
                step={1}
                max={100}
                value={discount.amount * 100}
                onChange={(e) => handleChange(e, true, false, true)}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 pb-5">
            <label
              htmlFor="Amount"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Criteria
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="criteria"
                min={1}
                value={discount.criteria}
                onChange={(e) => handleChange(e, false, false, true)}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
              <p className="text-xs text-gray-400">
                E.g. If the discount is to be applied on group totals of 4+
                people, the criteria would be: 4.
              </p>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 pb-5">
            <div>
              <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
                Criteria Type
              </p>
              <p className="text-xs text-gray-400">
                This is to select what this discount will be applied to. Either
                the guest count or number of nights selected.
              </p>
            </div>
            <div className="flex flex-col">
              <label
                className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
                htmlFor="guests"
              >
                <input
                  type="radio"
                  name="criteriaType"
                  id="guests"
                  className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                  checked={
                    discount.criteriaType === "guests" ? "checked" : undefined
                  }
                  onChange={(e) => handleRadioInputChange(e)}
                />
                Guests
              </label>
              <label
                className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
                htmlFor="duration"
              >
                <input
                  type="radio"
                  name="criteriaType"
                  id="nights"
                  className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                  checked={
                    discount.criteriaType === "nights" ? "checked" : undefined
                  }
                  onChange={(e) => handleRadioInputChange(e)}
                />
                Nights
              </label>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 pb-5">
            <div>
              <label
                htmlFor="Amount"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Discount to be applied on additional criteria only
              </label>
              <p className="text-xs text-gray-400">
                Select this if the discount is to be applied only on additional
                criteria (guests or nights) above the criteria chosen.
              </p>
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="checkbox"
                name="appliedOnAdditional"
                checked={discount.appliedOnAdditional}
                onChange={(e) => handleChange(e, false, true)}
                className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </div>

          <Checkbox
            title="Products"
            description="These will be the products this discount will apply to"
            values={discount}
            setValues={setDiscounts}
            field="productGroups"
            useTitleField
          />
        </div>

        <div className="flex justify-between pt-8 pr-8 mt-10">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

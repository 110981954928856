import React from "react";
import BooleanSelectBlock from "../Product/Fields/BooleanSelectBlock";
import { twClassNames } from "../../../utils/twClassNames";

const SpecialRequest = ({ customerSpecialRequest, setBusinessRules }) => {
  const handleChange = (payload, field) => {
    const updatedRequest = { ...customerSpecialRequest };
    updatedRequest[field] = payload;
    if (field === "isMandatory") {
      updatedRequest.minCharacters = 0;
    }
    if (field === "isActive" && payload === false) {
      updatedRequest.isMandatory = false;
      updatedRequest.minCharacters = 0;
    }
    setBusinessRules((prev) => ({
      ...prev,
      customerSpecialRequest: updatedRequest,
    }));
  };
  return (
    <div className="space-y-3">
      <h4 className="text-sm font-medium leading-6 text-gray-50 bg-gray-400 rounded-full w-max px-4">
        Customer special requests
      </h4>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
        <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
          Show special request box?
          <span className="block text-gray-400 text-xs font-normal">
            Default is yes
          </span>
        </span>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <BooleanSelectBlock
            name="isActive"
            value={customerSpecialRequest?.isActive ? true : false}
            onChange={(e) => handleChange(e, "isActive")}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
        <span
          className={twClassNames(
            "block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2",
            !customerSpecialRequest?.isActive && "opacity-50"
          )}
        >
          Is the special request mandatory?
          <span className="block text-gray-400 text-xs font-normal">
            Default is no
          </span>
        </span>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <BooleanSelectBlock
            name="isMandatory"
            value={customerSpecialRequest?.isMandatory ? true : false}
            onChange={(e) => handleChange(e, "isMandatory")}
            disabled={!customerSpecialRequest?.isActive}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
        <span
          className={twClassNames(
            "block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2",
            (!customerSpecialRequest?.isActive ||
              !customerSpecialRequest.isMandatory) &&
              "opacity-50"
          )}
        >
          Set the minimum characters{" "}
          <span className="font-normal text-gray-400">(optional)</span>
        </span>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
            type="number"
            step={1}
            min={0}
            id="minCharacters"
            name="minCharacters"
            value={customerSpecialRequest?.minCharacters}
            onChange={(e) =>
              handleChange(Number(e.currentTarget.value), "minCharacters")
            }
            disabled={
              !customerSpecialRequest?.isActive ||
              !customerSpecialRequest.isMandatory
            }
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
        <span
          className={twClassNames(
            "block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2",
            !customerSpecialRequest?.isActive && "opacity-50"
          )}
        >
          Set the special request label/title{" "}
          <span className="font-normal text-gray-400">(optional)</span>
          <span className="font-normal text-gray-400 block text-xs">
            Defaults to "Special Requests"
          </span>
        </span>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
            type="text"
            id="description"
            name="description"
            value={customerSpecialRequest?.description}
            onChange={(e) =>
              handleChange(e.currentTarget.value, "description")
            }
            disabled={!customerSpecialRequest?.isActive}
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          />
        </div>
      </div>
    </div>
  );
};

export default SpecialRequest;

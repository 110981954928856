import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../State/AppContext";
import {
  AmenityAPI,
  GuestAPI,
  ProductAPI,
  ProductGroupAPI,
} from "../../../apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import RentalForm from "./RentalForm";
import LodgeForm from "./LodgeForm";
import ExperienceForm from "./ExperienceForm";
import FeeForm from "./FeeForm";
import { repeatTypes } from "./Fields/constants";

export const availabilityTypes = {
  BlackedOut: "blacked_out",
  LongWeekend: "long_weekend",
};

export const productTypes = {
  Accommodation: "accommodation",
  Event: "event",
  Fee: "fee",
  Rental: "rental",
};

export const seasonalOverrideFields = {
  Pricing: "pricing",
  MinNights: "minNights",
};

const defaultOptions = [...new Array(20)];

export const initialProductValues = {
  // All products:
  title: "",
  priceBlurb: "",
  description: "",
  addOns: [],
  amenities: [],
  guests: [],
  isActive: true,
  specialRequest: {
    isActive: false,
    description: "",
  },
  isAddOnOnly: false,
  // Accommodations:
  bedType: "",
  bedCount: 1,
  bedrooms: 1,
  occupancy: 10,
  minNights: 1,
  maxNights: "----",
  qty: 1,
  // Fees:
  canSelectMultiple: false,
  maxQuantity: 0,
  isPerDay: false,
  // Rentals:
  minDays: 1,
  maxDays: 1,
  priceType: "daily",

  displaySettings: {
    // Events:
    isMultiDayEvent: false,
    datesDisplayType: "calendar",
    allowTimeSlots: false,
    hideGuestSelector: false,
    // Rentals:
    showSingleCalendar: false,
    timeSlots: {
      isActive: false,
      timeSlotType: "hourly",
      customTimeSlots: [],
      timeSlotsStart: 0,
      timeSlotsEnd: 0,
    },
  },
};

export default function AddProduct() {
  const [productType, setProductType] = useState("");
  const [values, setValues] = useState(initialProductValues);
  const [prices, setPrices] = useState([
    {
      label: "",
      price: 0,
      guestType: "",
      minGuests: "----",
      maxGuests: "----",
    },
  ]);
  const [options, setOptions] = useState([
    {
      description: "",
      groupName: "",
      options: [{ name: "", additionalPrice: "" }],
    },
  ]);
  const [globalAvailability, setGlobalAvailability] = useState([
    {
      startDateTime: "",
      endDateTime: "",
      availabilityType: availabilityTypes.BlackedOut,
    },
  ]);
  const [seasonalOverrides, setSeasonalOverrides] = useState({
    pricing: [
      {
        startDateTime: "",
        endDateTime: "",
        data: [{ label: "", price: 0, guestType: "" }],
      },
    ],
    minNights: [
      {
        startDateTime: "",
        endDateTime: "",
        data: 1,
      },
    ],
  });
  const [inventory, setInventory] = useState([{ label: "", quantity: 1 }]);
  const [submitted, setIsSubmitted] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [eventAvailability, setEventAvailability] = useState({
    start: "",
    repeat: repeatTypes.Daily,
    interval: 1,
    weekDays: [],
    day: 1,
    month: 0,
    until: "",
    multiDayEventIntervals: [{ startDateTime: "", endDateTime: "" }],
  });
  const [rentalData, setRentalData] = useState([
    { rentalType: "", qty: 1, price: 0 },
  ]);
  const [imageUpload] = useState({});
  const [, setImg] = useState({});

  const { state } = useContext(AppContext);
  const { business } = state.user;

  const navigate = useNavigate();

  useEffect(() => {
    let addOnData = [];
    let amenityData = [];
    let guestData = [];

    Promise.all([
      ProductGroupAPI.getAllByBusiness(business),
      AmenityAPI.getAmenities(business),
      GuestAPI.list(business),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map((response) => {
            return response.data;
          })
        );
      })
      .then((data) => {
        data.forEach((objs) => {
          objs.products?.forEach((product) => {
            if (!!product.isActive) {
              addOnData.push({
                _id: product._id,
                title: product.title,
                selected: false,
              });
            }
          });
          objs.amenities?.forEach((amenity) => {
            amenityData.push({
              _id: amenity._id,
              name: amenity.name,
              selected: false,
            });
          });
          objs.guests?.forEach((guest) => {
            guestData.push({
              _id: guest._id,
              label: guest.label,
              guestType: guest.guestType,
              selected: false,
            });
          });
        });

        setValues({
          ...initialProductValues,
          addOns: [...addOnData],
          amenities: [...amenityData],
          guests: [...guestData],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [business]);

  const productImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "operator");

    let data;
    await ProductAPI.uploadImage(formData)
      .then((res) => {
        data = res.data.secure_url;
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  const handleImg = async (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
      setProductImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedAddOns = values.addOns.filter((item) => item.selected);
    const selectedAmenities = values.amenities.filter(
      (amenity) => amenity.selected
    );
    const selectedGuests = values.guests
      .filter((guest) => guest.selected)
      .map((i) => i._id);
    const updatedGlobalAvailability =
      globalAvailability.length > 0 &&
      globalAvailability[0].startDateTime.length > 0
        ? globalAvailability
        : [];
    const updatedSeasonalOverrides = {
      pricing:
        seasonalOverrides.pricing.length > 0 &&
        seasonalOverrides.pricing[0].startDateTime.length > 0
          ? seasonalOverrides.pricing
          : [],
      minNights:
        seasonalOverrides.minNights.length > 0 &&
        seasonalOverrides.minNights[0].startDateTime.length > 0
          ? seasonalOverrides.minNights
          : [],
    };
    const updatedInventory =
      inventory.length > 0 && inventory[0].quantity > 0 ? inventory : [];
    const updatedEventAvailability =
      (eventAvailability.start && eventAvailability.start.length > 0) ||
      !!eventAvailability.multiDayEventIntervals
        ? eventAvailability.multiDayEventIntervals.length > 0 &&
          eventAvailability.multiDayEventIntervals[0].startDateTime.length > 0
          ? eventAvailability
          : { ...eventAvailability, multiDayEventIntervals: [] }
        : undefined;
    const updatedOptions =
      options.length > 0 && options[0].groupName.length > 0 ? options : [];
    const updatedMaxNights =
      values.maxNights === "----" ? undefined : values.maxNights;
    const updatedPrices =
      productType !== productTypes.Rental
        ? prices.map((tier) => {
            const updatedTier = { ...tier };
            if (tier.maxGuests === "----") {
              updatedTier.maxGuests = undefined;
            }
            if (tier.minGuests === "----") {
              updatedTier.minGuests = undefined;
            }
            return updatedTier;
          })
        : [];
    const updatedDisplaySettings =
      productType === productTypes.Rental
        ? { ...values.displaySettings, hideGuestSelector: true }
        : values.displaySettings;
    const updatedRentalData =
      productType === productTypes.Rental ? rentalData : undefined;

    let urlCDN;
    if (productImage !== "") {
      imageUpload.image = productImage;
      urlCDN = await productImageUpload(productImage);
    }

    let data = {
      ...values,
      displaySettings: updatedDisplaySettings,
      maxNights: updatedMaxNights,
      pricing: updatedPrices,
      options: updatedOptions,
      addOns: [...selectedAddOns],
      amenities: [...selectedAmenities],
      guests: [...selectedGuests],
      globalAvailability: updatedGlobalAvailability,
      seasonalOverrides: updatedSeasonalOverrides,
      eventAvailability,
      updatedEventAvailability,
      inventory: updatedInventory,
      rentalData: updatedRentalData,
      urlCDN: urlCDN,
    };

    await ProductGroupAPI.addProductGroup(data, business, productType)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Product has been created!");
          setIsSubmitted(true);
          navigate("/products");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handleInputChange = (e, field) => {
    // tiny mce workaround
    if (!_.isUndefined(field)) {
      setValues({
        ...values,
        [field]: e,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Add a product
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 mb-10">
            <p className="mt-2 flex items-center text-sm text-gray-500">
              Please select the type of product you would like to add below.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3">
            <button onClick={() => setProductType(productTypes.Accommodation)}>
              <div className="bg-gray-900/5 p-6 sm:p-10">
                <span className="max-h-12 w-full object-contain text-lg font-bold">
                  Accommodation
                </span>
              </div>
            </button>

            <button onClick={() => setProductType(productTypes.Event)}>
              <div className="bg-gray-900/5 p-6 sm:p-10">
                <span className="max-h-12 w-full object-contain text-lg font-bold">
                  Event
                </span>
              </div>
            </button>

            <button onClick={() => setProductType(productTypes.Fee)}>
              <div className="bg-gray-900/5 p-6 sm:p-10">
                <span className="max-h-12 w-full object-contain text-lg font-bold">
                  Fee
                </span>
              </div>
            </button>

            <button onClick={() => setProductType(productTypes.Rental)}>
              <div className="bg-gray-900/5 p-8 sm:p-10">
                <span className="max-h-12 w-full object-contain text-lg font-bold">
                  Rental
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-8 sm:space-y-5 pb-4 bg-white">
        {productType === productTypes.Rental && (
          <RentalForm
            imageUpload={handleImg}
            image={imageUpload.image}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            submitted={submitted}
            values={values}
            defaultOptions={defaultOptions}
            setValues={setValues}
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            rentalData={rentalData}
            setRentalData={setRentalData}
            options={options}
            setOptions={setOptions}
          />
        )}

        {productType === productTypes.Accommodation && (
          <LodgeForm
            imageUpload={handleImg}
            image={imageUpload.image}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            submitted={submitted}
            values={values}
            prices={prices}
            showGuestType={true}
            setPrices={setPrices}
            setValues={setValues}
            defaultOptions={defaultOptions}
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            options={options}
            setOptions={setOptions}
          />
        )}

        {productType === productTypes.Event && (
          <ExperienceForm
            imageUpload={handleImg}
            image={imageUpload.image}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            submitted={submitted}
            values={values}
            prices={prices}
            setPrices={setPrices}
            options={options}
            setOptions={setOptions}
            setValues={setValues}
            showGuestType={true}
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            inventory={inventory}
            setInventory={setInventory}
            eventAvailability={eventAvailability}
            setEventAvailability={setEventAvailability}
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            defaultOptions={defaultOptions}
          />
        )}

        {productType === productTypes.Fee && (
          <FeeForm
            imageUpload={handleImg}
            image={imageUpload.image}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            submitted={submitted}
            values={values}
            prices={prices}
            setPrices={setPrices}
            setValues={setValues}
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            options={options}
            setOptions={setOptions}
          />
        )}
      </div>
    </>
  );
}

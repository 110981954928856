import React from "react";
import { seasonalOverrideFields } from "../AddProduct";
import DynamicPrice from "./DynamicPrice";
import SelectBlock from "./SelectBlock";

const currentYear = new Date().getFullYear();
const monthOptions = [
  { value: 0, text: "January" },
  { value: 1, text: "February" },
  { value: 2, text: "March" },
  { value: 3, text: "April" },
  { value: 4, text: "May" },
  { value: 5, text: "June" },
  { value: 6, text: "July" },
  { value: 7, text: "August" },
  { value: 8, text: "September" },
  { value: 9, text: "October" },
  { value: 10, text: "November" },
  { value: 11, text: "December" },
];

export default function SeasonalOverrides({
  seasonalOverrides,
  setSeasonalOverrides,
  title,
  description,
  hideMinNightsOverride = false,
  isFeeOrAccommodationProduct = false,
}) {
  const handleDateChange = (index, month, field, overrideField) => {
    let newOverride = {
      ...seasonalOverrides,
    };

    newOverride[overrideField][index][field] = new Date(
      currentYear,
      month
    ).toISOString();

    setSeasonalOverrides(newOverride);
  };

  const handleDataChange = (index, newData, overrideField) => {
    let newOverride = { ...seasonalOverrides };

    newOverride[overrideField][index].data = newData;
    setSeasonalOverrides(newOverride);
  };

  const addFormFields = (overrideField) => {
    const data =
      overrideField === seasonalOverrideFields.Pricing
        ? [{ label: "", price: 0, guestType: "" }]
        : 1;

    setSeasonalOverrides({
      ...seasonalOverrides,
      [overrideField]: [
        ...seasonalOverrides[overrideField],
        { startDateTime: "", endDateTime: "", data },
      ],
    });
  };

  const removeFormFields = (i, overrideField) => {
    let newOverride = { ...seasonalOverrides };
    newOverride[overrideField].splice(i, 1);
    setSeasonalOverrides(newOverride);
  };

  return (
    <div className="sm:border-t sm:border-gray-200 ">
      {title && description && (
        <div className="flex flex-col gap-1 sm:pt-2">
          <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
            {title}
          </p>
          <p className="text-gray-500 text-sm">{description}</p>
        </div>
      )}
      {/* Pricing section container */}
      <div className="bg-gray-50 px-2 rounded-md mt-4">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Override Pricing:
        </p>
        <p className="text-gray-400 text-xs">
          Note: ensure you add the same type of price tiers you have available
          regularly
        </p>
        {seasonalOverrides &&
          seasonalOverrides.pricing.map((element, index) => {
            const endDateTime =
              typeof element.endDateTime === "string" &&
              element.endDateTime.length > 0
                ? new Date(element.endDateTime).getMonth()
                : "";
            const startDateTime =
              typeof element.startDateTime === "string" &&
              element.startDateTime.length > 0
                ? new Date(element.startDateTime).getMonth()
                : "";
            return (
              <div key={index} className="border-gray-300 border-b-2 pb-5">
                <SelectBlock
                  field="pricingOverrideStartDateTime"
                  value={startDateTime}
                  label="Start date"
                  options={monthOptions}
                  onChange={(e) =>
                    handleDateChange(
                      index,
                      e.target.value,
                      "startDateTime",
                      seasonalOverrideFields.Pricing
                    )
                  }
                />
                <SelectBlock
                  field="pricingOverrideEndDateTime"
                  value={endDateTime}
                  label="End date"
                  options={monthOptions}
                  onChange={(e) =>
                    handleDateChange(
                      index,
                      e.target.value,
                      "endDateTime",
                      seasonalOverrideFields.Pricing,
                      true
                    )
                  }
                />
                <DynamicPrice
                  prices={element.data}
                  setPrices={(p) =>
                    handleDataChange(index, p, seasonalOverrideFields.Pricing)
                  }
                  maxLimit={10}
                  showTopBorder={false}
                  isFeeOrAccommodationProduct={isFeeOrAccommodationProduct}
                  showGuestType
                />
                <button
                  type="button"
                  className="block justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2"
                  onClick={() =>
                    removeFormFields(index, seasonalOverrideFields.Pricing)
                  }
                >
                  Remove this override
                </button>
              </div>
            );
          })}
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
          type="button"
          onClick={() => addFormFields(seasonalOverrideFields.Pricing)}
        >
          Add another override
        </button>
      </div>

      {/* Min nights section container */}
      {!hideMinNightsOverride && (
        <div className="bg-gray-50 px-2 rounded-md mt-4">
          <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
            Override Minimum Nights:
          </p>
          {seasonalOverrides &&
            seasonalOverrides.minNights.map((element, index) => {
              const endDateTime =
                typeof element.endDateTime === "string" &&
                element.endDateTime.length > 0
                  ? new Date(element.endDateTime).getMonth()
                  : "";
              const startDateTime =
                typeof element.startDateTime === "string" &&
                element.startDateTime.length > 0
                  ? new Date(element.startDateTime).getMonth()
                  : "";
              return (
                <div key={index} className="border-gray-300 border-b-2 pb-5">
                  <SelectBlock
                    field="startDateTime"
                    value={startDateTime}
                    label="Start date"
                    options={monthOptions}
                    onChange={(e) =>
                      handleDateChange(
                        index,
                        e.target.value,
                        "startDateTime",
                        seasonalOverrideFields.MinNights
                      )
                    }
                  />
                  <SelectBlock
                    field="endDateTime"
                    value={endDateTime}
                    label="End date"
                    options={monthOptions}
                    onChange={(e) =>
                      handleDateChange(
                        index,
                        e.target.value,
                        "endDateTime",
                        seasonalOverrideFields.MinNights,
                        true
                      )
                    }
                  />
                  <SelectBlock
                    value={element.data}
                    onChange={(e) =>
                      handleDataChange(
                        index,
                        e.target.value,
                        seasonalOverrideFields.MinNights
                      )
                    }
                    field="data"
                    label="Min. Nights"
                    options={[...new Array(10)]}
                    index={true}
                  />
                  <button
                    type="button"
                    className="block justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2"
                    onClick={() =>
                      removeFormFields(index, seasonalOverrideFields.MinNights)
                    }
                  >
                    Remove this override
                  </button>
                </div>
              );
            })}
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
            type="button"
            onClick={() => addFormFields(seasonalOverrideFields.MinNights)}
          >
            Add another override
          </button>
        </div>
      )}
    </div>
  );
}

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { BusinessAPI } from "../../../apis";

export default function CutOff({ cutOffRows, setCutOffRows, business }) {
  const handleSubmit = async (e) => {
    e.preventDefault();

    await BusinessAPI.updateBusiness(business, "cancellationPolicy", cutOffRows)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDateChange = (index, event, field) => {
    let newRow = [...cutOffRows];

    newRow[index][field] = event.target.value;
    setCutOffRows(newRow);
  };

  const addFormFields = () => {
    setCutOffRows([...cutOffRows, { cancellationDays: 0, refundAmount: 0 }]);
  };

  const removeFormFields = (i) => {
    let newRow = [...cutOffRows];
    newRow.splice(i, 1);
    setCutOffRows(newRow);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
        <div className="space-y-6 sm:space-y-5 p-10">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Cancellation Policy
          </h3>
          {cutOffRows.map((element, index) => (
            <div key={index}>
              <div className="flex">
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
                  Cancel within
                </span>
                <div className="relative w-30">
                  <input
                    type="number"
                    name="cancellationDays"
                    value={element.cancellationDays}
                    onChange={(event) =>
                      handleDateChange(index, event, "cancellationDays")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                </div>
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 ml-2 mr-2">
                  days of check-in and receive a
                </span>
                <div className="relative w-30">
                  <input
                    type="number"
                    max="100"
                    name="refundAmount"
                    value={element.refundAmount}
                    onChange={(event) =>
                      handleDateChange(index, event, "refundAmount")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                  <button
                    type="submit"
                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-percent h-5 w-5"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />{" "}
                    </svg>
                  </button>
                </div>
                {index ? (
                  <button
                    type="button"
                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </button>
                ) : null}
              </div>
            </div>
          ))}
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            type="button"
            onClick={() => addFormFields()}
          >
            Add another row
          </button>
        </div>
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </form>
    </>
  );
}

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const ProductGroup = {
  getAllByBusiness: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/productGroup/getProductGroupsByBusiness", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllActiveByBusiness: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get(
      "/api/productGroup/getActiveProductGroupsByBusiness",
      {
        params: { businessId: businessId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  addProductGroup: async (data, business, productType) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      ...data,
      businessId: business,
      productType,
      photoURL: data.urlCDN,
    };
    return await axiosInstance.post(
      "/api/productGroup/addProductGroup",
      bodyParameters,
      config
    );
  },

  getProductGroupById: (productId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/productGroup/productGroupById", {
      params: { productId: productId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updateProductGroup: (product) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.put("/api/productGroup/updateProductGroup", {
      params: { product: product },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

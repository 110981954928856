import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { BusinessAPI } from "../../../apis";
import { getPercentageValue } from "../../../utils/getPercentageValue";

export default function DepositSettings({
  depositInfo,
  setDepositInfo,
  business,
}) {
  const isPercentage = depositInfo?.rateType === "percentage";

  const handleRateChange = (e) => {
    const { name, value } = e.target;
    setDepositInfo((prev) => ({
      ...prev,
      [name]:
        prev.rateType === "percentage"
          ? getPercentageValue(value, true)
          : value * 100,
    }));
  };

  const handleRadioInputChange = (e) => {
    const { name, id } = e.target;
    setDepositInfo((prev) => ({
      ...prev,
      [name]: id,
      rate: id === "percentage" ? 10 / 100 : 10 * 100,
    }));
  };

  const handleChargeDaysChange = (e) => {
    const { name, value } = e.target;
    setDepositInfo({
      ...depositInfo,
      [name]: Number(value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedDepositInfo = {
      ...depositInfo,
      enabled: true,
    };

    await BusinessAPI.updateBusiness(business, "deposit", updatedDepositInfo)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business deposit settings has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDisable = async (e) => {
    e.preventDefault();

    const disabledDepositInfo = {
      rate: 0,
      rateType: "percentage",
      enabled: false,
      chargeDaysBefore: 3,
    };

    setDepositInfo(disabledDepositInfo);

    await BusinessAPI.updateBusiness(business, "deposit", disabledDepositInfo)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business deposit settings have been disabled!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const isChargeDayCheckIn = depositInfo.chargeDaysBefore === 0;

  return (
    <>
      <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
        <div className="space-y-6 sm:space-y-5 p-10">
          <div className="flex justify-between items-center">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Deposit Settings
              {!depositInfo.enabled && (
                <span className="text-xs text-red-300 pl-4">
                  currently disabled
                </span>
              )}
            </h3>
            {depositInfo.enabled && (
              <button
                onClick={handleDisable}
                className="text-sm py-2 px-3 rounded bg-transparent text-red-600 hover:bg-red-50 transition-colors"
              >
                Disable
              </button>
            )}
          </div>

          <div>
            <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Rate Type
            </p>
            <div className="flex flex-col">
              <label
                className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
                htmlFor="fee"
              >
                <input
                  type="radio"
                  name="rateType"
                  id="fee"
                  className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                  checked={
                    depositInfo.rateType === "fee" ? "checked" : undefined
                  }
                  onChange={handleRadioInputChange}
                />
                Fixed rate
              </label>
              <label
                className="text-sm text-gray-600 sm:mt-px sm:pt-2 mr-2 content-center flex"
                htmlFor="percentage"
              >
                <input
                  type="radio"
                  name="rateType"
                  id="percentage"
                  className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
                  checked={isPercentage ? "checked" : undefined}
                  onChange={handleRadioInputChange}
                />
                Percentage
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Rate Amount ({isPercentage ? "%" : "$"})
            </label>
            <div className="relative w-30">
              <input
                type="number"
                name="rate"
                max={isPercentage ? 100 : undefined}
                step={1}
                min={1}
                value={
                  isPercentage
                    ? getPercentageValue(depositInfo?.rate ?? 0)
                    : depositInfo.rate / 100
                }
                onChange={handleRateChange}
                className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Days before reservation begins to charge remaining balance
            </label>
            <div className="relative w-30">
              <input
                type="number"
                name="chargeDaysBefore"
                max={100}
                step={1}
                min={0}
                value={depositInfo?.chargeDaysBefore}
                onChange={handleChargeDaysChange}
                className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                required
              />
            </div>
          </div>

          {!isPercentage && (
            <div className="text-xs text-amber-800 p-3 bg-amber-100 rounded-lg">
              <p>
                When selecting a fixed rate deposit, take extra care is
                selecting the rate amount ($). If you select an amount that is
                greater than the smallest amount a customer can book,&nbsp;
                <strong>you may end up owing your customers money</strong> that
                must be refunded.
              </p>
              <p>
                This is why we recommend you choose a percentage based deposit.
              </p>
            </div>
          )}

          {(depositInfo.enabled || depositInfo.rate > 0) && (
            <div>
              <p className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
                Summary
              </p>
              <p className="block text-sm text-gray-900 sm:mt-px sm:pt-2 mr-2">
                A deposit of&nbsp;
                <span className="inline, text-sm text-violet-600">
                  {depositInfo.rateType === "percentage"
                    ? `${depositInfo.rate * 100}% of the customer's total`
                    : `$${(depositInfo.rate / 100).toFixed(2)}`}
                </span>
                &nbsp;will be charged on booking. The remaining balance will be
                charged&nbsp;
                <span className="inline, text-sm text-violet-600">
                  {isChargeDayCheckIn
                    ? `on the day the reservation begins.`
                    : `${depositInfo.chargeDaysBefore} days before the
              reservation begins.`}
                </span>
              </p>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </form>
    </>
  );
}

import { useRef, useState, useContext, useEffect } from "react";
import { BusinessAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const BusinessDescription = () => {
  const [description, setDescription] = useState("");

  const editorRef = useRef(null);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  useEffect(() => {
    BusinessAPI.getById(business)
      .then((res) => {
        setDescription(res?.data?.business?.description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business, description]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editorRef.current) {
      BusinessAPI.updateBusiness(
        business,
        "description",
        editorRef.current.getContent()
      )
        .then((res) => {
          toast.success("Business description updated!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Description of the business
        </h3>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <Editor
              apiKey={"3u557s7493kor583in0tern82p6wzpw7leqapaxo3wvhcc0v"}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={description}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "image",
                  "code",
                  "table",
                  "link",
                  "media",
                  "codesample",
                  "lists",
                ],
                toolbar:
                  "undo redo | blocks fontsize | bold italic underline | link mergetags  | checklist numlist bullist indent outdent",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
              }}
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};
export default BusinessDescription;

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Guest = {
  create: (business, guests) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      business: business,
      guests: guests,
    };

    return axiosInstance.post("/api/guest/create", bodyParameters, config);
  },
  list: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/guest/list", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  remove: (businessId, guestId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      guestId: guestId,
    };

    return axiosInstance.put("/api/guest/delete", bodyParameters, config);
  },

  update: (guest) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      guest,
    };

    return axiosInstance.put("/api/guest/update", bodyParameters, config);
  }
};

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { BusinessAPI } from "../../../apis";

export default function BusinessHours({
  businessHours,
  setBusinessHours,
  business,
}) {
  const handleSubmit = async (e) => {
    e.preventDefault();

    await BusinessAPI.updateBusiness(business, "businessHours", businessHours)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business hours updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDateChange = (index, event, field) => {
    let newRow = [...businessHours];

    newRow[index][field] = event.target.value;
    setBusinessHours(newRow);
  };

  const addFormFields = () => {
    setBusinessHours([
      ...businessHours,
      { dayOfWeek: "Sunday", openingTime: "", closingTime: "" },
    ]);
  };

  const removeFormFields = (i) => {
    let newRow = [...businessHours];
    newRow.splice(i, 1);
    setBusinessHours(newRow);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
        <div className="space-y-6 sm:space-y-5 p-10">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Business operating hours
          </h3>
          {businessHours.map((element, index) => (
            <div key={index}>
              <div className="flex">
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
                  Day of the week
                </span>
                <div className="relative w-30">
                  <input
                    type="text"
                    name="dayOfWeek"
                    value={element.dayOfWeek}
                    onChange={(event) =>
                      handleDateChange(index, event, "dayOfWeek")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                </div>
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 ml-2 mr-2">
                  Opening time
                </span>
                <div className="relative w-30">
                  <input
                    type="text"
                    name="openingTime"
                    value={element.openingTime}
                    onChange={(event) =>
                      handleDateChange(index, event, "openingTime")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                </div>
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 ml-2 mr-2">
                  Until
                </span>
                <div className="relative w-30">
                  <input
                    type="text"
                    name="closingTime"
                    value={element.closingTime}
                    onChange={(event) =>
                      handleDateChange(index, event, "closingTime")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                </div>
                {index ? (
                  <button
                    type="button"
                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </button>
                ) : null}
              </div>
            </div>
          ))}
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            type="button"
            onClick={() => addFormFields()}
          >
            Add another row
          </button>
        </div>
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </form>
    </>
  );
}

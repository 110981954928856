import React, { useState, useCallback, useEffect, useContext } from "react";
import { BusinessAPI, CalendarAPI, ProductGroupAPI } from "../../apis";
import { AppContext } from "../State/AppContext";
import { toast } from "react-toastify";
import Spinner from "../Spinner";

export const IntegrateExternalCalendarForm = () => {
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState("");
  const [calendarLinks, setCalendarLinks] = useState([
    { link: "", productGroupId: "" },
  ]);
  const [products, setProducts] = useState([]);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  const handleIntegrateCalendarSubmit = (e) => {
    e.preventDefault();
    setIsSyncLoading(true);
    if (
      businessInfo &&
      businessInfo.calendarIntegrationLinks &&
      businessInfo.calendarIntegrationLinks.length > 0 &&
      (calendarLinks.length < 1 ||
        calendarLinks.every((v) => v.link.length < 1))
    ) {
      // Remove link from businessInfo to stop auto-syncing
      const updatedBusinessInfo = {
        ...businessInfo,
        calendarIntegrationLinks: [],
      };
      BusinessAPI.updateBusiness(business, "businessInfo", updatedBusinessInfo)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Calendar links have been removed");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        })
        .finally(() => {
          setIsSyncLoading(false);
          window.location.reload();
        });
    } else if (
      calendarLinks &&
      calendarLinks.length > 0 &&
      calendarLinks.every(
        (v) => v.link.length > 0 && v.productGroupId.length > 0
      )
    ) {
      // Sync the calendar integration links if they are valid
      CalendarAPI.integrate(business, calendarLinks)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
          }
        })
        .catch((err) => toast.error(err.response.data.message))
        .finally(() => setIsSyncLoading(false));
    } else {
      toast.error("Could not sync - Please enter a valid link and/or product");
      setIsSyncLoading(false);
    }
  };

  const getExistingCalendarIntegrationLink = useCallback(() => {
    BusinessAPI.getById(business).then((res) => {
      if (res.status === 200) {
        const calendarIntegrationLinks =
          res.data.business.businessInfo.calendarIntegrationLinks;
        setBusinessInfo(res.data.business.businessInfo);

        if (calendarIntegrationLinks && calendarIntegrationLinks.length > 0) {
          setCalendarLinks(calendarIntegrationLinks);
        }
      }
    });
  }, [business]);

  useEffect(() => {
    getExistingCalendarIntegrationLink();
  }, [getExistingCalendarIntegrationLink]);

  useEffect(() => {
    const getAccommodationProducts = () => {
      ProductGroupAPI.getAllActiveByBusiness(business).then((res) => {
        if (res.status === 200) {
          setProducts(
            res.data.products.filter((p) => p.productType === "accommodation")
          );
        }
      });
    };
    getAccommodationProducts();
  }, [business]);

  const handleCalendarLinkChange = (value, index, type) => {
    const newCalendarLinks = [...calendarLinks];
    newCalendarLinks[index] = {
      ...newCalendarLinks[index],
      [type]: value,
    };

    setCalendarLinks(newCalendarLinks);
  };

  const handleAddNewCalendarLink = () => {
    setCalendarLinks((prev) => [...prev, { link: "", productGroupId: "" }]);
  };

  const handleRemoveCalendarLink = (i) => {
    const newCalendarLinks = [...calendarLinks];
    newCalendarLinks.splice(i, 1);
    setCalendarLinks(newCalendarLinks);
  };

  return (
    <form onSubmit={handleIntegrateCalendarSubmit}>
      <div className="flex flex-col">
        {calendarLinks.map((linkObj, i) => (
          <div className="grid grid-cols-8 gap-6 py-4" key={i}>
            <input
              type="text"
              name={`link-${i}`}
              value={linkObj.link}
              onChange={(e) =>
                handleCalendarLinkChange(e.target.value, i, "link")
              }
              className="block w-full max-w-xl rounded py-3 px-4 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed col-span-3"
            />
            <select
              id={`product-${i}`}
              name={`product-${i}`}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm col-span-3"
              placeholder="Select product"
              value={linkObj.productGroupId}
              onChange={(e) =>
                handleCalendarLinkChange(e.target.value, i, "productGroupId")
              }
            >
              <option key="----" value="">
                --- Please Select ---
              </option>
              {products &&
                products.map((product) => (
                  <option key={product._id} value={product._id}>
                    {product.title}
                  </option>
                ))}
            </select>
            {i !== 0 && (
              <button
                className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 w-20 disabled:opacity-50"
                type="button"
                onClick={() => handleRemoveCalendarLink(i)}
              >
                Remove
              </button>
            )}
            {i === calendarLinks.length - 1 && (
              <button
                className="inline-flex items-center justify-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 w-20 disabled:opacity-50"
                type="button"
                onClick={handleAddNewCalendarLink}
              >
                Add link
              </button>
            )}
          </div>
        ))}
        <button
          className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 w-20 disabled:opacity-50"
          type="submit"
          disabled={isSyncLoading}
        >
          {isSyncLoading ? <Spinner color="white" /> : "Sync"}
        </button>
      </div>
    </form>
  );
};

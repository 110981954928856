import _ from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ProductAPI, ProductGroupAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import RentalFormEdit from "./RentalFormEdit";
import LodgeFormEdit from "./LodgeFormEdit";
import ExperienceEditForm from "./ExperienceEditForm";
import FeeEditForm from "./FeeEditForm";
import { ProductGroup } from "../../../apis/ProductGroup";
import { AmenityAPI, GuestAPI } from "../../../apis";
import {
  productTypes,
  initialProductValues,
  availabilityTypes,
} from "./AddProduct";
import { repeatTypes } from "./Fields/constants";

const defaultOptions = [...new Array(20)];

export default function ProductEdit() {
  const { productId } = useParams();
  const { state } = useContext(AppContext);
  const { business } = state.user;

  const [productImage, setProductImage] = useState("");
  const [imageUpload] = useState({});
  const [, setImg] = useState({});
  const [prices, setPrices] = useState([
    {
      label: "",
      price: 0,
      guestType: "",
      minGuests: "----",
      maxGuests: "----",
    },
  ]);
  const [globalAvailability, setGlobalAvailability] = useState([
    {
      startDateTime: "",
      endDateTime: "",
      availabilityType: availabilityTypes.BlackedOut,
    },
  ]);
  const [seasonalOverrides, setSeasonalOverrides] = useState({
    pricing: [
      {
        startDateTime: "",
        endDateTime: "",
        data: [{ label: "", price: 0, guestType: "" }],
      },
    ],
    minNights: [
      {
        startDateTime: "",
        endDateTime: "",
        data: 1,
      },
    ],
  });
  const [inventory, setInventory] = useState([]);
  const [options, setOptions] = useState([
    {
      description: "",
      groupName: "",
      options: [{ name: "", additionalPrice: "" }],
    },
  ]);
  const [submitted, setIsSubmitted] = useState(false);
  const [values, setValues] = useState(initialProductValues);
  const [eventAvailability, setEventAvailability] = useState({
    start: "",
    repeat: repeatTypes.Daily,
    interval: 1,
    weekDays: [],
    day: 1,
    month: 0,
    until: "",
  });
  const [rentalData, setRentalData] = useState([
    { rentalType: "", qty: 1, price: 0 },
  ]);

  const navigate = useNavigate();

  const productType = values.productType;

  useEffect(() => {
    let addOnData = [];
    let amenityData = [];
    let guestData = [];
    let productData = {};

    Promise.all([
      ProductGroup.getProductGroupById(productId),
      ProductGroupAPI.getAllByBusiness(business),
      AmenityAPI.getAmenities(business),
      GuestAPI.list(business),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map((response) => {
            return response.data;
          })
        );
      })
      .then((data) => {
        data.forEach((objs) => {
          const product = objs.product;
          if (product) {
            productData = {
              ...product,
              image: product.photoURL ?? undefined,
            };
            setPrices(product.pricing);
            if (
              product.globalAvailability &&
              product.globalAvailability.length > 0
            ) {
              setGlobalAvailability(product.globalAvailability);
            }
            if (
              product.seasonalOverrides &&
              (product.seasonalOverrides.pricing.length > 0 ||
                product.seasonalOverrides.minNights.length > 0)
            ) {
              setSeasonalOverrides(product.seasonalOverrides);
            }
            if (product.options && product.options.length > 0) {
              setOptions(product.options);
            }
            if (!!product.eventAvailability) {
              setEventAvailability(product.eventAvailability);
            }
            if (product.productType === productTypes.Rental) {
              const updatedRentalData = Object.values(
                product.products.reduce((acc, p) => {
                  const rentalType = p.rentalData?.rentalType;
                  if (rentalType) {
                    acc[rentalType] = acc[rentalType] || {
                      qty: 0,
                      rentalType,
                      price: p.rentalData.price,
                    };

                    acc[rentalType].qty++;
                  }

                  return acc;
                }, {})
              );
              setRentalData(updatedRentalData);
            }
          }

          objs.products?.forEach((p) => {
            if (productId !== p._id && !!p.isActive) {
              addOnData.push({
                _id: p._id,
                title: p.title,
                selected: !!productData.addOns.find((a) => a._id === p._id),
              });
            }
          });
          objs.amenities?.forEach((amenity) => {
            amenityData.push({
              _id: amenity._id,
              name: amenity.name,
              selected: !!productData.amenities.find(
                (a) => a._id === amenity._id
              ),
            });
          });
          objs.guests?.forEach((guest) => {
            guestData.push({
              _id: guest._id,
              label: guest.label,
              guestType: guest.guestType,
              selected: !!productData.guests.find(
                (guestId) => guestId === guest._id
              ),
            });
          });
        });

        setValues({
          ...initialProductValues,
          ...productData,
          addOns: [...addOnData],
          amenities: [...amenityData],
          guests: [...guestData],
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [business, inventory, productId, productType]);

  useEffect(() => {
    if (values.products && values.products.length > 0) {
      values.products.forEach((p) => {
        const productInvLabel =
          p && p.inventory && p.inventory.label ? p.inventory.label : "";
        const doesInvExist = inventory.some(
          (inv) => inv?.label === productInvLabel
        );

        if (!doesInvExist && productInvLabel.length > 0) {
          setInventory((prev) => [...prev, p.inventory]);
        }
      });
    }
  }, [values.products, inventory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedAddOns = values.addOns
      .filter((item) => item.selected)
      .map((i) => i._id);
    const selectedAmenities = values.amenities
      .filter((amenity) => amenity.selected)
      .map((i) => i._id);
    const selectedGuests = values.guests
      .filter((guest) => guest.selected)
      .map((i) => i._id);
    const updatedGlobalAvailability =
      globalAvailability.length > 0 &&
      globalAvailability[0].startDateTime.length > 0
        ? globalAvailability
        : [];
    const updatedSeasonalOverrides = {
      pricing:
        seasonalOverrides.pricing.length > 0 &&
        seasonalOverrides.pricing[0].startDateTime.length > 0
          ? seasonalOverrides.pricing
          : [],
      minNights:
        seasonalOverrides.minNights.length > 0 &&
        seasonalOverrides.minNights[0].startDateTime.length > 0
          ? seasonalOverrides.minNights
          : [],
    };
    const updatedEventAvailability =
      (eventAvailability.start && eventAvailability.start.length > 0) ||
      !!eventAvailability.multiDayEventIntervals
        ? eventAvailability.multiDayEventIntervals.length > 0 &&
          eventAvailability.multiDayEventIntervals[0].startDateTime &&
          eventAvailability.multiDayEventIntervals[0].startDateTime.length > 0
          ? eventAvailability
          : { ...eventAvailability, multiDayEventIntervals: [] }
        : undefined;
    const updatedOptions =
      options.length > 0 && options[0].groupName.length > 0 ? options : [];
    const updatedMaxNights =
      values.maxNights === "----" ? undefined : values.maxNights;
    const updatedPrices =
      productType !== productTypes.Rental
        ? prices.map((tier) => {
            const updatedTier = { ...tier };
            if (tier.maxGuests === "----") {
              updatedTier.maxGuests = undefined;
            }
            if (tier.minGuests === "----") {
              updatedTier.minGuests = undefined;
            }
            return updatedTier;
          })
        : [];
    const updatedDisplaySettings =
      productType === productTypes.Rental
        ? { ...values.displaySettings, hideGuestSelector: true }
        : values.displaySettings;
    const updatedRentalData =
      productType === productTypes.Rental ? rentalData : undefined;

    let urlCDN;
    if (productImage !== "") {
      imageUpload.image = productImage;
      urlCDN = await profileUpload(productImage);
    }

    let data = {
      ...values,
      displaySettings: updatedDisplaySettings,
      maxNights: updatedMaxNights,
      pricing: updatedPrices,
      options: updatedOptions,
      amenities: [...selectedAmenities],
      addOns: [...selectedAddOns],
      guests: [...selectedGuests],
      globalAvailability: updatedGlobalAvailability,
      seasonalOverrides: updatedSeasonalOverrides,
      eventAvailability,
      updatedEventAvailability,
      productType,
      rentalData: updatedRentalData,
      photoURL: urlCDN,
    };

    await ProductGroupAPI.updateProductGroup(data)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Product has been updated!");
          setIsSubmitted(true);
          navigate("/products");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message ?? err.message);
      });
  };

  const handleInputChange = (e, field) => {
    // tiny mce workaround

    if (!_.isUndefined(field)) {
      setValues({
        ...values,
        [field]: e,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const profileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "operator");

    let data;
    await ProductAPI.uploadImage(formData)
      .then((res) => {
        data = res.data.secure_url;
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  const handleImg = async (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
      setProductImage(e.target.files[0]);
    }
  };

  return (
    <>
      {productType === productTypes.Rental && (
        <RentalFormEdit
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          submitted={submitted}
          values={values}
          setValues={setValues}
          imageUpload={handleImg}
          globalAvailability={globalAvailability}
          setGlobalAvailability={setGlobalAvailability}
          options={options}
          setOptions={setOptions}
          rentalData={rentalData}
          setRentalData={setRentalData}
          defaultOptions={defaultOptions}
        />
      )}

      {productType === productTypes.Accommodation && (
        <LodgeFormEdit
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          submitted={submitted}
          values={values}
          prices={prices}
          setPrices={setPrices}
          defaultOptions={defaultOptions}
          setValues={setValues}
          imageUpload={handleImg}
          globalAvailability={globalAvailability}
          setGlobalAvailability={setGlobalAvailability}
          seasonalOverrides={seasonalOverrides}
          setSeasonalOverrides={setSeasonalOverrides}
          options={options}
          setOptions={setOptions}
        />
      )}

      {productType === productTypes.Event && (
        <ExperienceEditForm
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          submitted={submitted}
          values={values}
          prices={prices}
          setPrices={setPrices}
          options={options}
          setOptions={setOptions}
          setValues={setValues}
          imageUpload={handleImg}
          showGuestType={true}
          seasonalOverrides={seasonalOverrides}
          setSeasonalOverrides={setSeasonalOverrides}
          inventory={inventory}
          setInventory={setInventory}
          eventAvailability={eventAvailability}
          setEventAvailability={setEventAvailability}
          globalAvailability={globalAvailability}
          setGlobalAvailability={setGlobalAvailability}
          defaultOptions={defaultOptions}
        />
      )}

      {productType === productTypes.Fee && (
        <FeeEditForm
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          submitted={submitted}
          values={values}
          prices={prices}
          setPrices={setPrices}
          setValues={setValues}
          imageUpload={handleImg}
          seasonalOverrides={seasonalOverrides}
          setSeasonalOverrides={setSeasonalOverrides}
          options={options}
          setOptions={setOptions}
        />
      )}
    </>
  );
}

import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { generatePromoCode } from "../../../utils/generatePromoCode";
import { getPercentageValue } from "../../../utils/getPercentageValue";
import { PromoCodeAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";

const CreatePromoCode = () => {
  const { state } = useContext(AppContext);
  const { business } = state.user;

  const navigate = useNavigate();

  const [promoCode, setPromoCode] = useState({
    code: generatePromoCode(8),
    value: 0.15,
    valueType: "percentage",
    validUntil: moment(new Date()).add(1, "month").toDate(),
    usageLimit: 1,
  });
  const [errors, setErrors] = useState({
    code: undefined,
    value: undefined,
    valueType: undefined,
    validUntil: undefined,
    usageLimit: undefined,
  });

  const isValueTypePercentage = promoCode.valueType === "percentage";

  const handleChange = (e, { isPromoCode, isUsageLimit }) => {
    const { name, value } = e.target;

    const formattedValue = isPromoCode
      ? value.toUpperCase()
      : isUsageLimit
      ? Number(value)
      : value;

    setPromoCode((prev) => ({ ...prev, [name]: formattedValue }));

    if (isPromoCode) {
      if (/^[a-zA-Z0-9]*$/.test(value)) {
        if (value.length > 5) {
          setErrors((prev) => ({
            ...prev,
            code: undefined,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            code: "Please enter a minimum of 6 characters",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          code: "Please enter only numbers and/or letters, with no spaces.",
        }));
      }
    }
    if (isUsageLimit) {
      if (Number(value) === 0) {
        setErrors((prev) => ({
          ...prev,
          usageLimit: "Please enter a number higher than 0",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          usageLimit: undefined,
        }));
      }
    }
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setPromoCode((prev) => ({
      ...prev,
      [name]:
        prev.valueType === "percentage"
          ? Number(value) / 100
          : Number(value) * 100,
    }));
    if (Number(value) === 0) {
      setErrors((prev) => ({
        ...prev,
        value: "Please enter a number higher than 0",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        value: undefined,
      }));
    }
  };

  const handleDateChange = (date) => {
    setPromoCode((prev) => ({ ...prev, validUntil: date }));
  };
  const handleSelectInfiniteUsage = (e) => {
    if (e.target.checked) {
      setPromoCode((prev) => ({
        ...prev,
        usageLimit: "infinite",
      }));
    } else {
      setPromoCode((prev) => ({
        ...prev,
        usageLimit: 1,
      }));
    }
  };
  const isNoErrors = Object.values(errors).every(
    (value) => value === undefined
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isNoErrors) {
      const { code, usageLimit, validUntil, value, valueType } = promoCode;
      const promoCodeObj = {
        promoCode: code,
        promoCodeValue: value,
        usageLimit,
        validUntil,
        valueType,
      };
      await PromoCodeAPI.createPromoCode(business, promoCodeObj)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Promo code has been successfully added!");
            navigate("/business/promo-codes");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <div>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1 flex justify-between flex-col md:flex-row mt-6">
          <div>
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Create Promo Code
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 mb-10">
              <p className="mt-2 flex items-center text-sm text-gray-500">
                The promo code you create here can be given to your customer(s)
                to receive a discount.
              </p>
            </div>
          </div>
          <Link
            to="/business/promo-codes"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 h-min w-min"
          >
            Back
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 pb-5">
          <div>
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Promo Code
            </label>
            <p className="text-xs text-gray-500">
              This is the discount code you'll give to your customer(s)
            </p>
          </div>
          <div className="mt-1 sm:col-span-2 sm:mt-0 ">
            <div className="flex gap-2">
              <input
                type="text"
                name="code"
                required
                value={promoCode.code}
                onChange={(e) => handleChange(e, { isPromoCode: true })}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
              <button
                type="button"
                className="bg-gray-800 text-white active:bg-gray-600 font-bold text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 h-min w-max"
                onClick={() => {
                  const newPromoCode = generatePromoCode(8);
                  setPromoCode((prev) => ({
                    ...prev,
                    code: newPromoCode,
                  }));
                }}
              >
                Generate Code
              </button>
            </div>
            {errors.code && (
              <p className="text-xs text-red-700">{errors.code}</p>
            )}
          </div>

          <label
            htmlFor="valueType"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Value Type
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              name="valueType"
              value={promoCode.valueType}
              onChange={(e) => handleChange(e, {})}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option value="fee">Fixed Rate ($)</option>
              <option value="percentage">Percentage (%)</option>
            </select>
            {errors.valueType && (
              <p className="text-xs text-red-700">{errors.valueType}</p>
            )}
          </div>

          <label
            htmlFor="value"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Amount ({isValueTypePercentage ? "%" : "$"})
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="number"
              name="value"
              max={isValueTypePercentage ? 100 : undefined}
              step={1}
              min={0}
              value={
                isValueTypePercentage
                  ? (promoCode.value * 100).toFixed(0)
                  : promoCode.value / 100
              }
              onChange={handleValueChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
            />
            {errors.value && (
              <p className="text-xs text-red-700">{errors.value}</p>
            )}
          </div>

          <label
            htmlFor="validUntil"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Expiration Date
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <DatePicker
              name="validUntil"
              dateFormat="MMMM d, yyyy"
              value={promoCode.validUntil.toISOString()}
              onChange={handleDateChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {errors.validUntil && (
              <p className="text-xs text-red-700">{errors.validUntil}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="usageLimit"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Usage Limit
            </label>
            <p className="text-xs text-gray-500">
              This determines how many times the promo code value can be reused
            </p>
          </div>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <div className="flex gap-2">
              <input
                type="number"
                name="usageLimit"
                min={0}
                value={promoCode.usageLimit}
                onChange={(e) => handleChange(e, { isUsageLimit: true })}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
              />
              <label className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="usageLimit"
                  checked={promoCode.usageLimit === "infinite"}
                  onChange={handleSelectInfiniteUsage}
                  className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                Infinite usage
              </label>
            </div>
            {errors.usageLimit && (
              <p className="text-xs text-red-700">{errors.usageLimit}</p>
            )}
          </div>

          <p
            htmlFor="usageLimit"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Summary
          </p>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <p className="text-sm font-medium text-gray-700">
              The promo code:&nbsp;
              <span className="text-purple-600">{promoCode.code}</span> will
              apply a&nbsp;
              <span className="text-purple-600">
                {isValueTypePercentage
                  ? `${getPercentageValue(promoCode.value)}%`
                  : `$${promoCode.value / 100}`}
              </span>
              &nbsp;discount to your customer's order when used. This code at
              this value can only be used&nbsp;
              <span className="text-purple-600">
                {promoCode.usageLimit}&nbsp;time
                {(typeof promoCode.usageLimit === "number" &&
                  (promoCode.usageLimit > 1 || promoCode.usageLimit < 1)) ||
                typeof promoCode.usageLimit === "string"
                  ? "s"
                  : ""}
              </span>
              , and it will expire on&nbsp;
              <span className="text-purple-600">
                {moment(promoCode.validUntil).format("MMMM Do YYYY")}
              </span>
              .
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-8 pr-8 mt-10">
          <button
            type="submit"
            disabled={!isNoErrors}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePromoCode;

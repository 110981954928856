import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Organization = {
  getAll: () => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/organization/organizations", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getById: (id) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/organization/organizationDetail", {
      params: { id: id },
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  addOrganization: async (organizationName) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      name: organizationName,
    };

    return await axiosInstance.post(
      "/api/organization/addOrganization",
      bodyParameters,
      config
    );
  },
};

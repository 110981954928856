import React, { useState, useCallback, useEffect } from 'react';
import { BusinessAPI, OrganizationAPI } from '../../../apis';
import _ from 'lodash';

const businessTypes = ['accommodation', 'experience', 'fee'];

const BusinessForm = ({ setShowModal, setMsg }) => {
  const [name, setName] = useState('');
  const [organizations, setOrganizations] = useState(false);
  const [organization, setOrganization] = useState('');
  const [businessType, setBusinessType] = useState('');

  const loadOrganizations = useCallback(async () => {
    OrganizationAPI.getAll()
      .then((res) => {
        setOrganizations(
          res.data.organizations.map((organization) => ({
            label: organization.name,
            value: organization._id,
          }))
        );
        setOrganization(res.data.organizations[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadOrganizations();
  }, [loadOrganizations]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await BusinessAPI.addBusiness(name, organization, businessType)
      .then((res) => {
        if (res.status === 200) {
          setMsg('Business as been successfully created!');
          setShowModal(false);
        }
      })
      .catch((err) => {
        setMsg(err.response.data.message);
        setShowModal(false);
      });
  };

  return (
    <>
      <form
        className='space-y-8 divide-y divide-gray-200'
        onSubmit={handleSubmit}
      >
        <div className='space-y-6 sm:space-y-5'>
          <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
            <label
              htmlFor='first-name'
              className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
            >
              Organizations
            </label>

            <div className='mt-1 sm:col-span-2 sm:mt-0'>
              <select
                id='organization'
                name='organization'
                autoComplete='organization-name'
                className='block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                value={organization}
                onChange={(event) => setOrganization(event.target.value)}
              >
                {organizations &&
                  organizations.map((organization) => (
                    <option key={organization.label} value={organization.value}>
                      {organization.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
          <div className='space-y-6 sm:space-y-5'>
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5'>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Business name
              </label>
              <div className='mt-1 sm:col-span-2 sm:mt-0'>
                <input
                  type='text'
                  name='name'
                  id='name'
                  autoComplete='given-name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
          <label
            htmlFor='first-name'
            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
          >
            Business Type
          </label>

          <div className='mt-1 sm:col-span-2 sm:mt-0'>
            <select
              id='organization'
              name='organization'
              autoComplete='organization-name'
              className='block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
              value={businessType}
              onChange={(event) => setBusinessType(event.target.value)}
            >
              <option>--- Please select ---</option>
              {businessTypes.map((type) => (
                <option key={type} value={type}>
                  {_.startCase(_.toLower(type))}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='pt-5'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default BusinessForm;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessAPI } from "../../apis";
import AddUserToBusinessModal from "../Modal/AddUserToBusinessModal";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import Steps from "../Steps";
import UpdateBusinessStatusModal from "../Modal/UpdateBusinessStatusModal";

export default function BusinessDetail() {
  const { businessId } = useParams();
  const [business, setBusiness] = useState();
  const [users, setUsers] = useState([]);
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    BusinessAPI.getById(businessId)
      .then((res) => {
        setBusiness(res.data.business);
        setUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  return (
    business && (
      <>
        <Steps businessId={business._id} />
        {msg && (
          <div
            className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p className="text-sm">{msg}</p>
          </div>
        )}
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <button
              className="mt-4 mb-4 bg-blue-800 text-white active:bg-gray-600 font-bold text-xs px-1 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 pl-12 pr-12"
              type="button"
              onClick={() => setShowStatusModal(true)}
            >
              Update status
            </button>

            {showStatusModal ? (
              <UpdateBusinessStatusModal
                setShowModal={setShowStatusModal}
                setMsg={setMsg}
                businessId={business._id}
              />
            ) : null}
            <h1 className="text-2xl font-semibold text-gray-900">
              {business.name}
            </h1>

            <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
              {business.isActive ? "Active" : "Inactive"}
            </span>

            {business.businessURL ? (
              <a href={business.businessURL} target="_blank" rel="noreferrer">
                <span className="inline-block flex-shrink-0  px-2 py-0.5 text-xs font-medium text-blue-800">
                  {business.businessURL}
                </span>
              </a>
            ) : null}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
              type="button"
              onClick={() => setShowModal(true)}
            >
              Add User
            </button>
            {showModal ? (
              <AddUserToBusinessModal
                setShowModal={setShowModal}
                setMsg={setMsg}
                business={business}
              />
            ) : null}
          </div>
        </div>

        <div className="border-t mt-10 ">
          <h2 className="text-lg font-semibold text-gray-900 mt-10">
            Users for {business.name}
            <span className="text-gray-500 text-sm">({users.length})</span>
          </h2>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 pt-10">
            {users.map((user) => (
              <li
                key={user.username}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {user.firstName} {user.lastName}
                      </h3>
                    </div>
                    <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                      {user.isActive ? "Active" : "Inactive"}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={`mailto:${user.username}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <EnvelopeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Email
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    )
  );
}

import React from "react";
import AddUserToBusinessForm from "./Forms/AddUserToBusinessForm";

const AddUserToBusinessModal = ({ setShowModal, setMsg, business }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-6xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">
                Add user to {business.name}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <AddUserToBusinessForm
                setShowModal={setShowModal}
                setMsg={setMsg}
                business={business}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserToBusinessModal;

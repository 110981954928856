import React from "react";
import BooleanSelectBlock from "./BooleanSelectBlock";
import SelectBlock from "./SelectBlock";
import { productTypes, initialProductValues } from "../AddProduct";
import { DynamicCustomTimeSlots } from "./DynamicCustomTimeSlots";
import { timeOptions } from "./constants";

export const DisplaySettings = ({ values, setValues, productType }) => {
  const handleFieldChange = (value, field) => {
    if (field === "isMultiDayEvent") {
      setValues((prev) => ({
        ...prev,
        displaySettings: {
          ...prev.displaySettings,
          [field]: value,
          datesDisplayType: "date_cards",
        },
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        displaySettings: { ...prev.displaySettings, [field]: value },
      }));
    }
  };

  const handleTimeSlotsChange = (value, field) => {
    if (field === "isActive" && value === false) {
      setValues((prev) => ({
        ...prev,
        displaySettings: {
          ...prev.displaySettings,
          timeSlots: {
            ...prev.displaySettings.timeSlots,
            ...initialProductValues.displaySettings.timeSlots,
            [field]: value,
          },
        },
      }));
    } else if (field === "timeSlotType" && value === "custom") {
      setValues((prev) => ({
        ...prev,
        displaySettings: {
          ...prev.displaySettings,
          timeSlots: {
            ...prev.displaySettings.timeSlots,
            [field]: value,
            customTimeSlots: [{ hour: 1, minute: 0 }],
          },
        },
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        displaySettings: {
          ...prev.displaySettings,
          timeSlots: {
            ...prev.displaySettings.timeSlots,
            [field]: value,
          },
        },
      }));
    }
  };
  return (
    <div className="sm:border-t sm:border-gray-200 ">
      <div className="flex flex-col gap-1 sm:pt-2">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Display Settings Section
        </p>
        <p className="text-gray-500 text-sm">
          In this section you can select the specific settings on how this
          product will display in booker
        </p>
      </div>
      {productType === productTypes.Event && (
        <>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <BooleanSelectBlock
              label="Does this event span multiple dates?"
              name="isMultiDayEvent"
              value={values.displaySettings.isMultiDayEvent}
              onChange={(v) => handleFieldChange(v, "isMultiDayEvent")}
            />
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <BooleanSelectBlock
              label="Allow time slots"
              helperText="Do you want to allow your customers to select time slots?"
              name="allowTimeSlots"
              value={values.displaySettings.allowTimeSlots}
              onChange={(v) => handleFieldChange(v, "allowTimeSlots")}
            />
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <BooleanSelectBlock
              label="Hide the guest selector?"
              helperText="Choosing this will auto set the guestCt to 1 always"
              name="hideGuestSelector"
              value={values.displaySettings.hideGuestSelector}
              onChange={(v) => handleFieldChange(v, "hideGuestSelector")}
            />
          </div>
          <SelectBlock
            field="datesDisplayType"
            value={values.displaySettings.datesDisplayType}
            label="Dates display type"
            isDisabled={values.displaySettings.isMultiDayEvent}
            helperText={
              values.displaySettings.isMultiDayEvent
                ? "Date cards will be displayed for multi-day events"
                : "Do you want to display a calendar or date cards?"
            }
            onChange={(v) =>
              handleFieldChange(v.target.value, "datesDisplayType")
            }
            options={[
              { text: "Calendar", value: "calendar" },
              { text: "Date cards", value: "date_cards" },
            ]}
          />
        </>
      )}
      {productType === productTypes.Rental && (
        <>
          <SelectBlock
            field="showSingleCalendar"
            value={values.displaySettings.showSingleCalendar ?? false}
            label="Calendar display"
            onChange={(v) =>
              handleFieldChange(v.target.value, "showSingleCalendar")
            }
            helperText="Choose to display either 1 calendar for a single date/time selection or 2 calendars for a start & end date/time selection"
            options={[
              { text: "Single Calendar", value: true },
              { text: "Start/End Dates Calendars", value: false },
            ]}
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <BooleanSelectBlock
              label="Display time slots?"
              helperText="Do you want to allow your customers to select time slots?"
              name="isActive"
              value={values.displaySettings.timeSlots.isActive}
              onChange={(v) => handleTimeSlotsChange(v, "isActive")}
            />
          </div>
          <SelectBlock
            field="timeSlotType"
            value={values.displaySettings.timeSlots.timeSlotType}
            label="Time slots type"
            isDisabled={!values.displaySettings.timeSlots.isActive}
            onChange={(v) =>
              handleTimeSlotsChange(v.target.value, "timeSlotType")
            }
            options={[
              { text: "Hourly", value: "hourly" },
              { text: "Custom", value: "custom" },
            ]}
          />
          {values.displaySettings.timeSlots.isActive &&
            values.displaySettings.timeSlots.timeSlotType === "hourly" && (
              <>
                <SelectBlock
                  field="timeSlotsStart"
                  value={values.displaySettings.timeSlots.timeSlotsStart}
                  label="Time slots start"
                  isDisabled={!values.displaySettings.timeSlots.isActive}
                  onChange={(v) =>
                    handleTimeSlotsChange(v.target.value, "timeSlotsStart")
                  }
                  options={timeOptions}
                  helperText="Select when available time slots would start"
                />
                <SelectBlock
                  field="timeSlotsEnd"
                  value={values.displaySettings.timeSlots.timeSlotsEnd}
                  label="Time slots end"
                  isDisabled={!values.displaySettings.timeSlots.isActive}
                  onChange={(v) =>
                    handleTimeSlotsChange(v.target.value, "timeSlotsEnd")
                  }
                  options={timeOptions}
                  helperText="Select when available time slots would end"
                />
              </>
            )}
          {values.displaySettings.timeSlots.isActive &&
            values.displaySettings.timeSlots.timeSlotType === "custom" && (
              <DynamicCustomTimeSlots
                customTimeSlots={
                  values.displaySettings.timeSlots.customTimeSlots
                }
                label="Custom time slots"
                helperText="Select the specific time slots you want to display on Booker"
                onChange={(v) => handleTimeSlotsChange(v, "customTimeSlots")}
              />
            )}
        </>
      )}
    </div>
  );
};

import React, { createRef, useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";

export default function BusinessImageUpload(props) {
  // const [image, setImage] = useState([]);
  // const [message, setMessage] = useState("");

  // const uploadHandler = async (e) => {
  //   const imagesData = [];
  //   const files = e.target.files;
  //   for (let i = 0; i < files.length; i++) {
  //     imagesData.push(prev(files[i]));
  //   }
  // };

  // const prev = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setImage((curr) => [...curr, reader.result]);
  //     return reader.result;
  //   };
  // };

  // const upload = async () => {
  //   setMessage("Uploading...");
  //   const formData = new FormData();
  //   for (let i = 0; i < image.length; i++) {
  //     let file = image[i];
  //     formData.append("file", file);
  //   }
  //   try {
  //     const res = await fetch("http://localhost:8000/api/product/upload", {
  //       method: "POST",
  //       body: formData,
  //     });

  //     const data = await res.json();
  //     if (data) {
  //       setMessage("Done!");
  //     }
  //     console.log(data);
  //   } catch (e) {
  //     console.log(e);
  //     setMessage("Error! Could not upload");
  //   }
  // };
  // return (
  //   <>
  //     <input
  //       type="file"
  //       onChange={uploadHandler}
  //       accept="image/*"
  //       multiple="multiple"
  //     />
  //     <button onClick={() => upload(image)}>upload</button>
  //     <p>{message}</p>
  //   </>
  // );
  const [image, _setImage] = useState();
  const inputFileRef = createRef();

  const cleanup = () => {
    URL.revokeObjectURL(image && props.image);
  };

  const setImage = (newImage) => {
    if (image) cleanup();
    _setImage(newImage);
  };

  const handleChange = (event) => {
    const newImage = event.target.files[0];
    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }
    props.imageUpload(event);
  };

  return (
    <>
      <div className="col-span-full mt-8">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Upload photo
        </label>
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="text-center">
            {
              <img
                src={props?.businessProfile?.photoURL || props?.image}
                alt={props?.businessProfile?.name}
                className="h-28 w-28 rounded-full ml-32 mb-2"
              />
            }
            <PhotoIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 text-sm leading-6 text-gray-600 pl-20 mb-4">
              <label
                htmlFor="fileUpload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
              >
                <input
                  type="file"
                  id="fileUpload"
                  onChange={handleChange}
                  ref={inputFileRef}
                  accept="image/*"
                />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              PNG, JPG, GIF up to 10MB
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

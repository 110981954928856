import React from "react";

export default function SingleCheckBox({
  values,
  setValues,
  name,
  label,
  helperText,
}) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 pt-1 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-semibold text-gray-700"
      >
        {label}
      </label>
      <div>
        <input
          type="checkbox"
          name={name}
          checked={values[name] ? "checked" : false}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              [name]: e.target.checked,
            }));
          }}
          className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
      </div>
    </div>
  );
}

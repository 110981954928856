import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../State/AppContext";
import { BusinessAPI, OrderAPI } from "../../apis";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Reports() {
  const { state } = useContext(AppContext);
  const [businesses, setBusinesses] = useState();
  const { organization } = state.user;
  const [orders, setOrders] = useState([]);
  const [activeFilter, setActiveFilter] = useState();

  const isLoaded = useRef(false);

  const fetchBusinessData = () => {
    if (isLoaded.current) {
      BusinessAPI.getBusinesses(organization)
        .then((res) => {
          const { businesses } = res.data;
          setBusinesses(businesses);
          businesses.forEach((business) => {
            OrderAPI.getAll(business._id)
              .then((res) => {
                setOrders((origOrder) => [
                  {
                    businessId: business._id,
                    businessName: business.name,
                    orderTotal: _.size(res.data.orders),
                    revenue: _.sumBy(res.data.orders, "amount"),
                  },
                  ...origOrder,
                ]);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  React.useEffect(() => {
    fetchBusinessData();
    return () => {
      isLoaded.current = true;
    };
  }, []);

  const headers = [
    { label: "Business name", key: "businessName" },
    { label: "Order total", key: "orderTotal" },
    { label: "Revenue", key: "revenue" },
  ];

  const data = _.map(orders, (order) => {
    return {
      businessName: order.businessName,
      orderTotal: order.orderTotal,
      revenue: `$${(order.revenue / 100).toFixed(2)}`,
    };
  });

  const filterDate = (filterBy) => {
    if (isLoaded.current) {
      businesses.forEach((business) => {
        OrderAPI.ordersByDate(business._id, filterBy)
          .then((res) => {
            setActiveFilter(filterBy);
            setOrders([
              {
                businessId: business._id,
                businessName: business.name,
                orderTotal: _.size(res.data.orders),
                revenue: _.sumBy(res.data.orders, "amount"),
              },
            ]);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };


  return (
    orders && (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Reports
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Below is a report for each business
            </p>
          </div>
        </div>

        <div className="bg-white border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5 mt-6">
          <dl className="mx-auto grid max-w-4xl grid-cols-1 lg:grid-cols-2 lg:px-2 xl:px-0">
            <div className="flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-4 sm:px-6 lg:border-t-0 xl:px-8">
              <dt className="text-sm font-medium leading-6 text-gray-500">
                Total Revenue
              </dt>

              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {(_.sumBy(orders, "revenue") / 100).toFixed(2)}
              </dd>
            </div>
            <div className="flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-4 sm:px-6 lg:border-t-0 xl:px-8 sm:border-l">
              <dt className="text-sm font-medium leading-6 text-gray-500">
                Total Orders
              </dt>
              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {_.sumBy(orders, "orderTotal")}
              </dd>
            </div>
          </dl>
        </div>

        <div className="ml-6 pb-4 mt-10 flex w-full gap-x-8 text-sm font-semibold leading-6 sm:w-auto sm:leading-7">
          <span
            className={
              activeFilter === undefined ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate("alltime")}>All-time</button>
          </span>
          <span
            className={
              activeFilter === "thirty" ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate("thirty")}>Last 30 days</button>
          </span>
          <span
            className={
              activeFilter === "seven" ? "text-blue-700" : "text-gray-700"
            }
          >
            <button onClick={() => filterDate("seven")}>Last 7 days</button>
          </span>
          <CSVLink
            data={data}
            headers={headers}
            filename={`${organization}.csv`}
            className="text-red-700"
          >
            Download CSV
          </CSVLink>
        </div>

        <div className="-mx-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-white">
          <table className="min-w-full divide-y divide-gray-300 ">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Business Name
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  No. of Orders
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, orderIdx) => (
                <tr key={order._id}>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-transparent",
                      "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                    )}
                  >
                    <div className="font-medium text-gray-900">
                      <Link
                        className="text-blue-700"
                        to={`/export/${order.businessId}`}
                      >
                        {order.businessName}
                      </Link>
                    </div>

                    {orderIdx !== 0 ? (
                      <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {order.orderTotal}
                  </td>

                  <td
                    className={classNames(
                      orderIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    ${(order.revenue / 100).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}

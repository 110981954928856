import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Business } from "../../apis/Business";
import { Link } from "react-router-dom";
import UpdateBusinessStatusModal from "../Modal/UpdateBusinessStatusModal";
import Steps from "../Steps";

export default function AdminBusinessProfile() {
  const { businessId } = useParams();
  const [businessInfo, setBusiness] = useState();
  const [msg, setMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Business.getById(businessId)
      .then((res) => {
        setBusiness(res.data.business);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  return (
    businessInfo && (
      <>
        {msg && (
          <div
            className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p className="text-sm">{msg}</p>
          </div>
        )}
        <div className="text-center mb-20">
          <Steps businessId={businessId} />
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 mt-4 border-b">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-xl text-gray-900 font-bold">
                {businessInfo.name}
                <span className="ml-2 inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                  {businessInfo.isActive ? "Active" : "Inactive"}
                </span>
              </dd>
              <button
                className="mt-4 mb-4 bg-gray-800 text-white active:bg-gray-600 font-bold text-xs px-1 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 w-1/4"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Update status
              </button>
              <Link
                className="mt-4 ml-2 mb-4 bg-gray-800 text-white active:bg-gray-600 font-bold text-xs px-1 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 w-1/4"
                to={`/export/${businessId}`}
              >
                Reports
              </Link>
              {showModal ? (
                <UpdateBusinessStatusModal
                  setShowModal={setShowModal}
                  setMsg={setMsg}
                  businessId={businessId}
                />
              ) : null}
              <dd className="mt-1 text-sm text-gray-500">
                {businessInfo.notes}
              </dd>
            </div>
          </dl>
        </div>
      </>
    )
  );
}

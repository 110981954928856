import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../State/AppContext";
import { GuestAPI } from "../../../apis/index";
import { GuestCard } from "./GuestCard";

const Guests = () => {
  const { state } = useContext(AppContext);
  const [guests, setGuests] = useState();

  const { business } = state.user;

  useEffect(() => {
    GuestAPI.list(business)
      .then((res) => {
        setGuests(res?.data?.guests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  const handleDeleteGuest = (guestId) => {
    GuestAPI.remove(business, guestId)
      .then(() => {
        toast.success("Guest successfully deleted!");
        window.location.reload(); // state hack
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Guest Types
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Below is a list of all of the guest types for this business.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/guests/add"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
            type="button"
          >
            Add Guest
          </Link>
        </div>
      </div>

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
        {guests &&
          guests.map((g) => (
            <GuestCard
              key={g._id}
              guest={g}
              onDeleteGuest={handleDeleteGuest}
            />
          ))}
      </ul>
    </div>
  );
};

export default Guests;

import { useReducer, createContext, useEffect } from "react";
import UserReducer from "../Reducers/UserReducer";
export const APP_NAME = "operator_app";

//Check the localstorage or set a default state
const initialState = JSON.parse(localStorage.getItem(APP_NAME))
  ? JSON.parse(localStorage.getItem(APP_NAME))
  : {};

const AppContext = createContext(initialState);

const combinedReducers = ({ user }, action) => ({
  user: UserReducer(user, action),
});
const AppState = ({ children }) => {
  // Making it to provider state
  const [state, dispatch] = useReducer(combinedReducers, initialState);
  useEffect(() => {
    localStorage.setItem(APP_NAME, JSON.stringify(state));
  }, [state]);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppState;

export { AppContext, AppState };

import { useContext, useRef } from "react";
import { BusinessAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const EmailSettings = ({ setCustomEmailsData, customEmailsData }) => {
  const reservationConfirmedEditorRef = useRef(null);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  const handleChange = (editedStr, id) => {
    setCustomEmailsData((prev) => ({ ...prev, [id]: editedStr }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reservationConfirmedEditorRef.current) {
      BusinessAPI.updateBusiness(business, "emails", customEmailsData)
        .then((res) => {
          toast.success("Email settings updated!");
        })
        .catch((err) => {
          toast.error("Something went wrong:", err);
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Customize Emails
        </h3>

        <div>
          <label
            htmlFor="reservationConfirmedData"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Reservation Confirmed Email
          </label>
          <p className="text-xs text-gray-500">
            Copy and paste information you'd like to send to your customers in
            their "Reservation Confirmed!" email which is sent to your customers
            right after they complete an order.
          </p>
        </div>
        <div className="mt-2">
          <Editor
            id="reservationConfirmedData"
            apiKey={"3u557s7493kor583in0tern82p6wzpw7leqapaxo3wvhcc0v"}
            onInit={(evt, editor) =>
              (reservationConfirmedEditorRef.current = editor)
            }
            onEditorChange={(e) => handleChange(e, "reservationConfirmedData")}
            value={customEmailsData.reservationConfirmedData}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "image",
                "code",
                "table",
                "link",
                "media",
                "codesample",
                "lists",
              ],
              toolbar:
                "undo redo | blocks fontsize | bold italic underline | link mergetags  | checklist numlist bullist indent outdent",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
            }}
          />
        </div>
      </div>

      <div className="flex justify-start">
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default EmailSettings;

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../State/AppContext";
import { DiscountAPI } from "../../../apis/index";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function Discounts() {
  const { state } = useContext(AppContext);
  const [discounts, setDiscounts] = useState();

  const { role, business } = state.user;

  useEffect(() => {
    DiscountAPI.getDiscounts(business)
      .then((res) => {
        setDiscounts(res?.data?.discounts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  const removeDiscount = (discount) => {
    DiscountAPI.deleteDiscount(business, discount)
      .then(() => {
        toast.success("Discount successfully removed!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return role === "Business" ? (
    <div className="px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Discounts</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all discounts</p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/business/discount/add"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
            type="button"
          >
            Add discount
          </Link>
        </div>
      </div>

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
        {discounts &&
          discounts.map((discount) => (
            <li
              key={discount.name}
              className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow overflow-hidden"
            >
              <div className="flex flex-1 flex-col py-6 px-4 gap-2">
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Name:
                  </h3>
                  <p className="text-sm font-medium text-gray-900 text-left">
                    {discount.name}
                  </p>
                </div>
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Amount:
                  </h3>
                  <p className="text-sm font-medium text-gray-900 text-left">
                    {discount.amount * 100}%
                  </p>
                </div>
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Criteria:
                  </h3>
                  <p className="text-sm font-medium text-gray-900 text-left">
                    {discount.criteria}
                  </p>
                </div>
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Criteria Type:
                  </h3>
                  <p className="text-sm font-medium text-gray-900 text-left">
                    {discount.criteriaType}
                  </p>
                </div>
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Is applied on additional criteria only:
                  </h3>
                  <p className="text-sm font-medium text-gray-900 text-left">
                    {discount.appliedOnAdditional ? "Yes" : "No"}
                  </p>
                </div>
                <div>
                  <h3 className="text-xs font-medium text-gray-500 text-left">
                    Discount applies to the following products:
                  </h3>
                  {discount.productGroups &&
                  discount.productGroups.length > 0 ? (
                    discount.productGroups.map((p) => (
                      <p
                        key={p._id}
                        className="text-sm font-medium text-gray-900 text-left"
                      >
                        {p.title}
                      </p>
                    ))
                  ) : (
                    <p className="text-sm font-medium text-gray-900 text-left">
                      No products selected
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <button
                    onClick={() => removeDiscount(discount._id)}
                    className="relative -mr-px inline-flex w-full flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibol bg-red-100 hover:bg-red-50 transition-colors"
                  >
                    <span className="px-2 py-1 text-xs font-medium text-red-800">
                      Delete
                    </span>
                  </button>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  ) : null;
}

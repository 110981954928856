import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { AppContext } from "../State/AppContext";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

const AuthLayout = ({ allowedRoles }) => {
  const { dispatch } = useContext(AppContext);
  const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
  const { state } = useContext(AppContext);
  const [isProxy, setIsProxy] = useState(false);
  const { role } = state.user;
  const navigate = useNavigate();

  const logOut = () => {
    dispatch({
      type: "LOGOUT",
    });
    cookies.remove(process.env.REACT_APP_TOKEN_NAME, { path: "/" });
    window.location.href = "/";
  };

  useEffect(() => {
    if (state.user.originalUser && state.user.originalUser.role === "Admin") {
      setIsProxy(true);
    }
  }, [state.user.originalUser]);

  const stopProxy = (e) => {
    e.preventDefault();

    const { originalUser } = state.user;

    // set back the original users token in the cookie
    cookies.set(process.env.REACT_APP_TOKEN_NAME, originalUser.originalToken, {
      path: "/",
    });
    dispatch({
      type: "LOGIN",
      payload: {
        id: originalUser.id,
        username: originalUser.username,
        role: originalUser.role, // should always be admin
        firstName: originalUser.firstName,
        lastName: originalUser.lastName,
      },
    });

    navigate("/admin/organizations");
  };

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token && allowedRoles.find((r) => r.includes(role)) ? (
    <div>
      <MobileNav logout={logOut} />
      <DesktopNav logout={logOut} />

      <div className="flex flex-1 flex-col md:pl-64">
        {state.user.role === "Admin" || isProxy === true ? (
          <div className="bg-emerald-600 p-2 sticky top-0 z-50">
            <p className="text-sm font-medium text-white ml-2 group flex ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 mr-2 text-yellow-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                />
              </svg>
              <button
                onClick={(e) => stopProxy(e)}
                className="text-xs font-bold text-white"
              >
                Welcome, Superadmin!
              </button>
              <span className="text-xs ml-4">{state.user.business}</span>
            </p>
          </div>
        ) : null}
        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl px-4 sm:px-6 md:px-8">
              <div className="py-4">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default AuthLayout;

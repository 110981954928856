import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Business = {
  getById: (id) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/business/businessDetail", {
      params: { id: id },
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getAll: () => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/business/getAll", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getBusinesses: (organization) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/business/businessByOrg", {
      params: { organization: organization },
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  addBusiness: async (name, organization, businessType) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      name: name,
      organization: organization,
      businessType: businessType,
    };

    return await axiosInstance.post(
      "/api/business/addBusiness",
      bodyParameters,
      config
    );
  },

  updateBusiness: (id, field, value) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      id: id,
      [field]: value,
    };

    return axiosInstance.put(
      "/api/business/updateBusiness",
      bodyParameters,
      config
    );
  },

  getAmenities: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/business/getAmenities", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  uploadImage: async (formData) => {
    return await axiosInstance.post(
      "https://api.cloudinary.com/v1_1/dsy97ue6s/image/upload",
      formData
    );
  },
};

import React, { useEffect, useState } from "react";
import { OrganizationAPI } from "../../../apis";

const OrganizationForm = ({
  setShowModal,
  setMsg,
  organizationInfo,
  isUpdate,
}) => {
  const [organizationName, setOrganizationName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUpdate) {
      await OrganizationAPI.updateOrganization(
        organizationInfo._id,
        organizationName
      )
        .then((res) => {
          if (res.status === 200) {
            setMsg("Organization as been successfully updated!");
            setShowModal(false);
          }
        })
        .catch((err) => {
          setMsg(err.response.data.message);
          setShowModal(false);
        });
    } else {
      await OrganizationAPI.addOrganization(organizationName)
        .then((res) => {
          if (res.status === 200) {
            setMsg("Organization as been successfully created!");
            setShowModal(false);
          }
        })
        .catch((err) => {
          setMsg(err.response.data.message);
          setShowModal(false);
        });
    }
  };

  useEffect(() => {
    if (organizationInfo) {
      setOrganizationName(organizationInfo.name);
    }
  }, [organizationInfo]);

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Organization name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    autoComplete="given-name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default OrganizationForm;

import { useRef } from "react";
import { BusinessAPI } from "../../../apis";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import SelectBlock from "../Product/Fields/SelectBlock";
import { northAmericaTimeZones } from "./constants";

const BusinessInfo = ({ business, businessInfo, setBusinessInfo }) => {
  const editorRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo({
      ...businessInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editorRef.current) {
      setBusinessInfo({
        ...businessInfo,
        description: editorRef.current.getContent(),
      });
    }

    await BusinessAPI.updateBusiness(business, "businessInfo", businessInfo)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business address has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDateChange = (date) => {
    setBusinessInfo({
      ...businessInfo,
      maxBookableDate: date,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Business information
        </h3>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label
            htmlFor="websiteURL"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Website URL
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="websiteURL"
              value={businessInfo?.websiteURL}
              onChange={handleInputChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>

        <SelectBlock
          value={businessInfo?.checkInTime}
          onChange={handleInputChange}
          field="checkInTime"
          label="Check-in hour"
          options={[...new Array(24)]}
          index={true}
          helperText="Note: 24-hour time format"
        />
        <SelectBlock
          value={businessInfo?.checkOutTime}
          onChange={handleInputChange}
          field="checkOutTime"
          label="Check-out hour"
          options={[...new Array(24)]}
          index={true}
          helperText="Note: 24-hour time format"
        />

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Address
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="address"
              value={businessInfo?.address}
              onChange={handleInputChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <label
            htmlFor="time"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Last bookable date
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <DatePicker
              showTimeSelect
              selected={Date.parse(businessInfo?.maxBookableDate)}
              value={Date.parse(businessInfo?.maxBookableDate)}
              name="maxBookableDate"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              onChange={(date) => handleDateChange(date)}
            />
          </div>
        </div>
        <SelectBlock
          label="Time Zone"
          helperText="Defaults to EST"
          options={northAmericaTimeZones}
          field="timeZone"
          value={businessInfo?.timeZone}
          onChange={handleInputChange}
        />
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};
export default BusinessInfo;

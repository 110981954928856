import React, { useState, useContext, useEffect } from "react";
import { BusinessAPI } from "../../../apis";
import ChangePassword from "../../ChangePassword";
import ImageUpload from "../Product/Fields/ImageUpload";
import { AppContext } from "../../State/AppContext";
import { toast } from "react-toastify";
import Settings from "../Settings/Settings";

export default function BusinessProfile() {
  const [openTab, setOpenTab] = React.useState(1);
  const [productImage, setProductImage] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [imageUpload] = useState({});
  const [, setImg] = useState({});
  const { state } = useContext(AppContext);

  const { business } = state.user;

  useEffect(() => {
    BusinessAPI.getById(business)
      .then((res) => {
        setBusinessProfile(res?.data?.business);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [business]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let urlCDN;
    if (productImage !== "") {
      imageUpload.image = productImage;
      urlCDN = await profileUpload(productImage);
    }

    await BusinessAPI.updateBusiness(business, "photoURL", urlCDN)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Image has been uploaded!");
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleImg = async (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
      setProductImage(e.target.files[0]);
    }
  };

  const profileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "operator");

    let data;
    await BusinessAPI.uploadImage(formData)
      .then((res) => {
        data = res.data.secure_url;
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal " +
                  (openTab === 1 ? "text-blue-500" : "text-grey-600 bg-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Profile
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal " +
                  (openTab === 2 ? "text-blue-500" : "text-grey-600 bg-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Settings
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 rounded block leading-normal " +
                  (openTab === 3 ? "text-blue-500" : "text-grey-600 bg-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Password
              </a>
            </li>
          </ul>

          <div className="relative flex flex-col min-w-0 break-words bg-blue w-full mb-6 rounded">
            <div className="flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <form onSubmit={handleSubmit}>
                    <ImageUpload
                      imageUpload={handleImg}
                      image={imageUpload.image}
                      businessProfile={businessProfile}
                    />
                    <div className="pt-8 pr-8">
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <Settings />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const repeatTypes = {
  Daily: "daily",
  Weekly: "weekly",
  Monthly: "monthly",
  Yearly: "yearly",
};

export const untilTypes = {
  Never: "never",
  UntilDate: "until_date",
};

export const repeatOptions = [
  { text: "Daily", value: repeatTypes.Daily },
  { text: "Weekly", value: repeatTypes.Weekly },
  { text: "Monthly", value: repeatTypes.Monthly },
  { text: "Yearly", value: repeatTypes.Yearly },
];

export const weekDayOptions = [
  { text: "Sun", value: "su" },
  { text: "Mon", value: "mo" },
  { text: "Tue", value: "tu" },
  { text: "Wed", value: "we" },
  { text: "Thu", value: "th" },
  { text: "Fri", value: "fr" },
  { text: "Sat", value: "sa" },
];

export const untilTypeOptions = [
  { text: "Never stop", value: untilTypes.Never },
  { text: "Run until a specific date", value: untilTypes.UntilDate },
];

export const INTERVAL_TYPE_LOOKUP = {
  [repeatTypes.Daily]: "days",
  [repeatTypes.Weekly]: "weeks",
  [repeatTypes.Monthly]: "months",
  [repeatTypes.Yearly]: "years",
};

export const dayOfMonthOptions = [
  { text: "First", value: 1 },
  { text: "Second", value: 2 },
  { text: "Third", value: 3 },
  { text: "Fourth", value: 4 },
  { text: "Fifth", value: 5 },
  { text: "Sixth", value: 6 },
  { text: "Seventh", value: 7 },
  { text: "Eighth", value: 8 },
  { text: "Ninth", value: 9 },
  { text: "Tenth", value: 10 },
  { text: "Eleventh", value: 11 },
  { text: "Twelfth", value: 12 },
  { text: "Thirteenth", value: 13 },
  { text: "Fourteenth", value: 14 },
  { text: "Fifteenth", value: 15 },
  { text: "Sixteenth", value: 16 },
  { text: "Seventeenth", value: 17 },
  { text: "Eighteenth", value: 18 },
  { text: "Nineteenth", value: 19 },
  { text: "Twentieth", value: 20 },
  { text: "Twenty-first", value: 21 },
  { text: "Twenty-second", value: 22 },
  { text: "Twenty-third", value: 23 },
  { text: "Twenty-fourth", value: 24 },
  { text: "Twenty-fifth", value: 25 },
  { text: "Twenty-sixth", value: 26 },
  { text: "Twenty-seventh", value: 27 },
  { text: "Twenty-eighth", value: 28 },
  { text: "Twenty-ninth", value: 29 },
  { text: "Thirtieth", value: 30 },
  { text: "Thirty-first", value: 31 },
  { text: "Last", value: -1 },
];

export const monthOptions = [
  { text: "January", value: 0 },
  { text: "February", value: 1 },
  { text: "March", value: 2 },
  { text: "April", value: 3 },
  { text: "May", value: 4 },
  { text: "June", value: 5 },
  { text: "July", value: 6 },
  { text: "August", value: 7 },
  { text: "September", value: 8 },
  { text: "October", value: 9 },
  { text: "November", value: 10 },
  { text: "December", value: 11 },
];

export const timeOptions = [
  { text: "1:00", value: 1 },
  { text: "2:00", value: 2 },
  { text: "3:00", value: 3 },
  { text: "4:00", value: 4 },
  { text: "5:00", value: 5 },
  { text: "6:00", value: 6 },
  { text: "7:00", value: 7 },
  { text: "8:00", value: 8 },
  { text: "9:00", value: 9 },
  { text: "10:00", value: 10 },
  { text: "11:00", value: 11 },
  { text: "12:00", value: 12 },
  { text: "13:00", value: 13 },
  { text: "14:00", value: 14 },
  { text: "15:00", value: 15 },
  { text: "16:00", value: 16 },
  { text: "17:00", value: 17 },
  { text: "18:00", value: 18 },
  { text: "19:00", value: 19 },
  { text: "20:00", value: 20 },
  { text: "21:00", value: 21 },
  { text: "22:00", value: 22 },
  { text: "23:00", value: 23 },
  { text: "24:00", value: 24 },
];
export const hourOptions = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "11", value: 11 },
  { text: "12", value: 12 },
  { text: "13", value: 13 },
  { text: "14", value: 14 },
  { text: "15", value: 15 },
  { text: "16", value: 16 },
  { text: "17", value: 17 },
  { text: "18", value: 18 },
  { text: "19", value: 19 },
  { text: "20", value: 20 },
  { text: "21", value: 21 },
  { text: "22", value: 22 },
  { text: "23", value: 23 },
  { text: "24", value: 24 },
];

export const minuteOptions = [
  { text: "00", value: 0 },
  { text: "30", value: 30 },
];

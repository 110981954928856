import InputBlock from "./Fields/InputBlock";
import TextAreaBlock from "./Fields/TextAreaBlock";
import DynamicPrice from "./Fields/DynamicPrice";
import ImageUpload from "./Fields/ImageUpload";
import SelectBlock from "./Fields/SelectBlock";
import SpecialRequest from "./Fields/SpecialRequest";
import SingleCheckBox from "./Fields/SingleCheckBox";
import SeasonalOverrides from "./Fields/SeasonalOverrides";
import { Options } from "./Fields/Options";

export default function FeeEditForm({
  imageUpload,
  handleInputChange,
  handleSubmit,
  submitted,
  values,
  prices,
  setPrices,
  setValues,
  seasonalOverrides,
  setSeasonalOverrides,
  options,
  setOptions,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Product Information
        </h3>

        <ImageUpload imageUpload={imageUpload} />

        <div className="space-y-2 sm:space-y-4">
          <InputBlock
            value={values.title}
            onChange={handleInputChange}
            field="title"
            label="Title"
          />
          <TextAreaBlock
            value={values.description}
            onChange={handleInputChange}
            field="description"
            label="Description"
          />
          <SingleCheckBox
            label={
              <>
                Is this product <strong>only</strong> to be used as an add-on
                for other products?
              </>
            }
            values={values}
            setValues={setValues}
            name="isAddOnOnly"
            helperText="Select me for yes"
          />
          <SingleCheckBox
            label="Is this product to be charged per day?"
            values={values}
            setValues={setValues}
            name="isPerDay"
          />
          <SpecialRequest
            description={values.specialRequest.description}
            isActive={values.specialRequest.isActive}
            setValues={setValues}
          />

          <DynamicPrice
            prices={prices}
            setPrices={setPrices}
            maxLimit={2}
            isFeeOrAccommodationProduct
          />
          <SelectBlock
            field="canSelectMultiple"
            label="Can customers choose to pay for more than one?"
            value={values.canSelectMultiple}
            onChange={handleInputChange}
            options={[
              { text: "Yes", value: true },
              { text: "No", value: false },
            ]}
          />
          {values.canSelectMultiple ? (
            <SelectBlock
              value={values.maxQuantity}
              onChange={handleInputChange}
              options={[...new Array(20)]}
              field="maxQuantity"
              label="Maximum quantity"
              index
            />
          ) : null}
          <SeasonalOverrides
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            title="Seasonal Overrides Section"
            description="The pricing you set here will override the product's base properties for the date range you select"
            hideMinNightsOverride
            isFeeOrAccommodationProduct
          />
          <Options options={options} setOptions={setOptions} />
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 pt-1 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="isActive"
              className="block text-sm font-semibold text-red-800"
            >
              Deactivate this product?
            </label>
            <div>
              <input
                type="checkbox"
                name="isActive"
                checked={values.isActive === false ? "checked" : false}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    isActive: !e.target.checked,
                  }));
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8 pr-8">
        <div className="flex justify-end">
          <button
            disabled={submitted}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

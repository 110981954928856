import { BusinessAPI } from "../../../apis";
import { toast } from "react-toastify";
import { twClassNames } from "../../../utils/twClassNames";
import SpecialRequest from "./SpecialRequest";
import BooleanSelectBlock from "../Product/Fields/BooleanSelectBlock";

const BusinessRules = ({ business, businessRules, setBusinessRules }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();

    await BusinessAPI.updateBusiness(business, "businessRules", businessRules)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessRules({
      ...businessRules,
      [name]: [
        "maxNumSelectedAddOns",
        "maxTotalGuestsPerTransaction",
        "numMandatoryAddOns",
      ].includes(name)
        ? Number(value)
        : value,
    });
  };

  const handleBooleanChange = (payload, field) => {
    if (field === "isGuestNamesRecorded" && payload === false) {
      setBusinessRules({
        ...businessRules,
        [field]: payload,
        isGuestNamesMandatory: false,
      });
    } else {
      setBusinessRules({
        ...businessRules,
        [field]: payload,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Business rules
        </h3>

        <div className="space-y-3">
          <h4 className="text-sm font-medium leading-6 text-gray-50 bg-gray-400 rounded-full w-max px-4">
            Guest rules
          </h4>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Record guest names?
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <BooleanSelectBlock
                name="isGuestNamesRecorded"
                value={businessRules?.isGuestNamesRecorded ? true : false}
                onChange={(e) => handleBooleanChange(e, "isGuestNamesRecorded")}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span
              className={twClassNames(
                "block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2",
                !businessRules?.isGuestNamesRecorded && "opacity-50"
              )}
            >
              Is the recording of guest names mandatory?
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <BooleanSelectBlock
                name="isGuestNamesMandatory"
                value={businessRules?.isGuestNamesMandatory ? true : false}
                onChange={(e) =>
                  handleBooleanChange(e, "isGuestNamesMandatory")
                }
                disabled={!businessRules?.isGuestNamesRecorded}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Is the price to be calculated per guest?
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <BooleanSelectBlock
                name="isPriceCalculatedPerGuest"
                value={businessRules?.isPriceCalculatedPerGuest ? true : false}
                onChange={(e) =>
                  handleBooleanChange(e, "isPriceCalculatedPerGuest")
                }
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Max amount of guests bookable per transaction
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                id="maxTotalGuestsPerTransaction"
                name="maxTotalGuestsPerTransaction"
                value={businessRules?.maxTotalGuestsPerTransaction}
                onChange={handleInputChange}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <h4 className="text-sm font-medium leading-6 text-gray-50 bg-gray-400 rounded-full w-max px-4">
            Add-on rules
          </h4>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Show add ons?
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <BooleanSelectBlock
                name="showAddOns"
                value={businessRules?.showAddOns ? true : false}
                onChange={(e) => handleBooleanChange(e, "showAddOns")}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <div className="flex-col flex mr-2">
              <span
                className={twClassNames(
                  "block text-sm font-medium text-gray-600 ",
                  !businessRules?.showAddOns && "opacity-50"
                )}
              >
                Number of required/mandatory add-ons
                <span className="block text-xs text-gray-400 font-medium">
                  Defaults to 0
                </span>
              </span>
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                step={1}
                min={0}
                id="numMandatoryAddOns"
                name="numMandatoryAddOns"
                value={businessRules?.numMandatoryAddOns}
                onChange={handleInputChange}
                disabled={!businessRules?.showAddOns}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <div className="flex-col flex mr-2">
              <span
                className={twClassNames(
                  "block text-sm font-medium text-gray-600 ",
                  !businessRules?.showAddOns && "opacity-50"
                )}
              >
                Maximum number of selectable add-ons
                <span className="block text-xs text-gray-400 font-medium">
                  Defaults to 0
                </span>
              </span>
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                step={1}
                min={0}
                id="maxNumSelectedAddOns"
                name="maxNumSelectedAddOns"
                value={businessRules?.maxNumSelectedAddOns}
                onChange={handleInputChange}
                disabled={!businessRules?.showAddOns}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <div className="flex-col flex mr-2">
              <span
                className={twClassNames(
                  "block text-sm font-medium text-gray-600 ",
                  !businessRules?.showAddOns && "opacity-50"
                )}
              >
                Title of Add Ons Section in booker
                <span className="block text-xs text-gray-400 font-medium">
                  Defaults to "Add Ons"
                </span>
              </span>
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                id="addOnTitle"
                name="addOnTitle"
                value={businessRules?.addOnTitle}
                onChange={handleInputChange}
                disabled={!businessRules?.showAddOns}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <div className="flex-col flex mr-2">
              <span
                className={twClassNames(
                  "block text-sm font-medium text-gray-600 ",
                  !businessRules?.showAddOns && "opacity-50"
                )}
              >
                Description of Add Ons Section in booker
              </span>
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                id="addOnDesc"
                name="addOnDesc"
                value={businessRules?.addOnDesc}
                onChange={handleInputChange}
                disabled={!businessRules?.showAddOns}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>
          </div>
        </div>

        <SpecialRequest
          customerSpecialRequest={businessRules.customerSpecialRequest}
          setBusinessRules={setBusinessRules}
        />

        <div className="space-y-3">
          <h4 className="text-sm font-medium leading-6 text-gray-50 bg-gray-400 rounded-full w-max px-4">
            Other rules
          </h4>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
              Show promo code field
            </span>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <BooleanSelectBlock
                name="showPromoCode"
                value={businessRules?.showPromoCode ? true : false}
                onChange={(e) => handleBooleanChange(e, "showPromoCode")}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};
export default BusinessRules;

import React, { useState } from "react";
import { BusinessAPI } from "../../../apis";

const UpdateBusinessStatusForm = ({ setShowModal, setMsg, businessId }) => {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await BusinessAPI.updateBusiness(businessId, "status", status)
      .then((res) => {
        if (res.status === 200) {
          setMsg("Business Status has been successfully updated!");
          setShowModal(false);
          // hack.. todo: bubble up
          window.location.reload();
        }
      })
      .catch((err) => {
        setMsg(err.response.data.message);
        setShowModal(false);
      });
  };

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit}
      >
        <div className="space-y-8 sm:space-y-5 pb-4">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Update Business Status
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <select
                id="status"
                name="status"
                autoComplete="status"
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              >
                <option key="----" value="----">
                  --- Please Select ---
                </option>
                <option key="Contacted" value="Contacted">
                  Contacted
                </option>
                <option key="Discovery Call" value="Discovery Call">
                  Discovery Call
                </option>
                <option key="Approved" value="Approved">
                  Approved
                </option>
                <option key="In Progress" value="In Progress">
                  In Progress
                </option>
                <option key="Testing" value="Testing">
                  Testing
                </option>
                <option key="Launched" value="Launched">
                  Launched
                </option>
                <option key="Declined" value="Declined">
                  Declined
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateBusinessStatusForm;

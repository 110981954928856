import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { BusinessAPI } from "../../../apis";

export default function WelcomeMessage({
  welcomeMessages,
  setWelcomeMessages,
  business,
}) {
  const handleSubmit = async (e) => {
    e.preventDefault();

    await BusinessAPI.updateBusiness(
      business,
      "welcomeMessages",
      welcomeMessages
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleChange = (index, event, field) => {
    let newRow = [...welcomeMessages];

    newRow[index][field] = event.target.value;
    setWelcomeMessages(newRow);
  };

  const addFormFields = () => {
    setWelcomeMessages([...welcomeMessages, { welcomeCode: "", message: "" }]);
  };

  const removeFormFields = (i) => {
    let newRow = [...welcomeMessages];
    newRow.splice(i, 1);
    setWelcomeMessages(newRow);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
        <div className="space-y-6 sm:space-y-5 p-10">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Welcome Messages
          </h3>
          {welcomeMessages.map((element, index) => (
            <div key={index}>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
                  Welcome message code
                </span>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="welcomeCode"
                    value={element.welcomeCode}
                    onChange={(event) =>
                      handleChange(index, event, "welcomeCode")
                    }
                    className="block p-2.5 rounded-md w-24 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor={element.message}
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Welcome message
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    name="message"
                    value={element.message}
                    onChange={(event) => handleChange(index, event, "message")}
                    className="block h-20 w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  ></textarea>
                </div>
              </div>

              <button
                type="submit"
                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-percent h-5 w-5"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />{" "}
                </svg>
              </button>

              {index ? (
                <button
                  type="button"
                  className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </button>
              ) : null}
            </div>
          ))}
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            type="button"
            onClick={() => addFormFields()}
          >
            Add another row
          </button>
        </div>
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </form>
    </>
  );
}

import React from "react";
import { hourOptions, minuteOptions } from "./constants";

export const DynamicCustomTimeSlots = ({
  customTimeSlots,
  label,
  onChange,
  helperText = "",
}) => {
  const handleTimeSlotChange = (index, value, field) => {
    let newTimeSlots = [...customTimeSlots];

    newTimeSlots[index][field] = value;
    onChange(newTimeSlots);
  };

  const addTimeSlot = () => {
    const newTimeSlots = [...customTimeSlots, { hour: 1, minute: 0 }];
    onChange(newTimeSlots);
  };

  const removeTimeSlot = (i) => {
    let newTimeSlots = [...customTimeSlots];
    newTimeSlots.splice(i, 1);
    onChange(newTimeSlots);
  };

  return (
    <>
      <div className="flex flex-col gap-1 sm:pt-2">
        <p className="block font-medium text-gray-700 text-sm sm:mt-px sm:pt-2">
          {label}
        </p>
        {helperText.length > 0 && (
          <p className="text-gray-500 text-xs">{helperText}</p>
        )}
      </div>
      {customTimeSlots.length > 0 &&
        customTimeSlots.map((element, index) => {
          return (
            <div
              key={index}
              className="sm:grid sm:grid-cols-7 sm:items-start sm:gap-4 pt-5"
            >
              <label
                htmlFor={`time-slot${index + 1} - hour`}
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Time slot {index + 1}
              </label>
              <select
                name={`time-slot${index + 1} - hour`}
                className=" col-span-2 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                value={element.hour}
                onChange={(e) =>
                  handleTimeSlotChange(index, Number(e.target.value), "hour")
                }
              >
                <option key="----" value="----">
                  --- Please Select ---
                </option>
                {hourOptions.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
              <div className="text-center text-xl">:</div>
              <select
                name={`time-slot${index + 1} - minute`}
                className=" col-span-2 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                value={element.minute}
                onChange={(e) =>
                  handleTimeSlotChange(index, Number(e.target.value), "minute")
                }
              >
                <option key="----" value="----">
                  --- Please Select ---
                </option>
                {minuteOptions.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
              {index > 0 && (
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => removeTimeSlot(index)}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
      <div className="pt-5">
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          type="button"
          onClick={() => addTimeSlot()}
        >
          Add another time slot
        </button>
      </div>
    </>
  );
};

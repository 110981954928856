import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Order = {
  getAll: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/orders", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  ordersByDate: (businessId, filterBy) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/ordersByDate", {
      params: { businessId: businessId, filterBy: filterBy },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  orderInfo: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/orderInfo", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getOrdersByBusinessId: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/getOrdersByBusinessId", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getOrder: (orderId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/order/getOrder", {
      params: { orderId: orderId },
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  createRefund: async (
    order,
    refundAmount,
    refundStatus,
    orderStatus,
    refundApplicationFee
  ) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      order,
      refundAmount,
      refundStatus,
      orderStatus,
      refundApplicationFee,
    };

    return await axiosInstance.post(
      "/api/order/createRefund",
      bodyParameters,
      config
    );
  },

  cancelOrder: async (order) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      order: order,
    };

    return await axiosInstance.post(
      "/api/order/cancelOrder",
      bodyParameters,
      config
    );
  },

  createOrder: async (
    productIds,
    values,
    metadata,
    business,
    orderType,
    customer
  ) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      products: productIds,
      name: values.fullName,
      email: values.email,
      startDateTime: values.startDateTime,
      endDateTime: values.endDateTime,
      metadata: metadata,
      business: business,
      orderType: orderType,
      customer: customer,
    };

    return await axiosInstance.post(
      "/api/order/createOrder",
      bodyParameters,
      config
    );
  },

  update: async (order) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await axiosInstance.put(
      `/api/order/update?orderId=${order?.orderNumber}`,
      order,
      config
    );
  },
};

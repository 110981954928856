import { useState, useContext, useEffect, useRef } from "react";
import { BusinessAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const TermsConditions = () => {
  const [termsConditions, setTermsConditions] = useState("");

  const editorRef = useRef(null);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  useEffect(() => {
    BusinessAPI.getById(business)
      .then((res) => {
        setTermsConditions(res?.data?.business?.termsConditions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business, termsConditions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editorRef.current) {
      BusinessAPI.updateBusiness(
        business,
        "termsConditions",
        editorRef.current.getContent()
      )
        .then((res) => {
          toast.success("Terms & Conditions updated!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Terms and Conditions
        </h3>

        <label
          htmlFor="termsConditions"
          className="block text-sm font-medium leading-6 text-gray-600"
        >
          Copy and paste your businesses terms and conditions. e.g: Cancellation
          conditions.
        </label>
        <div className="mt-2">
          <Editor
            apiKey={"3u557s7493kor583in0tern82p6wzpw7leqapaxo3wvhcc0v"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={termsConditions}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "image",
                "code",
                "table",
                "link",
                "media",
                "codesample",
                "lists",
              ],
              toolbar:
                "undo redo | blocks fontsize | bold italic underline | link mergetags  | checklist numlist bullist indent outdent",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
            }}
          />
        </div>
      </div>

      <div className="flex justify-start">
        <button
          type="submit"
          className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default TermsConditions;

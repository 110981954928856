import React, { Fragment, useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import ReactDatePicker from "react-datepicker";
import { PromoCodeAPI } from "../../../apis";
import { toast } from "react-toastify";

const UpdatePromoCodeModal = ({ promoCode, isOpen, setIsOpen }) => {
  const [updatedPromoCode, setUpdatedPromoCode] = useState(promoCode);

  useEffect(() => {
    if (promoCode) {
      setUpdatedPromoCode(promoCode);
    }
  }, [promoCode]);

  if (!promoCode) {
    return null;
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (e, isUsageLimit) => {
    const { name, value } = e.target;

    const formattedValue = isUsageLimit ? Number(value) : value;

    setUpdatedPromoCode((prev) => ({ ...prev, [name]: formattedValue }));
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setUpdatedPromoCode((prev) => ({
      ...prev,
      [name]:
        prev.valueType === "percentage"
          ? Number(value) / 100
          : Number(value) * 100,
    }));
  };

  const handleDateChange = (date) => {
    setUpdatedPromoCode((prev) => ({
      ...prev,
      validUntil: date.toISOString(),
    }));
  };
  const handleSelectInfiniteUsage = (e) => {
    if (e.target.checked) {
      setUpdatedPromoCode((prev) => ({
        ...prev,
        usageLimit: "infinite",
      }));
    } else {
      setUpdatedPromoCode((prev) => ({
        ...prev,
        usageLimit: 1,
      }));
    }
  };
  const handleDeactivateCode = (e) => {
    setUpdatedPromoCode((prev) => ({
      ...prev,
      isActive: !e.target.checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    PromoCodeAPI.updatePromoCode(updatedPromoCode)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            res.data.message ?? "Promo code has been successfully updated!"
          );
          closeModal();
          window.location.reload(); // state hack
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const isValueTypePercentage = updatedPromoCode.valueType === "percentage";

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Body */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Editing Promo Code:&nbsp;
                        <span className="text-purple-800">
                          {promoCode.promoCode}
                        </span>
                      </Dialog.Title>
                      <form id="editPromoCodeForm" onSubmit={handleSubmit}>
                        <div className="mt-5 w-full flex flex-col gap-3">
                          <div>
                            <label
                              htmlFor="valueType"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Value Type
                            </label>
                            <div className="pt-1">
                              <select
                                name="valueType"
                                value={updatedPromoCode.valueType}
                                onChange={handleChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="fee">Fixed Rate ($)</option>
                                <option value="percentage">
                                  Percentage (%)
                                </option>
                              </select>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="value"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Amount ({isValueTypePercentage ? "%" : "$"})
                            </label>
                            <div className="pt-1">
                              <input
                                type="number"
                                name="promoCodeValue"
                                max={isValueTypePercentage ? 100 : undefined}
                                step={1}
                                min={1}
                                value={
                                  isValueTypePercentage
                                    ? updatedPromoCode.promoCodeValue * 100
                                    : updatedPromoCode.promoCodeValue / 100
                                }
                                onChange={handleValueChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="validUntil"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Expiration Date
                            </label>
                            <div className="pt-1">
                              <ReactDatePicker
                                name="validUntil"
                                dateFormat="MMMM d, yyyy"
                                value={updatedPromoCode.validUntil}
                                onChange={handleDateChange}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              />
                            </div>
                          </div>

                          <div>
                            <div>
                              <label
                                htmlFor="usageLimit"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Usage Limit
                              </label>
                              <p className="text-xs text-gray-500">
                                This determines how many times the promo code
                                value can be reused
                              </p>
                            </div>
                            <div className="pt-1">
                              <div className="flex flex-1 gap-2">
                                <input
                                  type="number"
                                  name="usageLimit"
                                  min={0}
                                  value={updatedPromoCode.usageLimit}
                                  onChange={(e) => handleChange(e, true)}
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50 flex-1"
                                />
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="checkbox"
                                    name="usageLimit"
                                    checked={
                                      updatedPromoCode.usageLimit === "infinite"
                                    }
                                    onChange={handleSelectInfiniteUsage}
                                    className="flex-1 w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  Infinite usage
                                </label>
                              </div>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="isActive"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Select this to deactivate promo code
                            </label>
                            <div className="pt-1">
                              <input
                                type="checkbox"
                                name="isActive"
                                checked={
                                  updatedPromoCode.isActive === false
                                    ? "checked"
                                    : undefined
                                }
                                onChange={handleDeactivateCode}
                                className="flex-1 w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    form="editPromoCodeForm"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setUpdatedPromoCode(promoCode);
                      closeModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdatePromoCodeModal;

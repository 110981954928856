import { useState, useContext, useEffect, useCallback } from "react";
import TermsConditions from "./TermsConditions";
import CutOff from "./CutOff";
import { BusinessAPI } from "../../../apis";
import { AppContext } from "../../State/AppContext";

import Contact from "./Contact";
import BusinessInfo from "./BusinessInfo";
import BusinessHours from "./BusinessHours";
import BusinessDescription from "./BusinessDescription";
import DepositSettings from "./DepositSettings";
import WelcomeMessage from "./WelcomeMessage";
import BusinessRules from "./BusinessRules";
import { SecurityDeposit } from "./SecurityDeposit";
import PaymentInfo from "./PaymentInfo";
import EmailSettings from "./EmailSettings";

const Settings = () => {
  const [cutOffRows, setCutOffRows] = useState([
    { cancellationDays: 0, refundAmount: 0 },
  ]);
  const [businessHours, setBusinessHours] = useState([
    { dayOfWeek: "Sunday", openingTime: "", closingTime: "" },
  ]);
  const [welcomeMessages, setWelcomeMessages] = useState([
    { cancellationDays: "", refundAmount: "" },
  ]);
  const [businessInfo, setBusinessInfo] = useState({});
  const [contactInfo, setContactInfo] = useState("");
  const [depositInfo, setDepositInfo] = useState({
    chargeDaysBefore: 3,
    rateType: "percentage",
    rate: 0,
    enabled: false,
  });
  const [securityDepositInfo, setSecurityDepositInfo] = useState({
    rateType: undefined,
    rate: 0,
    enabled: false,
  });
  const [businessRules, setBusinessRules] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [customEmailsData, setCustomEmailsData] = useState({});

  const { state } = useContext(AppContext);
  const { business } = state.user;

  const fetchBusinessDetail = useCallback(() => {
    BusinessAPI.getById(business)
      .then((res) => {
        const {
          cancellationPolicy,
          businessInfo,
          contact,
          businessHours,
          deposit,
          welcomeMessages,
          businessRules,
          securityDeposit,
          paymentInfo,
          emails,
        } = res.data.business;
        setCutOffRows(cancellationPolicy);
        setBusinessHours(businessHours);
        setBusinessInfo(businessInfo);
        setContactInfo(contact);
        setDepositInfo(
          deposit ?? {
            chargeDaysBefore: 3,
            rateType: "percentage",
            rate: 0,
            enabled: false,
          }
        );
        setBusinessRules(businessRules);
        setWelcomeMessages(welcomeMessages);
        setSecurityDepositInfo(
          securityDeposit ?? {
            rateType: undefined,
            rate: 0,
            enabled: false,
          }
        );
        setPaymentInfo(
          paymentInfo ?? {
            isTaxExempt: false,
            applicationPercentage: 0.0495,
            customerApplicationPercentage: 0.035,
            taxPercentage: 0.13,
            disclaimerText:
              "All payments are processed securely through Stripe.",
            connectedAccountId: "",
          }
        );
        setCustomEmailsData(
          emails ?? {
            reservationConfirmedData: "",
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  useEffect(() => {
    fetchBusinessDetail();
  }, [fetchBusinessDetail]);

  return (
    <>
      <CutOff
        business={business}
        cutOffRows={cutOffRows}
        setCutOffRows={setCutOffRows}
      />
      <WelcomeMessage
        business={business}
        welcomeMessages={welcomeMessages}
        setWelcomeMessages={setWelcomeMessages}
      />
      <DepositSettings
        business={business}
        depositInfo={depositInfo}
        setDepositInfo={setDepositInfo}
      />
      <SecurityDeposit
        business={business}
        securityDepositInfo={securityDepositInfo}
        setSecurityDepositInfo={setSecurityDepositInfo}
      />
      <BusinessRules
        business={business}
        businessRules={businessRules}
        setBusinessRules={setBusinessRules}
      />
      <Contact
        business={business}
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
      />
      <BusinessInfo
        business={business}
        setBusinessInfo={setBusinessInfo}
        businessInfo={businessInfo}
      />
      <PaymentInfo
        business={business}
        paymentInfo={paymentInfo}
        setPaymentInfo={setPaymentInfo}
      />
      <BusinessDescription />
      <BusinessHours
        business={business}
        businessHours={businessHours}
        setBusinessHours={setBusinessHours}
      />
      <TermsConditions />
      <EmailSettings
        customEmailsData={customEmailsData}
        setCustomEmailsData={setCustomEmailsData}
      />
    </>
  );
};

export default Settings;

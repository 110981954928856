import React from "react";
import BooleanSelectBlock from "./BooleanSelectBlock";

export default function SpecialRequest({ isActive, description, setValues }) {
  const onSpecialRequestChange = (e, isCheckbox) => {
    const value = isCheckbox ? e : e.target.value;
    const field = isCheckbox ? "isActive" : "description";
    setValues((prev) => ({
      ...prev,
      specialRequest: { ...prev.specialRequest, [field]: value },
    }));
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 border-t">
      <BooleanSelectBlock
        label="Special requests?"
        helperText="Select to allow customers to add a special request on this product"
        name="isActive"
        value={isActive}
        onChange={(v) => onSpecialRequestChange(v, true)}
      />
      <div className="col-start-2 col-span-2">
        {isActive && (
          <input
            type="text"
            name="description"
            value={description}
            placeholder="Optional special request label"
            onChange={onSpecialRequestChange}
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm mt-2"
          />
        )}
      </div>
    </div>
  );
}

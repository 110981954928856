import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

export default axiosInstance;

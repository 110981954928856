import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectBlock from "./SelectBlock";
import {
  untilTypes,
  INTERVAL_TYPE_LOOKUP,
  repeatOptions,
  weekDayOptions,
  untilTypeOptions,
  repeatTypes,
  dayOfMonthOptions,
  monthOptions,
} from "./constants";

export default function EventAvailability({
  isMultiDayEvent,
  eventAvailability,
  setEventAvailability,
}) {
  const [untilType, setUntilType] = useState(
    eventAvailability.until && eventAvailability.until.length > 0
      ? untilTypes.UntilDate
      : untilTypes.Never
  );

  const handleDateChange = (date, field) => {
    setEventAvailability((prev) => ({
      ...prev,
      [field]: date.toISOString(),
    }));
  };

  const handleChange = (value, field) => {
    setEventAvailability((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleWeekDaysChange = (value) => {
    const isValueIncluded = !!eventAvailability.weekDays.includes(value);
    const updatedWeekDays = isValueIncluded
      ? eventAvailability.weekDays.filter((d) => d !== value)
      : eventAvailability.weekDays;
    if (!isValueIncluded) {
      updatedWeekDays.push(value);
    }

    setEventAvailability((prev) => ({
      ...prev,
      weekDays: updatedWeekDays,
    }));
  };

  const updateUntilType = (value) => {
    if (value === untilTypes.Never) {
      setEventAvailability((prev) => ({
        ...prev,
        until: "",
      }));
    }
    setUntilType(value);
  };

  const handleMultiDateChange = (value, field, index) => {
    let newMultiDayEventIntervals = [
      ...eventAvailability.multiDayEventIntervals,
    ];
    newMultiDayEventIntervals[index][field] = value.toISOString();
    setEventAvailability((prev) => ({
      ...prev,
      multiDayEventIntervals: newMultiDayEventIntervals,
    }));
  };

  const addFormFields = () => {
    setEventAvailability((prev) => ({
      ...prev,
      multiDayEventIntervals: [
        ...prev.multiDayEventIntervals,
        { startDateTime: "", endDateTime: "" },
      ],
    }));
  };

  const removeFormFields = (i) => {
    let newMultiDayEventIntervals = [
      ...eventAvailability.multiDayEventIntervals,
    ];
    newMultiDayEventIntervals.splice(i, 1);
    setEventAvailability((prev) => ({
      ...prev,
      multiDayEventIntervals: newMultiDayEventIntervals,
    }));
  };

  return (
    <div className="sm:border-t sm:border-gray-200 ">
      <div className="flex flex-col gap-1 sm:pt-2">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Event Availability Section
        </p>
        <p className="text-gray-500 text-sm">
          In this section you can set what day(s) this event is available.
        </p>
      </div>

      {isMultiDayEvent ? (
        <div className="mt-5 bg-gray-50 rounded-md p-2">
          <p className="font-medium text-gray-700">Available Date Ranges</p>
          <p className="font-medum text-xs text-gray-500">
            Set the date ranges this event is available for:
          </p>
          {eventAvailability.multiDayEventIntervals.map((element, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                  <label
                    htmlFor="startDateTime"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start date
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      value={element.startDateTime}
                      name="startDateTime"
                      dateFormat="MMMM d, yyyy"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(date) =>
                        handleMultiDateChange(date, "startDateTime", idx)
                      }
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                  <label
                    htmlFor="endDateTime"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    End date
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      value={element.endDateTime}
                      name="endDateTime"
                      dateFormat="MMMM d, yyyy"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(date) =>
                        handleMultiDateChange(date, "endDateTime", idx)
                      }
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="block justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2"
                  onClick={() => removeFormFields(idx)}
                >
                  Remove
                </button>
              </React.Fragment>
            );
          })}
          <button
            className="block justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
            type="button"
            onClick={addFormFields}
          >
            Add Another Date Range
          </button>
        </div>
      ) : (
        <>
          {/* Start section */}
          <div className="mt-5 bg-gray-50 rounded-md p-2">
            <p className="font-medium text-gray-700">Start Conditions</p>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor="time"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Start date
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  value={eventAvailability.start}
                  name="start"
                  dateFormat="MMMM d, yyyy"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  onChange={(date) => handleDateChange(date, "start")}
                />
              </div>
            </div>
            <SelectBlock
              label="Repeat"
              field="repeat"
              onChange={(e) => handleChange(e.target.value, "repeat")}
              options={repeatOptions}
              value={eventAvailability.repeat}
            />
          </div>

          {/* Details section */}
          <div className="mt-5 bg-gray-50 rounded-md p-2">
            <p className="font-medium text-gray-700">Details</p>
            <div className=" space-y-4">
              <div className="text-sm text-gray-600 mt-4">
                Repeats every&nbsp;
                <input
                  type="number"
                  name="interval"
                  step={1}
                  min={1}
                  value={eventAvailability.interval}
                  className="inline rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm w-20"
                  onChange={(e) =>
                    handleChange(Number(e.target.value), "interval")
                  }
                />
                &nbsp;{INTERVAL_TYPE_LOOKUP[eventAvailability.repeat]}
              </div>
              {eventAvailability.repeat === repeatTypes.Weekly && (
                <div>
                  <label htmlFor="weekDays" className="text-gray-600 text-sm">
                    Select the weekdays you'd like this event to repeat each
                    week
                  </label>
                  <div className="flex flex-wrap gap-2 justify-around">
                    {weekDayOptions.map((d) => (
                      <label key={d.value} className="flex gap-1 items-center">
                        <input
                          type="checkbox"
                          name={d.text}
                          value={d.value}
                          checked={
                            eventAvailability.weekDays.includes(d.value)
                              ? "checked"
                              : false
                          }
                          className="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          onChange={() => handleWeekDaysChange(d.value)}
                        />
                        {d.text}
                      </label>
                    ))}
                  </div>
                </div>
              )}
              {eventAvailability.repeat === repeatTypes.Monthly && (
                <div className="text-sm text-gray-600 mt-4">
                  on the&nbsp;
                  <select
                    name="day"
                    className="inline rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                    value={eventAvailability.day}
                    onChange={(e) => handleChange(e.target.value, "day")}
                  >
                    {dayOfMonthOptions.map((d) => (
                      <option key={d.value} value={d.value}>
                        {d.text}
                      </option>
                    ))}
                  </select>
                  &nbsp;of the month
                </div>
              )}
              {eventAvailability.repeat === repeatTypes.Yearly && (
                <div className="text-sm text-gray-600 mt-4">
                  on the&nbsp;
                  <select
                    name="day"
                    className="inline rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                    value={eventAvailability.day}
                    onChange={(e) => handleChange(e.target.value, "day")}
                  >
                    {dayOfMonthOptions.map((d) => (
                      <option key={d.value} value={d.value}>
                        {d.text}
                      </option>
                    ))}
                  </select>
                  &nbsp;of&nbsp;
                  <select
                    name="month"
                    className="inline rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                    value={eventAvailability.month}
                    onChange={(e) => handleChange(e.target.value, "month")}
                  >
                    {monthOptions.map((m) => (
                      <option key={m.value} value={m.value}>
                        {m.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          {/* Stop section */}
          <div className="mt-5 bg-gray-50 rounded-md p-2">
            <p className="font-medium text-gray-700">Stop Conditions</p>
            <SelectBlock
              label="Stop repeating when..."
              field="untilType"
              onChange={(e) => updateUntilType(e.target.value)}
              options={untilTypeOptions}
              value={untilType}
            />
            {untilType === untilTypes.UntilDate && (
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor="time"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Run this event until
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <DatePicker
                    value={eventAvailability.until}
                    name="until"
                    dateFormat="MMMM d, yyyy"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    onChange={(date) => handleDateChange(date, "until")}
                  />
                  <p className="text-xs text-gray-400">
                    Note: No events will run on the date selected here and any
                    date after it.
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";
import InputBlock from "./Fields/InputBlock";
import TextAreaBlock from "./Fields/TextAreaBlock";
import SelectBlock from "./Fields/SelectBlock";
import Checkbox from "./Fields/Checkbox";
import ImageUpload from "./Fields/ImageUpload";
import DynamicPrice from "./Fields/DynamicPrice";
import DynamicDates from "./Fields/DynamicDates";
import SpecialRequest from "./Fields/SpecialRequest";
import SingleCheckBox from "./Fields/SingleCheckBox";
import SeasonalOverrides from "./Fields/SeasonalOverrides";
import { Options } from "./Fields/Options";

export default function LodgeForm({
  imageUpload,
  image,
  handleInputChange,
  handleSubmit,
  submitted,
  values,
  prices,
  setPrices,
  setValues,
  defaultOptions,
  showGuestType,
  globalAvailability,
  setGlobalAvailability,
  seasonalOverrides,
  setSeasonalOverrides,
  options,
  setOptions,
}) {
  // const handleChange = (selected, i) => {
  //   let selectedField = values['guests'][i];
  //   selectedField.selected = !selected;
  //   let objClone = [...values['guests']];
  //   objClone[i] = selectedField;
  //   console.log(selectedField);
  //   setValues({
  //     ...values,
  //     guests: [...objClone],
  //   });
  // };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Product Information
        </h3>

        <ImageUpload imageUpload={imageUpload} image={image} />

        <div className="space-y-2 sm:space-y-4">
          <InputBlock
            value={values.title}
            onChange={handleInputChange}
            field="title"
            label="Title"
          />
          <TextAreaBlock
            value={values.description}
            onChange={handleInputChange}
            field="description"
            label="Description"
          />
          <SingleCheckBox
            label={
              <>
                Is this product <strong>only</strong> to be used as an add-on
                for other products?
              </>
            }
            values={values}
            setValues={setValues}
            name="isAddOnOnly"
            helperText="Select me for yes"
          />
          <div className="sm:border-t sm:border-gray-200" />
          <SelectBlock
            value={values.qty}
            onChange={handleInputChange}
            field="qty"
            label="Quantity of accommodations of this type"
            options={[...new Array(100)]}
            index={true}
          />
          <SelectBlock
            value={values.minOccupancy}
            onChange={handleInputChange}
            field="minOccupancy"
            label="Minimum Occupancy"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.occupancy}
            onChange={handleInputChange}
            field="occupancy"
            label="Maximum Occupancy"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.bedrooms}
            onChange={handleInputChange}
            field="bedrooms"
            label="No. of Bedrooms"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.bedCount}
            onChange={handleInputChange}
            field="bedCount"
            label="No. of Beds"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.minNights}
            onChange={handleInputChange}
            field="minNights"
            label="Min. Nights"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.maxNights}
            onChange={handleInputChange}
            field="maxNights"
            label="Max. Nights"
            options={[...new Array(31)]}
            index={true}
          />
          <InputBlock
            value={values.bedType}
            onChange={handleInputChange}
            field="bedType"
            label="Bed Type"
          />
          <SpecialRequest
            description={values.specialRequest.description}
            isActive={values.specialRequest.isActive}
            setValues={setValues}
          />

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 border-t">
            <label
              htmlFor={values.priceBlurb}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Price blurb
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                name="priceBlurb"
                value={values.priceBlurb}
                onChange={handleInputChange}
                className="block h-20 w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              ></textarea>
            </div>
          </div>
          <DynamicPrice
            prices={prices}
            setPrices={setPrices}
            maxLimit={10}
            showGuestType={showGuestType}
            isFeeOrAccommodationProduct
          />
          <SeasonalOverrides
            seasonalOverrides={seasonalOverrides}
            setSeasonalOverrides={setSeasonalOverrides}
            title="Seasonal Overrides Section"
            description="The pricing and/or minimum nights you set here will override the product's base properties for the date range you select"
            isFeeOrAccommodationProduct
          />
          <DynamicDates
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            title="Global Availability Section"
            description="Set the ranges of dates you'd like to set as closed or as long weekend dates for your product here."
          />
          <Options options={options} setOptions={setOptions} />
          <Checkbox
            title="Product Add-ons"
            description="These will be the available add-ons for your product."
            field="addOns"
            useTitleField={true}
            values={values}
            setValues={setValues}
          />
          <Checkbox
            title="Product Amenities"
            description="These will be included in your product."
            field="amenities"
            values={values}
            setValues={setValues}
          />
          <Checkbox
            title="Product Guests"
            description="These will be the guest options displayed on this product."
            field="guests"
            values={values}
            setValues={setValues}
            isGuests
          />
        </div>
      </div>

      <div className="pt-8 pr-8">
        <div className="flex justify-end">
          <button
            disabled={submitted}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

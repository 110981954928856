import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const PromoCode = {
  createPromoCode: (business, promoCode) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      business: business,
      ...promoCode,
    };

    return axiosInstance.post("/api/promocode/create", bodyParameters, config);
  },

  getPromoCodes: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get("/api/promocode/getPromoCodes", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updatePromoCode: (promoCode) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      ...promoCode,
    };

    return axiosInstance.put("/api/promocode/update", bodyParameters, config);
  },
};

import React from "react";
import { twClassNames } from "../../../../utils/twClassNames";

export default function DynamicInventory({
  inventory,
  setInventory,
  isEditing = false,
}) {
  const handleChange = (value, field, index) => {
    let newInventory = [...inventory];

    newInventory[index][field] = value;
    setInventory(newInventory);
  };

  const addFormFields = () => {
    setInventory((prev) => [
      ...prev,
      {
        label: "",
        quantity: "",
      },
    ]);
  };

  const removeFormFields = (i) => {
    let newInventory = [...inventory];
    newInventory.splice(i, 1);
    setInventory(newInventory);
  };
  return (
    <div className="sm:border-t sm:border-gray-200 ">
      <div className="flex flex-col gap-1 sm:pt-2">
        <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
          Inventory Section
        </p>
        <p className="text-gray-500 text-sm">
          In this section you can set the event's inventory information.
        </p>
        <p className="text-gray-500 text-sm">
          The name refers to the timeslot (e.g. "Half Day Tour" or "12:15pm").
          The quantity refers to the number of "tickets" available for that
          slot.
        </p>
        {isEditing ? (
          <p className="text-orange-700 text-sm">
            To edit the time slots & quantity, this must be done manually for
            now. Please ask a developer.
          </p>
        ) : (
          <>
            <p className="text-orange-700 text-sm">
              This is a required field. Atleast 1 must be filled out per event.
              You can leave the `name` property blank if needed.
            </p>
            <p className="text-orange-700 text-sm">
              Don't forget to allow time slots (in the Display Settings Section)
              if you have more than one option here.
            </p>
          </>
        )}
      </div>
      {inventory.map((element, index) => {
        return (
          <div
            key={index}
            className={twClassNames(
              index > 0 && "border-t border-gray-200 mt-5"
            )}
          >
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor="label"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="label"
                  value={element.label}
                  onChange={(e) => handleChange(e.target.value, "label", index)}
                  className="block disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  disabled={isEditing}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor="quantity"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Quantity
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="number"
                  step={1}
                  min={1}
                  name="quantity"
                  value={element.quantity}
                  onChange={(e) =>
                    handleChange(e.target.value, "quantity", index)
                  }
                  className="block disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  disabled={isEditing}
                />
              </div>
            </div>
            {!isEditing && (
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2"
                onClick={() => removeFormFields(index)}
              >
                Remove
              </button>
            )}
          </div>
        );
      })}
      {!isEditing && (
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
          type="button"
          onClick={addFormFields}
        >
          Add Inventory
        </button>
      )}
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import UpdatePromoCodeModal from "./UpdatePromoCodeModal";
import { AppContext } from "../../State/AppContext";
import { PromoCodeAPI } from "../../../apis";
import { twClassNames } from "../../../utils/twClassNames";

const PromoCodes = () => {
  const { state } = useContext(AppContext);
  const [promoCodes, setPromoCodes] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState({});

  const { business } = state.user;

  useEffect(() => {
    PromoCodeAPI.getPromoCodes(business)
      .then((res) => {
        setPromoCodes(res?.data?.promoCodes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  const handleEditClick = (promoCode) => {
    setSelectedPromoCode(promoCode);
    setIsEditModalOpen(true);
  };

  return (
    <>
      <div className="px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              Promo Codes
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all promo codes
            </p>
            <div className="flex gap-2 items-center mt-2">
              <ExclamationTriangleIcon className="h-5 w-5 text-amber-600" />
              <p className="text-xs text-amber-800">
                Promo codes with this symbol are no longer useable. Look for the
                reason in red.
              </p>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/business/promo-codes/create"
              className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
              type="button"
            >
              Create promo code
            </Link>
          </div>
        </div>

        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
          {promoCodes &&
            promoCodes.map((code) => {
              const validUntilDate = moment(code.validUntil);
              const isValid = validUntilDate.isAfter(new Date());
              const isDepleted =
                code.valueType === "fee" && code.promoCodeValue === 0;
              const isUsed =
                typeof code.usageLimit === "number" && code.usageLimit === 0;
              const isActive = code.isActive;
              const isInvalid = !isValid || isDepleted || isUsed;
              const isError = !isValid || isDepleted || isUsed || !isActive;
              return (
                <li
                  key={code._id}
                  className={twClassNames(
                    isError && "shadow-amber-600",
                    "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow overflow-hidden relative"
                  )}
                >
                  {isError && (
                    <div className="absolute right-4 top-4">
                      <ExclamationTriangleIcon className="h-6 w-6 text-amber-600" />
                    </div>
                  )}
                  <div className="flex flex-1 flex-col py-6 px-4 gap-2">
                    <div>
                      <h3 className="text-xs font-medium text-gray-500 text-left">
                        Code:
                      </h3>
                      <p className="text-sm font-medium text-gray-900 text-left">
                        {code.promoCode}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-xs font-medium text-gray-500 text-left">
                        Value:
                      </h3>
                      <p
                        className={twClassNames(
                          isDepleted ? "text-red-600" : "text-gray-900",
                          "text-sm font-medium text-left"
                        )}
                      >
                        {code.valueType === "fee"
                          ? isDepleted
                            ? "Depleted"
                            : `$${code.promoCodeValue / 100} off`
                          : `${code.promoCodeValue * 100}% off`}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-xs font-medium text-gray-500 text-left">
                        Expiration Date:
                      </h3>
                      <p
                        className={twClassNames(
                          isValid ? "text-gray-900" : "text-red-600",
                          "text-sm font-medium text-left"
                        )}
                      >
                        {isValid
                          ? validUntilDate.format("MMMM Do YYYY")
                          : "Expired"}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-xs font-medium text-gray-500 text-left">
                        Usage Limit:
                      </h3>
                      <p
                        className={twClassNames(
                          isUsed ? "text-red-600" : "text-gray-900",
                          "text-sm font-medium text-left"
                        )}
                      >
                        {isUsed ? "Depleted" : code.usageLimit}
                      </p>
                    </div>
                    {!isInvalid && (
                      <div>
                        <h3
                          className={twClassNames(
                            isActive ? "text-green-600" : "text-red-600",
                            "text-sm font-medium text-left"
                          )}
                        >
                          {isActive ? "Active" : "Deactivated"}
                        </h3>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <button
                        onClick={() => handleEditClick(code)}
                        className="relative -mr-px inline-flex w-full flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibol bg-blue-100 hover:bg-blue-50 transition-colors"
                      >
                        <span className="px-2 py-1 text-xs font-medium text-blue-800">
                          Edit
                        </span>
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>

      <UpdatePromoCodeModal
        promoCode={selectedPromoCode}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
      />
    </>
  );
};

export default PromoCodes;

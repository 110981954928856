import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../State/AppContext";
import { ProductGroupAPI } from "../../../apis/index";
import { Link } from "react-router-dom";
import { ProductCard } from "./ProductCard";

export default function Products() {
  const { state } = useContext(AppContext);
  const [products, setProducts] = useState();
  const { role, business } = state.user;

  useEffect(() => {
    ProductGroupAPI.getAllByBusiness(business)
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  const activeProducts = useMemo(
    () => (products ? products.filter((p) => p.isActive) : []),
    [products]
  );
  const inactiveProducts = useMemo(
    () => (products ? products.filter((p) => !p.isActive) : []),
    [products]
  );

  return role === "Business" ? (
    <div className="px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Products</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the products & packages.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/product/new"
            className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
          >
            Add product
          </Link>
        </div>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-md mt-10">
        <ul className="divide-y divide-gray-200">
          {!_.isUndefined(products) && products.length > 0 ? (
            <>
              {activeProducts &&
                activeProducts.length > 0 &&
                activeProducts.map((product) => (
                  <ProductCard product={product} key={product._id} />
                ))}
              {inactiveProducts &&
                inactiveProducts.length > 0 &&
                inactiveProducts.map((product) => (
                  <ProductCard product={product} key={product._id} />
                ))}
            </>
          ) : (
            <li className="text-center p-10 font-bold text-sm text-gray-600">
              There are currently no products.
            </li>
          )}
        </ul>
      </div>
    </div>
  ) : null;
}

import React, { useEffect, useState } from "react";
import { ProductGroupAPI } from "../../apis";

export const CopyCalendarLink = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    async function getProductById() {
      const product = await ProductGroupAPI.getProductGroupById(
        link.productGroupId
      );
      setProduct(product.data.product);
    }
    getProductById();
  }, [link.productGroupId]);
  return (
    <div className="py-4">
      {product && product.title}:
      <div className="flex gap-6">
        <div className="bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 focus:outline-none focus:bg-white focus:border-gray-500 h-[50px] overflow-hidden">
          <span id="ical-copy-link" className="text-sm">
            {link.src}
          </span>
        </div>

        <div className="relative">
          <button
            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-full"
            onClick={() => {
              navigator.clipboard.writeText(link.src);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            Copy to Clipboard
          </button>
          {isCopied && (
            <div className="flex items-center h-fit p-2 bg-blue-100 rounded absolute right-0 -top-10">
              <span className="text-xs text-blue-700">Copied!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { XMarkIcon, Cog8ToothIcon } from "@heroicons/react/24/outline";

export const helpModalTypes = {
  subscribeHelp: "SUBSCRIBE_HELP",
  integrateHelp: "INTEGRATE_HELP",
};

export const CalendarIntegrationHelpModal = ({ helpModalType, closeModal }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 my-6 mx-auto max-w-3xl z-50">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* Header: */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 bg-slate-50 rounded-t">
              <h3 className="text-xl font-semibold">
                Calendar Integration Help Docs
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={closeModal}
              >
                <XMarkIcon className="w-6 h-5 text-black" />
              </button>
            </div>
            {/* Body: */}
            <div className="flex flex-col gap-5 px-5 pt-5 pb-10 max-h-[46rem] overflow-auto divide-y">
              {helpModalType === helpModalTypes.subscribeHelp && (
                <>
                  <div>
                    <h4 className="text-lg text-center">
                      How to sync the booker calendar with your personal
                      calendar
                    </h4>
                  </div>
                  <div className="flex flex-col gap-1 pt-5">
                    <h5 className="font-medium">Sync with Google Calendar</h5>
                    <ol className="list-decimal list-outside pl-10 text-sm flex flex-col gap-1">
                      <li>
                        From Google Calendar, click the '+' next to the 'Other
                        Calendars' field.
                      </li>
                      <li>Select 'From URL' in the menu.</li>
                      <li>
                        Paste the subscribe to booker calendar URL and click
                        'Add Calendar'.
                      </li>
                    </ol>
                  </div>
                  <div className="flex flex-col gap-1 pt-5">
                    <h5 className="font-medium">Sync with Apple Calendar</h5>
                    <ol className="list-decimal list-outside pl-10 text-sm flex flex-col gap-1">
                      <li>Choose File &gt; New Calendar Subscription.</li>
                      <li>
                        Paste the subscribe to booker calendar URL and click
                        Subscribe.
                      </li>
                      <li>
                        In the next info dialog box, in the Auto-refresh
                        dropdown menu, select Every Hour in order to keep the
                        calendar up-to-date.
                      </li>
                    </ol>
                  </div>
                  <div className="flex flex-col gap-2 pt-5">
                    <h5 className="font-medium">Sync with Microsoft Outlook</h5>
                    <div className="flex flex-col gap-1">
                      <h6 className="text-sm font-medium">Web Application</h6>
                      <ol className="list-decimal list-outside pl-10 text-sm flex flex-col gap-1">
                        <li>Open Outlook.</li>
                        <li>
                          Click on the Calendar Tab on the bottom left corner of
                          the screen.
                        </li>
                        <li>Click Add Calendar.</li>
                        <li>
                          Paste the subscribe to booker calendar URL in the
                          field and click Import.
                        </li>
                      </ol>
                    </div>
                    <div className="flex flex-col gap-1">
                      <h6 className="text-sm font-medium">
                        Desktop Application
                      </h6>
                      <ol className="list-decimal list-outside pl-10 text-sm flex flex-col gap-1">
                        <li>Open Outlook.</li>
                        <li>
                          Click on the Calendar Tab on the bottom left corner of
                          the screen.
                        </li>
                        <li>
                          Right-click on My Calendars (or any other calendar
                          group's heading) on the left-hand side of the screen
                          to display the options for that group.
                        </li>
                        <li>
                          Hover over Add Calendar and select From Internet in
                          the sub-menu.
                        </li>
                        <li>
                          Paste the subscribe to booker calendar URL in the "New
                          Internet Calendar Subscription" window that appears
                          and click OK. Click Yes to subscribe to this internet
                          calendar and add it to your list of calendars.
                        </li>
                        <li>
                          Click Yes to subscribe to this internet calendar and
                          add it to your list of calendars.
                        </li>
                      </ol>
                    </div>
                  </div>
                </>
              )}
              {helpModalType === helpModalTypes.integrateHelp && (
                <>
                  <div>
                    <h4 className="text-lg text-center">
                      How to get your Google Calendar link
                    </h4>
                  </div>
                  <div className="flex flex-col gap-1 pt-5">
                    <h5 className="font-medium">Open your Google Calendar</h5>
                    <ol className="list-decimal list-outside pl-10 text-sm flex flex-col gap-1">
                      <li>
                        In the top right, click Settings&nbsp;
                        <Cog8ToothIcon className="inline w-5 h-5 text-black" />
                        &nbsp;&gt;&nbsp;
                        <span className="font-medium">Settings</span>.
                      </li>
                      <li>
                        On the left panel, under “Settings for my calendars,”
                        click the name of the calendar you want to use.
                      </li>
                      <li>Click "Access permissions for events".</li>
                      <li>
                        Check the box to "Make available to the public" & ensure
                        "See all event details" is the selected option
                      </li>
                      <li>Scroll further down to "Integrate calendar".</li>
                      <li>
                        Find the link under the "Public address in iCal format"
                        &gt; this link should end in ".ics".
                      </li>
                      <li>
                        Copy this link & paste it under the "Integrate Your
                        Google Calendar With Booker" section &gt; click "Sync"
                      </li>
                    </ol>
                  </div>
                  <div className="flex flex-col gap-1 pt-5">
                    <h5 className="font-medium">Notes</h5>
                    <ol className="list-outside list-disc pl-10 text-sm flex flex-col gap-1">
                      <li>
                        Only calendar events with a title that includes the full
                        name of an accommodation product that exists in Booker
                        can be used to block off dates for that product.
                      </li>
                      <li>
                        If you remove an event from your personal calendar that
                        you've previous synced with Booker, on the next sync
                        those dates will become available again for that
                        product.
                      </li>
                      <li>
                        Once you add your calendar integration link, Booker will
                        sync your calendar to Booker every 8 hours
                        automatically.
                      </li>
                    </ol>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Overlay: */}
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </>
  );
};

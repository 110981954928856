import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { availabilityTypes } from "../AddProduct";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function DynamicDates({
  globalAvailability,
  setGlobalAvailability,
  title,
  description,
  displayLongWeekendOption = true,
}) {
  const handleDateChange = (index, date, field) => {
    let newAvailability = [...globalAvailability];

    newAvailability[index][field] = _.isUndefined(date.target)
      ? date.toISOString()
      : date.target.value.toISOString();
    setGlobalAvailability(newAvailability);
  };

  const handleAvailabilityTypeChange = (index, type, field) => {
    let newAvailability = [...globalAvailability];

    newAvailability[index][field] = type;
    setGlobalAvailability(newAvailability);
  };

  const addFormFields = () => {
    setGlobalAvailability([
      ...globalAvailability,
      {
        startDateTime: new Date(),
        endDateTime: new Date(),
        availabilityType: availabilityTypes.BlackedOut,
      },
    ]);
  };

  const removeFormFields = (i) => {
    let newAvailability = [...globalAvailability];
    newAvailability.splice(i, 1);
    setGlobalAvailability(newAvailability);
  };

  return (
    <div className="sm:border-t sm:border-gray-200 ">
      {title && description && (
        <div className="flex flex-col gap-1 sm:pt-2">
          <p className="block font-medium text-gray-700 sm:mt-px sm:pt-2">
            {title}
          </p>
          <p className="text-gray-500 text-sm">{description}</p>
        </div>
      )}
      {globalAvailability &&
        globalAvailability.map((element, index) => {
          const endDateTime =
            (typeof element.endDateTime === "string" &&
              element.endDateTime.length > 0) ||
            typeof element.endDateTime !== "string"
              ? new Date(element.endDateTime).toISOString()
              : "";
          const startDateTime =
            (typeof element.startDateTime === "string" &&
              element.startDateTime.length > 0) ||
            typeof element.startDateTime !== "string"
              ? new Date(element.startDateTime).toISOString()
              : "";
          return (
            <div key={index}>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor="time"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Start date and time
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <DatePicker
                    showTimeSelect
                    value={startDateTime}
                    name="startDateTime"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    onChange={(date) =>
                      handleDateChange(index, date, "startDateTime")
                    }
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor="time"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  End date and time
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <DatePicker
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    value={endDateTime}
                    name="endDateTime"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    onChange={(date) =>
                      handleDateChange(index, date, "endDateTime")
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                <label
                  htmlFor="quantity"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Availability Type
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    name="availabilityType"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:opacity-50"
                    value={element.availabilityType}
                    onChange={(e) => {
                      handleAvailabilityTypeChange(
                        index,
                        e.target.value,
                        "availabilityType"
                      );
                    }}
                  >
                    <option value={availabilityTypes.BlackedOut}>Closed</option>
                    {displayLongWeekendOption && (
                      <option value={availabilityTypes.LongWeekend}>
                        Long Weekend
                      </option>
                    )}
                  </select>
                  {displayLongWeekendOption && (
                    <p className="text-xs text-gray-500 mt-1">
                      <InformationCircleIcon className="h-5 w-5 flex-shrink-0 text-gray-400 inline mr-1" />
                      Long weekend dates are a range of dates that is 3 nights
                      long, and when selected by a customer it only allows them
                      to select all 3 nights
                    </p>
                  )}
                </div>
              </div>

              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 mt-2"
                onClick={() => removeFormFields(index)}
              >
                Remove
              </button>
            </div>
          );
        })}
      <button
        className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-2"
        type="button"
        onClick={() => addFormFields()}
      >
        Add another date
      </button>
    </div>
  );
}

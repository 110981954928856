export const northAmericaTimeZones = [
  { value: "America/Adak", text: "Hawaii-Aleutian Standard Time (UTC-10:00)" },
  { value: "America/Anchorage", text: "Alaska Standard Time (UTC-09:00)" },
  { value: "America/Los_Angeles", text: "Pacific Standard Time (UTC-08:00)" },
  { value: "America/Denver", text: "Mountain Standard Time (UTC-07:00)" },
  {
    value: "America/Phoenix",
    text: "Mountain Standard Time - Arizona (UTC-07:00)",
  },
  { value: "America/Chicago", text: "Central Standard Time (UTC-06:00)" },
  {
    value: "America/Regina",
    text: "Central Standard Time - Saskatchewan (UTC-06:00)",
  },
  {
    value: "America/Mexico_City",
    text: "Central Standard Time - Mexico City (UTC-06:00)",
  },
  { value: "America/New_York", text: "Eastern Standard Time (UTC-05:00)" },
  {
    value: "America/Indianapolis",
    text: "Eastern Standard Time - Indiana (UTC-05:00)",
  },
  { value: "America/Halifax", text: "Atlantic Standard Time (UTC-04:00)" },
  { value: "America/St_Johns", text: "Newfoundland Standard Time (UTC-03:30)" },
];

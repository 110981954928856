import InputBlock from "./Fields/InputBlock";
import TextAreaBlock from "./Fields/TextAreaBlock";
import Checkbox from "./Fields/Checkbox";
import ImageUpload from "./Fields/ImageUpload";
import DynamicDates from "./Fields/DynamicDates";
import SpecialRequest from "./Fields/SpecialRequest";
import SingleCheckBox from "./Fields/SingleCheckBox";
import SelectBlock from "./Fields/SelectBlock";
import { DisplaySettings } from "./Fields/DisplaySettings";
import { productTypes } from "./AddProduct";
import { RentalData } from "./Fields/RentalData";
import { Options } from "./Fields/Options";

export default function RentalForm({
  imageUpload,
  image,
  handleInputChange,
  handleSubmit,
  submitted,
  values,
  defaultOptions,
  setValues,
  globalAvailability,
  setGlobalAvailability,
  rentalData,
  setRentalData,
  options,
  setOptions,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Product Information
        </h3>

        <ImageUpload imageUpload={imageUpload} image={image} />

        <div className="space-y-2 sm:space-y-4">
          <InputBlock
            value={values.title}
            onChange={handleInputChange}
            field="title"
            label="Title"
          />
          <TextAreaBlock
            value={values.description}
            onChange={handleInputChange}
            field="description"
            label="Description"
          />
          <SingleCheckBox
            label={
              <>
                Is this product <strong>only</strong> to be used as an add-on
                for other products?
              </>
            }
            values={values}
            setValues={setValues}
            name="isAddOnOnly"
            helperText="Select me for yes"
          />
          <RentalData rentalData={rentalData} setRentalData={setRentalData} />
          <div className="sm:border-t sm:border-gray-200" />
          <SelectBlock
            value={values.priceType}
            onChange={handleInputChange}
            field="priceType"
            label="Price Type"
            helperText="Select how the price(s) set above are charged"
            options={[
              { text: "Daily", value: "daily" },
              { text: "Hourly", value: "hourly" },
              { text: "Single fee", value: "fee" },
            ]}
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <label
              htmlFor={values.priceBlurb}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Price blurb
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                name="priceBlurb"
                value={values.priceBlurb}
                onChange={handleInputChange}
                className="block h-20 w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              ></textarea>
            </div>
          </div>
          <SelectBlock
            value={values.minDays}
            onChange={handleInputChange}
            field="minDays"
            label="Minimum Days"
            options={defaultOptions}
            index={true}
          />
          <SelectBlock
            value={values.maxDays}
            onChange={handleInputChange}
            field="maxDays"
            label="Maximum Days"
            options={defaultOptions}
            index={true}
          />
          <DisplaySettings
            values={values}
            setValues={setValues}
            productType={productTypes.Rental}
          />
          <SpecialRequest
            description={values.specialRequest.description}
            isActive={values.specialRequest.isActive}
            setValues={setValues}
          />
          <DynamicDates
            globalAvailability={globalAvailability}
            setGlobalAvailability={setGlobalAvailability}
            title="Global Availability Section"
            description="Set the ranges of dates you'd like to set as closed or as long weekend dates for your product here."
            displayLongWeekendOption={false}
          />
          <Options options={options} setOptions={setOptions} />
          <Checkbox
            title="Product Add-ons"
            description="These will be the available add-ons for your product."
            field="addOns"
            useTitleField={true}
            values={values}
            setValues={setValues}
          />
          <Checkbox
            title="Product Amenities"
            description="These will be included in your product."
            field="amenities"
            values={values}
            setValues={setValues}
          />
        </div>
      </div>

      <div className="pt-8 pr-8">
        <div className="flex justify-end">
          <button
            disabled={submitted}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

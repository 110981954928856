import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import React from "react";
import AuthLayout from "./components/Layouts/AuthLayout";
import AppState from "./components/State/AppContext";
import AdminUsers from "./components/Admin/AdminUsers";
import AdminOrganizations from "./components/Admin/AdminOrganizations";
import Businesses from "./components/Business/Businesses";
import BusinessDetail from "./components/Business/BusinessDetail";
import Products from "./components/Business/Product/Products";
// import ProductDetail from "./components/Business/Product/ProductDetail";
import AdminOrganizationDetail from "./components/Admin/AdminOrganizationDetail";
import OrganizationProfile from "./components/Organization/OrganizationProfile";
import AdminBusinessProfile from "./components/Admin/AdminBusinessProfile";
import Amenities from "./components/Business/Amentities/Amenities";
import AddAmenity from "./components/Business/Amentities/AddAmenity";
import ChangePassword from "./components/ChangePassword";
import BusinessProfile from "./components/Business/Profile/BusinessProfile";
import Orders from "./components/Business/Orders/Orders";
import Settings from "./components/Business/Settings/Settings";
import Discounts from "./components/Business/Discounts/Discounts";
import AddDiscount from "./components/Business/Discounts/AddDiscount";
import ProductEdit from "./components/Business/Product/ProductEdit";
import AddProduct from "./components/Business/Product/AddProduct";
import Export from "./components/Business/Export/Export";
import Reports from "./components/Organization/Reports";
import UpcomingBookings from "./components/Bookings/UpcomingBookings";
import Guests from "./components/Business/Guests/Guests";
import AddGuest from "./components/Business/Guests/AddGuest";
import PromoCodes from "./components/Business/PromoCodes/PromoCodes";
import CreatePromoCode from "./components/Business/PromoCodes/CreatePromoCode";

const App = () => {
  return (
    <AppState>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<AuthLayout allowedRoles={["Admin"]} />}>
          <Route
            exact
            path="/admin/organizations"
            element={<AdminOrganizations />}
          />
          <Route exact path="/admin/businesses" element={<Businesses />} />
          <Route
            exact
            path="/admin/organization/:organizationId"
            element={<AdminOrganizationDetail />}
          />
          <Route exact path="/admin/users" element={<AdminUsers />} />
          <Route
            exact
            path="/admin/business/:businessId"
            element={<AdminBusinessProfile />}
          />
        </Route>

        <Route
          element={<AuthLayout allowedRoles={["Organization", "Admin"]} />}
        >
          <Route exact path="/businesses" element={<Businesses />} />
          <Route
            exact
            path="/business/:businessId"
            element={<BusinessDetail />}
          />
          <Route exact path="/organization/reports" element={<Reports />} />
          <Route exact path="/export/:businessId" element={<Export />} />
          <Route
            exact
            path="/organization/profile"
            element={<OrganizationProfile />}
          />
        </Route>
        <Route element={<AuthLayout allowedRoles={["Business"]} />}>
          <Route
            exact
            path="/upcoming-bookings"
            element={<UpcomingBookings />}
          />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/orders" element={<Orders />} />
          {/* <Route
            exact
            path="/products/:productId"
            element={<ProductDetail />}
          /> */}
          <Route
            exact
            path="/product/edit/:productId"
            element={<ProductEdit />}
          />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/product/new" element={<AddProduct />} />
          <Route exaxt path="business/discounts" element={<Discounts />} />
          <Route
            exact
            path="/business/discount/add"
            element={<AddDiscount />}
          />

          <Route exact path="/business/amenities" element={<Amenities />} />
          <Route exact path="/business/amentity/add" element={<AddAmenity />} />
          <Route
            exact
            path="/business/terms-conditions"
            element={<Settings />}
          />
          <Route exact path="/business/profile" element={<BusinessProfile />} />
          <Route exact path="/guests" element={<Guests />} />
          <Route exact path="/guests/add" element={<AddGuest />} />
          <Route exact path="business/promo-codes" element={<PromoCodes />} />
          <Route
            exact
            path="business/promo-codes/create"
            element={<CreatePromoCode />}
          />
        </Route>
      </Routes>
    </AppState>
  );
};

export default App;

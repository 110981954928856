import React, { useCallback, useState, useEffect } from "react";
import { OrderAPI, ProductGroupAPI } from "../../apis";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import _ from "lodash";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const AddCalendarEvent = ({ setShowModal, business, booking }) => {
  const [products, setProducts] = useState([]);
  const [fullProducts, setFullProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [maxGuests, setMaxGuests] = useState([]);
  const [values, setValues] = useState({
    email: "",
    fullName: "",
    startDateTime: null,
    endDateTime: null,
  });
  const [errors, setErrors] = useState({});

  const loadProducts = useCallback(async () => {
    ProductGroupAPI.getAllByBusiness(business)
      .then((res) => {
        const activeAccommodations = res.data.products
          .filter((prod) => prod.productType === "accommodation")
          .filter((prod) => prod.isActive);
        setFullProducts(res.data.products);
        setProducts(
          activeAccommodations.map((p) => ({
            label: p.title,
            value: p._id,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmail(values.email)) {
      setErrors({ email: "Email is invalid" });
    } else {
      const orderType = "manual_entry";
      const productIds = new Array(product);
      const productData = fullProducts.find((p) => p._id === product);

      const metadata = [
        {
          guestInfo: [
            {
              guestCt: numberOfGuests,
            },
          ],
          paymentInfo: {
            customerFee: 0,
            subTotal: 0,
            taxTotal: 0,
            grandTotal: 0,
            grandTotalBeforeFee: 0,
            discounts: [],
            deposit: {
              enabled: false,
            },
            securityDeposit: {
              enabled: false,
            },
          },
          productInfo: [
            {
              data: productData,
              pricePerTier: [],
              notes: "",
              cartItemTotalGuestCt: numberOfGuests,
              cartItemOptions: [],
              price: 0,
              isAddOnProduct: false,
              cartItemStartDateTime: values.startDateTime,
              cartItemEndDateTime: values.endDateTime,
            },
          ],
        },
      ];

      const customer = {
        name: values.fullName,
        email: values.email,
      };

      await OrderAPI.createOrder(
        productIds,
        values,
        metadata,
        business,
        orderType,
        customer
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Event has been created!");
            setShowModal(false);
            window.location.reload(); // state hack
            setErrors({});
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setErrors({ form: err.message });
        });
    }
  };

  const handleProductChange = (e) => {
    const productId = e.target.value;
    ProductGroupAPI.getProductGroupById(productId)
      .then((res) => {
        const product = res.data.product;
        setMaxGuests(_.range(1, product.occupancy + 1));
        setProduct(product._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setValue = (e, field) => {
    if (field === "startDateTime" || field === "endDateTime") {
      setValues((values) => ({ ...values, [field]: e }));
    } else {
      if (field === "email") {
        if (!isEmail(e.target.value)) {
          setErrors({ email: "Email is invalid" });
        } else {
          setErrors({});
        }
      }
      setValues((values) => ({ ...values, [field]: e.target.value }));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
          <form onSubmit={handleSubmit}>
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-xl font-semibold">Add Manual Booking</h3>
              </div>
              <div className="relative p-6 flex-auto">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pb-4">
                  <label
                    htmlFor="product"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Select product
                  </label>

                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="product"
                      name="product"
                      autoComplete="product-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={product}
                      onChange={(e) => handleProductChange(e)}
                    >
                      <option key="----" value="----">
                        --- Please Select ---
                      </option>
                      {products &&
                        products.map((product) => (
                          <option key={product.label} value={product.value}>
                            {product.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-4">
                    <input
                      type="text"
                      id="fullName"
                      autoComplete="fullName"
                      value={values.fullName}
                      onChange={(e) => setValue(e, "fullName")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Email
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-4">
                    <input
                      type="text"
                      id="email"
                      autoComplete="email"
                      value={values.email}
                      onChange={(e) => setValue(e, "email")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
                {!_.isEmpty(errors) && (
                  <div
                    className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                    role="alert"
                  >
                    <span className="font-medium">Error!</span> {errors.email}.
                    Please try again
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Guests
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-4">
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="numberOfGuests"
                        name="numberOfGuests"
                        autoComplete="numberOfGuests"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        value={numberOfGuests}
                        onChange={(event) =>
                          setNumberOfGuests(event.target.value)
                        }
                      >
                        <option key="----" value="----">
                          --- Please Select ---
                        </option>
                        {maxGuests &&
                          maxGuests.map((max) => (
                            <option key={max} value={max}>
                              {max}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 pb-4">
                  <label
                    htmlFor="time"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start date and time
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      showTimeSelect
                      selected={values.startDateTime}
                      value={values.startDateTime}
                      name="startDateTime"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(date) => setValue(date, "startDateTime")}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pb-4">
                  <label
                    htmlFor="time"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    End date and time
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      showTimeSelect
                      selected={values.endDateTime}
                      value={values.endDateTime}
                      name="endDateTime"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(date) => setValue(date, "endDateTime")}
                    />
                  </div>
                </div>
                {!_.isEmpty(errors) && (
                  <div
                    className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                    role="alert"
                  >
                    {errors.form}
                  </div>
                )}
              </div>
              <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="defaultModal"
                  type="submit"
                  disabled={errors.length > 0}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddCalendarEvent;

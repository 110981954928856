import { CheckIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Business } from "../apis/Business";

export default function Steps({ businessId }) {
  const [status, setStatus] = useState("");

  useEffect(() => {
    Business.getById(businessId)
      .then((res) => {
        setStatus(res.data.business.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  let steps = [
    { id: "01", index: 1, name: "Contacted", status: "upcoming" },
    { id: "02", index: 2, name: "Discovery Call", status: "upcoming" },
    { id: "03", index: 3, name: "Approved", status: "upcoming" },
    { id: "04", index: 4, name: "In Progress", status: "upcoming" },
    { id: "05", index: 5, name: "Testing", status: "upcoming" },
    { id: "06", index: 6, name: "Launched", status: "upcoming" },
    { id: "07", index: 7, name: "Declined", status: "upcoming" },
  ];

  const updatedSteps = () => {
    return steps.map((item) => {
      var step = Object.assign({}, item);
      if (step.name === status) {
        step.status = "complete";
      }
      return step;
    });
  };

  steps = updatedSteps();

  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 bg-white mb-10">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex">
            {step.status === "complete" ? (
              <span className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </span>
            ) : step.status === "current" ? (
              <span
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-600">
                  <span className="text-blue-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-blue-600">
                  {step.name}
                </span>
              </span>
            ) : (
              <>
                <span className="group flex items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </span>
              </>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute top-0 right-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

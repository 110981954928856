import React from "react";
import { Link } from "react-router-dom";
import { twClassNames } from "../../../utils/twClassNames";

export const ProductCard = ({ product }) => {
  return (
    <li key={product._id}>
      <Link
        to={`/product/edit/${product._id}`}
        className="block hover:bg-gray-50"
      >
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <p className="truncate text-sm font-medium text-indigo-800">
              {product.title}
            </p>
            <div className="ml-2 flex flex-shrink-0">
              <p
                className={twClassNames(
                  product.isActive
                    ? "text-green-800 bg-green-100"
                    : "text-red-800 bg-red-100",
                  "inline-flex rounded-full  px-2 text-xs font-semibold leading-5"
                )}
              >
                {product.isActive ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            {/* <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          {product.description}
                        </p>
                      </div> */}
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <svg
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                  clipRule="evenodd"
                />
              </svg>
              <p>{product.productType}</p>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

import React, { useState, useContext } from 'react';
import { UserAPI } from '../apis/index';
import Cookies from 'universal-cookie';
import { AppContext } from './State/AppContext';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const Login = () => {
  const { dispatch } = useContext(AppContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await UserAPI.login(username, password)
      .then((res) => {
        if (res.status === 201) {
          setMsg(res.data.message);
          // set the cookie
          cookies.set(process.env.REACT_APP_TOKEN_NAME, res.data.token, {
            path: '/',
          });
          dispatch({
            type: 'LOGIN',
            payload: {
              id: res.data.id,
              username: res.data.username,
              role: res.data.role,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              organization: res.data.organization,
              business: res.data.business,
            },
          });

          const { role } = res.data;
          if (role === 'Admin') {
            navigate('/admin/organizations');
          }
          if (role === 'Organization') {
            navigate('/businesses');
          }
          if (role === 'Business') {
            navigate('/upcoming-bookings');
          }
        }
      })
      .catch((err) => {
        setMsg(err.response.data.message);
      });
  };

  return (
    <section>
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Sign in to your account
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit}>
              {msg && (
                <div
                  className='bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4'
                  role='alert'
                >
                  <p className='text-sm'>{msg}</p>
                </div>
              )}
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Email address or username
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete='email'
                    required
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                  />
                  <label
                    htmlFor='remember-me'
                    className='ml-2 block text-sm text-gray-900'
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

import axiosInstance from './axiosConfig';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const Discount = {
  createDiscount: (business, discounts) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      business: business,
      discounts: discounts,
    };

    return axiosInstance.post(
      '/api/discount/createDiscount',
      bodyParameters,
      config
    );
  },

  getDiscounts: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get('/api/discount/getDiscounts', {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteDiscount: (businessId, discountId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      businessId: businessId,
      discountId: discountId,
    };

    return axiosInstance.put(
      '/api/discount/deleteDiscount',
      bodyParameters,
      config
    );
  },
};

import axiosInstance from "./axiosConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Booking = {
  getDay: async (year, month, day) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return await axiosInstance.get("/api/booking/bookingDetail", {
      params: { year: year, month: month, day: day },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  upcomingBookings: async (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return await axiosInstance.get("/api/booking/upcomingBookings", {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

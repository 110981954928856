import React, { useState, useContext } from "react";
import { AppContext } from "../../State/AppContext";
import { AmenityAPI } from "../../../apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function AddAmenity() {
  const [amenities, setAmenities] = useState([{ name: "" }]);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const { business } = state.user;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await AmenityAPI.createAmenity(business, amenities)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business amenities has been successfully updated!");
          navigate("/business/amenities");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        navigate("/business/amenities");
      });
  };

  const handleChange = (index, e) => {
    let newAmenities = [...amenities];
    newAmenities[index][e.target.name] = e.target.value;
    setAmenities(newAmenities);
  };

  const addFormFields = () => {
    setAmenities([...amenities, { name: "" }]);
  };

  const removeFormFields = (i) => {
    let newAmenities = [...amenities];
    newAmenities.splice(i, 1);
    setAmenities(newAmenities);
  };

  return (
    <>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Add amenities
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 mb-10">
            <p className="mt-2 flex items-center text-sm text-gray-500">
              The amenities you add below will be options you can select for
              each product you add.
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {amenities.map((element, index) => (
          <div key={index}>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 pb-5">
              <label
                htmlFor="time"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name of amenity
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="name"
                  autoComplete="given-name"
                  value={element.name}
                  onChange={(name) => handleChange(index, name)}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                />
              </div>
            </div>
            {index ? (
              <button
                type="button"
                className="mb-2 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => removeFormFields(index)}
              >
                Remove
              </button>
            ) : null}
          </div>
        ))}

        <div className="flex justify-between pt-8 pr-8 mt-10">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            type="button"
            onClick={() => addFormFields()}
          >
            Add another amenity
          </button>

          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

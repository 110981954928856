import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../State/AppContext";
import { OrganizationAPI } from "../../apis/index";
import OrganizationModal from "../Modal/OrganizationModal";
import { Link } from "react-router-dom";

export default function AdminOrganizations() {
  const { state } = useContext(AppContext);
  const [organizations, setOrganizations] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");

  const { role } = state.user;

  useEffect(() => {
    OrganizationAPI.getAll()
      .then((res) => {
        setOrganizations(res.data.organizations);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return role === "Admin" ? (
    <div className="px-6 lg:px-8">
      {msg && (
        <div
          className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
          role="alert"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p className="text-sm">{msg}</p>
        </div>
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            Organizations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the organizations account including their name, email
            and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            className="bg-gray-800 text-white active:bg-gray-600 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8 w-full"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Add organization
          </button>
          {showModal ? (
            <OrganizationModal setShowModal={setShowModal} setMsg={setMsg} />
          ) : null}
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {organizations &&
                organizations.map((organization) => (
                  <li
                    key={organization.name}
                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                  >
                    <Link
                      to={`/admin/organization/${organization._id}`}
                      className="block hover:bg-gray-50"
                    >
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="text-md font-medium text-gray-900">
                              {organization.name}
                            </h3>
                          </div>
                          <div>
                            <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                              {organization.isActive ? "Active" : "Inactive"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

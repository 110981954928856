import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../State/AppContext";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { CalendarAPI } from "../../apis";
import {
  CalendarIntegrationHelpModal,
  helpModalTypes,
} from "./CalendarIntegrationHelpModal";
import { IntegrateExternalCalendarForm } from "./IntegrateExternalCalendarForm";
import { CopyCalendarLink } from "./CopyCalendarLink";

export const CalendarIntegration = () => {
  const [modalType, setModalType] = useState("");
  const [iCalLinks, setiCalLinks] = useState([]);

  const { state } = useContext(AppContext);
  const { business } = state.user;

  useEffect(() => {
    CalendarAPI.create(business).then((res) => {
      if (res.status === 200) {
        setiCalLinks(res.data.links);
      }
    });
  }, [business]);

  return (
    <>
      <div className="md:flex md:items-center md:justify-between border-b py-4">
        <div className="min-w-0 flex-1">
          <div className="flex justify-between">
            <h3 className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
              Subscribe to Booker Calendar
            </h3>
            <button
              className="inline-flex items-center justify-center gap-1 rounded-md bg-teal-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setModalType(helpModalTypes.subscribeHelp)}
            >
              <QuestionMarkCircleIcon className="text-white h-5 w-5" /> Help
            </button>
          </div>
          <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
            Copy and paste this URL into any third-party calendar service
            (Google Calendar, Apple Calendar...) to subscribe to the bookings of
            your business.
          </span>
          <span className="block text-sm font-medium text-red-600 sm:mt-px sm:py-2 mr-2">
            Note: This feature is only compatible if your business has
            "Accommodation" type products.
          </span>
          <div className="flex flex-col gap-3">
            {iCalLinks &&
              iCalLinks.map((val) => {
                return <CopyCalendarLink link={val} key={val.productGroupId} />;
              })}
          </div>
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between border-b py-4">
        <div className="min-w-0 flex-1">
          <div className="flex justify-between">
            <h3 className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
              Integrate Your Google Calendar With Booker
            </h3>
            <button
              className="inline-flex items-center justify-center gap-1 rounded-md bg-teal-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setModalType(helpModalTypes.integrateHelp)}
            >
              <QuestionMarkCircleIcon className="text-white h-5 w-5" /> Help
            </button>
          </div>
          <span className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2 mr-2">
            Copy and paste your Google Calendar .ics URL here to sync your
            bookings from elsewhere into Booker.
          </span>
          <span className="block text-sm font-medium text-red-600 sm:mt-px sm:py-2 mr-2">
            Note: This feature is only compatible if your business has
            "Accommodation" type products.
          </span>
          <IntegrateExternalCalendarForm />
        </div>
      </div>
      {modalType.length > 0 ? (
        <CalendarIntegrationHelpModal
          closeModal={() => setModalType("")}
          helpModalType={modalType}
        />
      ) : null}
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../State/AppContext";
import { Organization } from "../../apis/Organization";

export default function OrganizationProfile() {
  const { state } = useContext(AppContext);
  const [organizationInfo, setOrganization] = useState();
  const { organization } = state.user;

  useEffect(() => {
    Organization.getById(organization)
      .then((res) => {
        setOrganization(res.data.organization);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [organization]);

  return (
    organizationInfo && (
      <>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              {organizationInfo.name}
            </h1>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 mt-4">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {organizationInfo.name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Active</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                  {organizationInfo.isActive ? "Active" : "Inactive"}
                </span>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                No. of Businesses
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {organizationInfo.businesses.length}
              </dd>
            </div>
            {organizationInfo.notes && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Additional notes
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {organizationInfo.notes}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </>
    )
  );
}

import { BusinessAPI } from "../../../apis";
import { toast } from "react-toastify";
import { getPercentageValue } from "../../../utils/getPercentageValue";
import BooleanSelectBlock from "../Product/Fields/BooleanSelectBlock";

const PaymentInfo = ({ business, paymentInfo, setPaymentInfo }) => {
  const handleInputChange = (e, isPercent) => {
    const { name, value } = e.target;
    setPaymentInfo({
      ...paymentInfo,
      [name]: isPercent ? getPercentageValue(value, true) : value,
    });
  };

  const handleBooleanChange = (payload, field) => {
     setPaymentInfo({
       ...paymentInfo,
       [field]: payload,
     });
  }

  const TOTAL_FEE_PERCENTAGE = 0.0785;

  const handleCustomerApplicationFeeChange = (e) => {
    const { name, value } = e.target;
    setPaymentInfo({
      ...paymentInfo,
      [name]: (TOTAL_FEE_PERCENTAGE * (value / 100)).toFixed(4),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedPaymentInfo = {
      ...paymentInfo,
      customerApplicationPercentage:
        paymentInfo.customerApplicationPercentage,
      // Initialize the application percentage if it doesn't exist
      applicationPercentage:
        typeof paymentInfo.applicationPercentage === "undefined"
          ? paymentInfo.applicationPercentage
          : 0.0495,
    };
    setPaymentInfo(updatedPaymentInfo);

    await BusinessAPI.updateBusiness(
      business,
      "paymentInfo",
      updatedPaymentInfo
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Business payment info has been updated!");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="border-b border-gray-300 pb-5">
      <div className="space-y-6 sm:space-y-5 p-10">
        <h3 className="text-md font-medium leading-6 text-gray-900">
          Payment information
        </h3>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
          <div>
            <label
              htmlFor="websiteURL"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Customer Application Fee (%)
            </label>
            <p className="text-xs text-gray-500">
              Please select a percentage of the fees you'd like your customers
              to cover.&nbsp;
              <a
                href="https://docs.google.com/presentation/d/1hkhsKU4qMnj8h4fFC_bhjDAuq4NGGflfOCYjQbOZalE/edit?usp=sharing"
                className="text-blue-500 hover:underline"
              >
                Click here
              </a>
              &nbsp; to learn more about how fees work.
            </p>
          </div>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="number"
              name="customerApplicationPercentage"
              max={100}
              value={(
                (paymentInfo?.customerApplicationPercentage /
                  TOTAL_FEE_PERCENTAGE) *
                100
              ).toFixed(0)}
              onChange={handleCustomerApplicationFeeChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <p className="text-xs text-gray-500">
              Note: Choosing 100% means your customers will be charged an
              additional fee of 7.85% of their total.
            </p>
            <p className="text-xs text-gray-500">
              Choosing 50% means your customer will be charged an additional fee
              of 3.93% of the total and you will cover the remainig 3.93%.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Disclaimer Text
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="disclaimerText"
              value={paymentInfo?.disclaimerText}
              onChange={handleInputChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <p className="text-xs text-gray-500">
              Note: This label will be displayed at the bottom of the booker
              below the "Pay" button.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Tax Rate (%)
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="number"
              step={1}
              name="taxPercentage"
              value={getPercentageValue(paymentInfo?.taxPercentage ?? 0)}
              onChange={(e) => handleInputChange(e, true)}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Is your business tax exempt?
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <BooleanSelectBlock
              name="isTaxExempt"
              value={paymentInfo?.isTaxExempt ? true : false}
              onChange={(e) => handleBooleanChange(e, "isTaxExempt")}
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="ml-10 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Save
      </button>
    </form>
  );
};
export default PaymentInfo;

import axiosInstance from './axiosConfig';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const Product = {
  getAll: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get('/api/product/products', {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getProduct: (productId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get('/api/product/productDetail', {
      params: { productId: productId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updateProduct: (product) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.put('/api/product/updateProduct', {
      params: { product: product },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteProduct: (businessId, productId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      businessId: businessId,
      productId: productId,
    };

    return axiosInstance.put(
      '/api/product/deleteProduct',
      bodyParameters,
      config
    );
  },

  getAddOns: (businessId) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    return axiosInstance.get('/api/product/getAddOns', {
      params: { businessId: businessId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addProduct: async (data, business, productType) => {
    const token = cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      title: data.title,
      shortTitle: data.shortTitle,
      priceBlurb: data.priceBlurb,
      inventoryType: data.inventoryType,
      description: data.description,
      duration: data.duration,
      customDuration: data.customDuration,
      occupancy: data.occupancy,
      quantity: data.quantity,
      business: business,
      pricing: data.pricing,
      addons: data.addons,
      amenities: data.amenities,
      groupName: data.groupName,
      options: data.options,
      discounts: data.discounts,
      isPerDay: data.isPerDay,
      guests: data.guests,
      productType: productType,
      photoURL: data.urlCDN,
      dates: data.formDates,
      dailyMax: data.dailyMax,
      minGuests: data.minGuests,
      maxGuests: data.maxGuests,
      minNights: data.minNights,
      maxNights: data.maxNights,
      private: data.isPrivate,
      minOccupancy: data.minOccupancy,
      bedrooms: data.bedrooms,
      bedCount: data.bedCount,
      bedType: data.bedType,
    };
    return await axiosInstance.post(
      '/api/product/addProduct',
      bodyParameters,
      config
    );
  },

  uploadImage: async (formData) => {
    return await axiosInstance.post(
      'https://api.cloudinary.com/v1_1/dsy97ue6s/image/upload',
      formData
    );
  },
};

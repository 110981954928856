import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import EditGuestModal from "./EditGuestModal";

export const GuestCard = ({ guest, onDeleteGuest }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { _id, label, guestType, minimum, maximum } = guest;
  return (
    <>
      <li
        className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow overflow-hidden relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <button
            className="absolute w-4 h-4 right-2 top-2"
            onClick={() => setIsModalOpen(true)}
          >
            <PencilSquareIcon className="text-gray-500" />
          </button>
        )}
        <div className="flex flex-1 flex-col p-8 mt-3">
          <h2 className="text-xs font-medium text-gray-500 text-left">Label</h2>
          <p className=" text-sm font-medium text-gray-900 text-left">
            {label}
          </p>
          <h2 className="text-xs font-medium text-gray-500 text-left mt-3">
            Guest Type
          </h2>
          <p className=" text-sm font-medium text-gray-900 text-left">
            {guestType}
          </p>
          {!!minimum && minimum > 0 && (
            <>
              <h2 className="text-xs font-medium text-gray-500 text-left mt-3">
                Min Guests
              </h2>
              <p className=" text-sm font-medium text-gray-900 text-left">
                {minimum}
              </p>
            </>
          )}
          {!!maximum && maximum > 0 && (
            <>
              <h2 className="text-xs font-medium text-gray-500 text-left mt-3">
                Max Guests
              </h2>
              <p className=" text-sm font-medium text-gray-900 text-left">
                {maximum}
              </p>
            </>
          )}
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <button
              onClick={() => onDeleteGuest(_id)}
              className="relative -mr-px inline-flex w-full flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibol bg-red-100 hover:bg-red-50 transition-colors"
            >
              <span className="px-2 py-1 text-xs font-medium text-red-800">
                Delete
              </span>
            </button>
          </div>
        </div>
      </li>
      <EditGuestModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        guest={guest}
      />
    </>
  );
};
